import {
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Typography,
  Skeleton,
  Fab,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTestOrderByClientId } from "store";
import moment from "moment";
import QRCode from "react-qr-code";
import { Empty, Tag } from "antd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ClockCircleOutlined } from "@ant-design/icons";
import PickedUpOrderDetails from "./pickedUpOrderDetails";

const PickedUpOrders = ({ clientId }) => {
  const [pickedUpOrders, setPickedUpOrders] = useState([]);
  const [clickedOrderDetails, setClickedOrderDetails] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const totalNumberOfPickedupOrders = pickedUpOrders.length;
  const userSelectedClientId = clientId !== 0;

  const renderEmptyComponent = (description) => {
    return (
      <Empty style={{ height: "70vh" }} description={<p>{description}</p>} />
    );
  };

  const handleShowOrderDetails = (id) => {
    setSelectedOrderId(id);
    setClickedOrderDetails(true);
  };
  const handleHideOrderDetails = () => {
    setClickedOrderDetails(false);
  };

  const handleFetchPickedUpOrders = () => {
    if (
      getTestOrderByClientId.pending().type ===
      "test-orders/get-test-order-by-clientId/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(
      getTestOrderByClientId({
        clientId: clientId,
        search: "completeddelivery",
        pageSize: 999,
        pageNumber: 1,
        sortField: "dateUpdated",
        sortDirection: 0,
        orderTransportStatus: null,
      })
    ).then((res) => {
      const isFetchSuccess = res?.payload?.success;
      if (isFetchSuccess) {
        setPickedUpOrders(res?.payload?.data?.items.reverse());
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    handleFetchPickedUpOrders();
    // eslint-disable-next-line
  }, [clientId, dispatch]);

  const getQrValue = (orderNumber, autoGeneratedOrderNumber) => {
    if (orderNumber === null) {
      return autoGeneratedOrderNumber + "";
    } else {
      return orderNumber + "";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      {!clickedOrderDetails && (
        <Typography
          sx={{
            py: 2,
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              md: "1.5rem",
              lg: "1.5rem",
              xl: "1.5rem",
            },
          }}
          variant="p"
        >
          Completed Delivery{" "}
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="p"
          >{`(${totalNumberOfPickedupOrders})`}</Typography>
        </Typography>
      )}
      {!isLoading &&
        userSelectedClientId &&
        totalNumberOfPickedupOrders === 0 &&
        renderEmptyComponent("No picked up orders for this client yet.")}
      {!userSelectedClientId &&
        renderEmptyComponent(
          "Please select client to view Picked up test orders"
        )}
      <Box sx={{ width: "100%" }}>
        {userSelectedClientId && isLoading && (
          <List
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "1rem",
              width: "100%",
            }}
          >
            {Array.from({ length: 8 }).map((_, index) => {
              return (
                <ListItem key={index}>
                  <Box
                    sx={{
                      p: 1,
                      width: "100%",
                    }}
                  >
                    <Skeleton variant="rounded" height={270} />
                  </Box>
                </ListItem>
              );
            })}
          </List>
        )}
        {!clickedOrderDetails && (
          <List>
            {userSelectedClientId &&
              totalNumberOfPickedupOrders > 0 &&
              pickedUpOrders.map((item) => {
                const fullName =
                  item?.patient?.firstname + " " + item?.patient?.lastname;
                return (
                  <ListItem key={item?.id}>
                    <Box
                      sx={{
                        p: 1,
                        width: 1,
                      }}
                    >
                      <Card
                        onClick={() => handleShowOrderDetails(item?.id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <CardHeader
                          className="bg-green-500 text-white"
                          title={
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <QRCode
                                size={70}
                                value={getQrValue(
                                  item?.orderNumber,
                                  item?.autoGeneratedOrderNumber
                                )}
                              />
                              {getQrValue(
                                item?.orderNumber,
                                item?.autoGeneratedOrderNumber
                              )}
                            </Box>
                          }
                        />
                        <CardContent>
                          <Tag
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              width: "max-content",
                              marginBottom: "0.5rem",
                            }}
                            icon={<ClockCircleOutlined />}
                            color="default"
                          >
                            {moment(item?.dateUpdated).fromNow()}
                          </Tag>
                          {!item?.isMinimalOrder && (
                            <>
                              <Typography sx={{ mb: 1.5 }}>
                                Patient Name: <b>{fullName}</b>
                              </Typography>
                              <Typography sx={{ mb: 1.5 }}>
                                DOB:{" "}
                                <b>
                                  {moment(item?.patient?.dateOfBirth).format(
                                    "MMMM DD,YYYY"
                                  )}
                                </b>
                              </Typography>
                              <Typography sx={{ mb: 1.5 }}>
                                Gender: <b>{item?.patient?.gender}</b>
                              </Typography>
                            </>
                          )}
                          {item?.isMinimalOrder && (
                            <Typography sx={{ fontWeight: "bold" }}>
                              LRF Pickup Request
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  </ListItem>
                );
              })}
          </List>
        )}
        {clickedOrderDetails && (
          <PickedUpOrderDetails selectedOrderId={selectedOrderId} />
        )}
        {clickedOrderDetails && (
          <Fab
            size="small"
            variant="extended"
            color="primary"
            onClick={handleHideOrderDetails}
          >
            <ArrowBackIcon sx={{ mr: 1 }} />
            Back
          </Fab>
        )}
      </Box>
    </Box>
  );
};

export default PickedUpOrders;
