import { getAllSupplyRequestStatuses } from "api/supplyOrders/getSupplyRequestStatuses";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSupplyRequestStatusDisplayName } from "./getSupplyRequestStatusDisplayName";
import { Select } from "antd";

const SupplyRequestStatusFilter = ({
  handleSelectSupplyRequestStatus,
  selectedSupplyRequestStatus,
}) => {
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const [supplyRequestStatusOptions, setSupplyRequestStatusOptions] = useState(
    []
  );
  const dispatch = useDispatch();

  const handleFetchSupplyRequestStatusesData = () => {
    dispatch(getAllSupplyRequestStatuses()).then((res) => {
      const formattedSupplyRequestStatusOptions = res?.payload?.data?.map(
        (status) => ({
          label: getSupplyRequestStatusDisplayName(status)?.displayName,
          value: status,
        })
      );
      setSupplyRequestStatusOptions(formattedSupplyRequestStatusOptions);
    });
  };

  useEffect(() => {
    handleFetchSupplyRequestStatusesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      className="w-full"
      allowClear
      filterOption={filterOption}
      options={supplyRequestStatusOptions}
      onChange={handleSelectSupplyRequestStatus}
      value={selectedSupplyRequestStatus}
      placeholder="Select supply request status"
      virtual={false}
      showSearch
    />
  );
};

export default SupplyRequestStatusFilter;
