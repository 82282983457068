import { Select } from "antd";
import React from "react";

const statusOptions = [
  {
    label: "Ready For Pickup",
    value: 0,
  },
  {
    label: "Picked Up",
    value: 1,
  },
  {
    label: "Accepted",
    value: 2,
  },
  {
    label: "Pending",
    value: 4,
  },
];

const StatusSelectFilter = ({ selectedStatuses, handleSelectStatus }) => {
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      allowClear
      mode="multiple"
      style={{ width: selectedStatuses.length > 0 ? "auto" : 140 }}
      options={statusOptions}
      value={selectedStatuses}
      onChange={handleSelectStatus}
      maxTagCount={1}
      placeholder="Select status"
      filterOption={filterOption}
    />
  );
};

export default StatusSelectFilter;
