import {
  Modal,
  Select,
  Switch,
  Form,
  Input,
  Button,
  Popconfirm,
  DatePicker,
} from "antd";
import { useDispatch } from "react-redux";
import {
  createSampleCollector,
  deleteSampleCollector,
  getSampleCollectorTypes,
  updateSampleCollector,
} from "store";
import moment from "moment";
import useCheckPermission from "hooks/useCheckPermission";
import { useCallback, useEffect, useState } from "react";
import notification from "components/mnl/global/openNotification";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";

const CreateUpdateSampleCollector = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
    clientData,
  } = props;

  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Laboratory:SampleCollector:Delete"
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sampleCollectorTypes, setSampleCollectorTypes] = useState([]);
  const LoadUpdateData = useCallback(() => {
    form.setFieldsValue({
      firstName: idForUpdate.firstName,
      lastName: idForUpdate.lastName,
      collectorType: idForUpdate.collectorType,
      licenseNumber: idForUpdate.licenseNumber,
      licenseExpirationDate:
        idForUpdate.licenseExpirationDate !== null
          ? moment(idForUpdate.licenseExpirationDate)
          : "",
      clientId: idForUpdate.clientId,
      phoneNumber: idForUpdate.phoneNumber,
      email: idForUpdate.email,
      isActive: Boolean(idForUpdate.isActive),
    });
  }, [form, idForUpdate]);

  useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateSampleCollector.pending().type ===
        "sample-collectors/update-sample-collectors/pending"
      ) {
        setLoading(true);
      }
      dispatch(updateSampleCollector([values, idForUpdate.id])).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Updated Sample Collector",
            description: `Successfully updated ${values.firstName} ${values.lastName}`,
          });
        }
        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Update Sample Collector",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    } else {
      if (
        createSampleCollector.pending().type ===
        "sample-collectors/create-sample-collectors/pending"
      ) {
        setLoading(true);
      }
      dispatch(createSampleCollector(values)).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created Sample Collector",
            description: `Successfully created ${values.firstName} ${values.lastName}`,
          });
        }

        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Create Sample Collector",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line
    errorInfo.errorFields.map((item) => {
      notification.error({
        message: "Required Field",
        description: item?.errors[0].props.children,
      });
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (
      deleteSampleCollector.pending().type ===
      "sample-collectors/delete-sample-collectors/pending"
    ) {
      setLoading(true);
    }
    dispatch(deleteSampleCollector(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Sample Collector",
          description: `Successfully deleted ${idForUpdate.firstName} ${idForUpdate.lastName}`,
        });
      }
      if (!val.payload?.success) {
        notification.error({
          message: "Failed to Delete Sample Collector",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    dispatch(getSampleCollectorTypes()).then((res) => {
      setSampleCollectorTypes(res.payload);
    });
    // eslint-disable-next-line
  }, []);

  const optionSampleCollectorType = sampleCollectorTypes.map((type, index) => {
    return { value: type, label: type };
  });

  const optionClient = clientData.map((element) => {
    return { value: element.id, label: element?.clientName };
  });

  return (
    <Modal
      open={openCreateUpdate}
      title={
        isForUpdate ? "Update Sample Collector" : "Create Sample Collector"
      }
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="sampleCollector"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/* Status */}
        {isForUpdate && (
          <Form.Item label="Status" name="isActive" valuePropName="checked">
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>
        )}
        {/* First Name */}
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: <i>{"Please input First Name."}</i>,
            },
          ]}
        >
          <Input placeholder="First Name" />
        </Form.Item>

        {/* Last Name */}
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: <i>{"Please input Last Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>

        {/* Collector Type */}
        <Form.Item
          label="Collector Type"
          name="collectorType"
          rules={[
            {
              required: true,
              message: <i>{"Please input Collector Type."}</i>,
            },
          ]}
        >
          <Select
            allowClear
            options={optionSampleCollectorType}
            placeholder={"Collector Type"}
          />
        </Form.Item>
        {/* License Number */}
        <Form.Item label="License Number" name="licenseNumber">
          <Input placeholder="License Number" />
        </Form.Item>
        {/* License Expiration Date */}
        <Form.Item label="License Expiration Date" name="licenseExpirationDate">
          <DatePicker />
        </Form.Item>
        {/* clientId */}
        <Form.Item
          label="Client"
          name="clientId"
          rules={[
            {
              required: true,
              message: <i>{"Please input Client."}</i>,
            },
          ]}
        >
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            virtual
            allowClear
            loading={loading}
            options={optionClient}
            placeholder={"Client"}
          />
        </Form.Item>

        {/* Phone Number */}
        <Form.Item label="Phone Number" name="phoneNumber">
          <Input placeholder="Phone Number" />
        </Form.Item>

        {/* Email */}
        <Form.Item label="Email" name="email">
          <Input placeholder="Email" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {accessRightsToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete the sample collector"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateSampleCollector;
