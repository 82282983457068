import { Box, Grid, Skeleton, Typography } from "@mui/material";
import moment from "moment";
import styles from "components/mnl/testRequest/forms/fourthStep/styles.module.css";
import useCalculateBirthdate from "hooks/useCalculateBirthdate";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { useLocation } from "react-router-dom";
import useConvertHours from "hooks/useConvertHours";

const PatientInformation = (props) => {
  const { patientDetails, provider, specimens, isLoading } = props;
  const { getAge } = useCalculateBirthdate();
  const { convertProvidedCollectionDateTime } = useConvertHours();
  const location = useLocation();
  const providerTitle = !provider?.title
    ? ""
    : provider?.title && !provider?.title.includes(".")
    ? provider?.title + "."
    : provider?.title;
  const providerFirstName = provider?.firstName ? provider?.firstName : "";
  const providerLastName = provider?.lastName ? provider?.lastName : "";
  const dash = provider?.specialty ? " - " : "";
  const providerSpecialty = provider?.specialty ? provider.specialty : "";
  const inReceivingRoute = location.pathname.includes("receiving");
  const patientFullName = `${patientDetails?.firstname} ${
    patientDetails?.middlename === null
      ? ""
      : patientDetails?.middlename?.includes(".")
      ? patientDetails?.middlename
      : patientDetails?.middlename + "."
  } ${patientDetails?.lastname}`;
  const isPatientNull = patientDetails === null;

  const patientMedicalRecordNo = `${
    patientDetails?.searchMedicalRecordNo || ""
  }`;
  const { allowEmailSendingOfRequest } = parsedClientDetails();

  const renderCollectionTime = (specimensLength, collectionTime) => {
    const currentDate = moment();
    const diffInHours = currentDate.diff(collectionTime, "hours");
    if (specimensLength === 0) return "";

    if (inReceivingRoute) {
      const hourDiffDisplay =
        convertProvidedCollectionDateTime(diffInHours) !== "0 hour"
          ? `- ${convertProvidedCollectionDateTime(diffInHours)} ago`
          : "";
      return `${moment(collectionTime).format("LT")} ${hourDiffDisplay}`;
    }
    return moment(collectionTime).format("LT");
  };

  const patientInfoLeft = [
    {
      id: 1,
      label: "Patient name",
      value: isPatientNull ? `(LRF Pickup Request)` : patientFullName,
    },
    {
      id: 2,
      label: "Medical Record no.",
      value: patientMedicalRecordNo,
    },
    {
      id: 3,
      label: "Date of Birth",
      value:
        patientDetails?.dateOfBirth === null
          ? "DD/MM/YY"
          : moment(patientDetails?.dateOfBirth).format("MM/DD/YYYY"),
    },
    {
      id: 4,
      label: "Patient age",
      value: `${getAge(patientDetails?.dateOfBirth)?.years || "00"} years ${
        getAge(patientDetails?.dateOfBirth)?.months || "00"
      } months ${getAge(patientDetails?.dateOfBirth)?.days || "00"} days`,
    },
    {
      id: 5,
      label: "Sex code",
      value: patientDetails?.gender === "Male" ? "M" : "F" || "M",
    },
  ];
  const patientInfoRight = [
    {
      id: 6,
      label: "Date Collected",
      value:
        moment(specimens ? specimens[0]?.collectionDateTime : "").format(
          "MM/DD/YYYY"
        ) || "MM/DD/YYYY",
    },
    {
      id: 7,
      label: "Time Collected",
      value:
        renderCollectionTime(
          specimens?.length,
          specimens?.find((data) => Math.max(moment(data?.collectionDateTime)))
            ?.collectionDateTime
        ) || "",
    },
    {
      id: 8,
      label: "Physician code",
      value: `${provider?.id || ""}`,
    },
    {
      id: 9,
      label: "Physician name",
      value: `${providerTitle} ${providerFirstName} ${providerLastName} ${dash} ${providerSpecialty}`,
    },
    {
      id: 10,
      label: "Email address",
      value: patientDetails?.emails?.map((email) => email).join(", "),
    },
  ];
  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          sx={{
            color: "darkBlue.main",
            marginBottom: "0.5rem",
            marginTop: "0.7rem",
            fontSize: "1.4rem",
            fontWeight: "semibold",
          }}
          variant="h4"
        >
          Patient Information
        </Typography>
        <p className={styles.divider} />
      </Grid>
      <Grid container>
        <Grid
          item
          sx={{ marginRight: "auto" }}
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
        >
          <Box className={styles["grid-container"]}>
            {patientInfoLeft.map((info) => (
              <Box key={info.id} className={styles["grid-item"]}>
                <span className={styles.label}>{info.label}</span>
                <span className={styles.colon}>:</span>
                <span className={styles.value}>
                  {isLoading ? <Skeleton /> : info.value}
                </span>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Box className={styles["grid-container"]}>
            {patientInfoRight
              .filter((item) =>
                !allowEmailSendingOfRequest ? item.id !== 10 : item
              )
              .map((info) => (
                <Box key={info.id} className={styles["grid-item"]}>
                  <span className={styles.label}>{info.label}</span>
                  <span className={styles.colon}>:</span>
                  <span className={styles.value}>
                    {isLoading ? <Skeleton /> : info.value}
                  </span>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientInformation;
