import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Button, Modal } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const DuplicatedTestOrderWarningModal = ({
  duplicatedTestCreator,
  isLoading,
  open,
  handleCloseModal,
  handleClearTestOrderInputs,
  onOk,
}) => {
  const orderData = useSelector((state) => state.orderData);
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const duplicatedTestOrderOrderDate = moment(
    orderData.duplicatedTestOrderData[0]?.dateCreated
  ).format("LLL");
  const hasDuplicatedTestOrder = orderData?.duplicatedTestOrderData?.length > 1;
  const duplicatedTestOrderDetails = orderData?.duplicatedTestOrderData
    ?.map((data) => data.testOrderDetails)
    .flat()
    .filter((test) =>
      savedLabtests.savedLabtestsData.some(
        (savedTest) => savedTest.id === test.testId
      )
    );
  const firstName = orderData?.duplicatedTestOrderData[0]?.patient?.firstname;
  const middlename = orderData?.duplicatedTestOrderData[0]?.patient?.middlename;
  const lastname = orderData?.duplicatedTestOrderData[0]?.patient?.lastname;
  const patientName = `${firstName} ${
    middlename === null || middlename === "" ? "" : middlename + "."
  } ${lastname}`;
  const patientDateOfBirth = moment(
    orderData?.duplicatedTestOrderData[0]?.patient?.dateOfBirth
  ).format("LL");

  return (
    <Modal
      centered
      confirmLoading={isLoading}
      onCancel={handleCloseModal}
      open={open}
      footer={false}
      title={
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "0.3rem",
            my: 1,
          }}
        >
          <WarningRoundedIcon sx={{ fontSize: "1.5rem", color: "#FAAD14" }} />{" "}
          This patient has an existing test order
        </Typography>
      }
      okButtonProps={{
        className: "submitBtn",
      }}
    >
      <Box
        sx={{ marginTop: "1.3rem", display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ marginTop: "0.4rem" }} variant="p">
          Patient Name:{" "}
          <Typography sx={{ fontWeight: "bold" }} variant="p">
            {patientName}
          </Typography>
        </Typography>
        <Typography variant="p">
          Date of Birth:{" "}
          <Typography sx={{ fontWeight: "bold" }} variant="p">
            {patientDateOfBirth}
          </Typography>
        </Typography>
        <Typography variant="p">
          {hasDuplicatedTestOrder ? "Ordered Labtests:" : "Ordered Labtest:"}
        </Typography>
        <ul className="list-disc ml-10 mt-1">
          {duplicatedTestOrderDetails?.map((test) => {
            const labtestData = test?.labTest;
            return (
              <li
                className="font-semibold"
                key={labtestData.id}
              >{`${labtestData?.code} - ${labtestData.name}`}</li>
            );
          })}
        </ul>
        <Typography sx={{ marginTop: "1.1rem" }} variant="p">
          Prepared by:{" "}
          <Typography sx={{ fontWeight: "bold" }} variant="p">
            {duplicatedTestCreator}
          </Typography>
        </Typography>
        <Typography variant="p">
          Request date:{" "}
          <Typography sx={{ fontWeight: "bold" }} variant="p">
            {duplicatedTestOrderOrderDate}
          </Typography>
        </Typography>
      </Box>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 2,
          fontSize: "0.9rem",
          fontWeight: "bold",
        }}
        variant="p"
      >
        Would you like to proceed?
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Button onClick={onOk} type="primary" className="submitBtn">
          Yes, I'll proceed
        </Button>

        <Button onClick={handleClearTestOrderInputs} type="default">
          No, pls cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default DuplicatedTestOrderWarningModal;
