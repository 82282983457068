// import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

import moment from "moment";

const LogDetailsTable = ({ data }) => {
  // Helper function to determine the style based on the log level

  const getLogLevelTextColor = (level) => {
    const logEventStyleMap = {
      Error: { color: "red" },
      Warning: { color: "orange" },
      Information: { color: "blue" },
    };

    const selectedStyle = logEventStyleMap[level];

    return selectedStyle;
  };

  if (typeof data === "object" && data !== null) {
    return (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <tbody>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key} style={{ borderBottom: "1px solid #ddd" }}>
              <td style={{ padding: "8px", fontWeight: "bold" }}>{key}: </td>
              <td
                style={{
                  padding: "8px",
                  color:
                    key === "Level"
                      ? getLogLevelTextColor(value)?.color
                      : "black",
                }}
              >
                {typeof value === "object" && value !== null ? (
                  <LogDetailsTable data={value} />
                ) : (
                  String(
                    key === "TimeStamp" ? moment(value).format("LLL") : value
                  )
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    return <span>{String(data)}</span>;
  }
};

export default LogDetailsTable;
