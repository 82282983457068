import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/mnl/global/menuBar";
import { theme } from "components/mnl/global/theme";
import GetAllClient from "components/mnl/admin/client/getAllClient";
import V2SessionTimeout from "components/mnl/global/v2SessionTimeout";
import TableLayout from "views/mnl/layout/tableLayout";

const ClientPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Client"} />
        <TableLayout>
          <GetAllClient />
        </TableLayout>
      </Box>
      <V2SessionTimeout />
    </ThemeProvider>
  );
};
export default ClientPage;
