import { Box } from "@mui/material";
import { Button, Modal, Typography } from "antd";
import HeaderContent from "./headerContent";
import PatientInformation from "./patientInformation";
import TestProfileSection from "./testProfileSection";
import BillingInformation from "./billingInformation";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import TestOrderToBePrinted from "./testOrderPrintLayout/testOrderToBePrinted";
import { useDispatch } from "react-redux";
import { encryptTestOrder } from "store";
import CreateUpdateNotesModal from "./createUpdateNotesModal";
import { getTestOrderById } from "store";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { isDataEmpty } from "utils/isDataEmpty";

const TestOrderDetailsModal = ({
  cancelTestOrderData,
  handleCloseOrderModal,
  handleOpenCancelTestOrderModal,
  showOrderModal,
  selectedDetails,
  selectedOrderDetails,
  isLoading,
  selectedCreatedDetails,
}) => {
  const componentRef = useRef();
  const promiseResolveRef = useRef(null);
  const [printTestOrder, setPrintTestOrder] = useState(false);
  const {
    isClientAbcLab,
    isSuperAdmin,
    hasClientServiceRole,
    hasAdminRole,
    isClientAbcOrIsSuperAdmin,
  } = parsedClientDetails();
  const [encryptedTestOrder, setEncryptedTestOrder] = useState(null);
  const [fetchedTestOrderDetails, setFetchedTestOrderDetails] = useState(null);
  const [refetchTestOrderDetails, setRefetchTestOrderDetails] = useState(false);
  const [openCreateUpdateNotesModal, setOpenCreateUpdateNotesModal] =
    useState(false);
  const isMinimalOrder = selectedDetails?.isMinimalOrder;
  const showAddNotesButton =
    isClientAbcLab && (hasClientServiceRole || hasAdminRole || isSuperAdmin);
  const isTestOrderNoteForUpdate = fetchedTestOrderDetails?.notes !== null;
  const dispatch = useDispatch();
  const orderStatus = selectedDetails?.orderStatus;

  const showCancelButton = () => {
    if (isClientAbcOrIsSuperAdmin) {
      return true;
    } else {
      if (
        orderStatus === "InClientProcessing" ||
        orderStatus === "ReadyForPickup" ||
        orderStatus === "PickedUp" ||
        orderStatus === "InTransit" ||
        orderStatus === "CompletedDelivery" ||
        orderStatus === "MissedPickup"
      ) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    if (printTestOrder && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [printTestOrder]);

  useEffect(() => {
    if (isDataEmpty(selectedDetails.id)) return;
    dispatch(
      encryptTestOrder({
        orderId: selectedDetails?.id,
      })
    ).then((res) => {
      setEncryptedTestOrder(res?.payload?.data);
    });
  }, [dispatch, selectedDetails?.id]);

  useEffect(() => {
    if (showOrderModal) {
      dispatch(
        getTestOrderById({
          id: selectedDetails?.id,
        })
      ).then((res) => {
        setFetchedTestOrderDetails(res?.payload?.data);
      });
    }
  }, [dispatch, selectedDetails, showOrderModal, refetchTestOrderDetails]);

  const onBeforePrintContent = () => {
    //Before printing the test order summary, it should display all of the data not the paginated one using a state
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintTestOrder(true);
    });
  };

  const onAfterPrintContent = () => {
    //After printing set the state to false, to re display the paginated data
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintTestOrder(false);
    });
  };

  const handlePrintTestOrder = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: onBeforePrintContent,
    onAfterPrint: onAfterPrintContent,
  });

  const handleOpenAddNotesModal = () => {
    setOpenCreateUpdateNotesModal(true);
  };

  const handleCloseCreateUpdateNotesModal = () => {
    setOpenCreateUpdateNotesModal(false);
  };

  const toggleRefetch = () => {
    setRefetchTestOrderDetails(!refetchTestOrderDetails);
  };

  return (
    <Modal
      onCancel={handleCloseOrderModal}
      open={showOrderModal}
      footer={[
        showCancelButton() && (
          <Button
            className="danger"
            danger
            disabled={cancelTestOrderData?.testOrderStatus === "Canceled"}
            key="cancelOrder"
            style={{ marginLeft: "1.5rem" }}
            htmlType="button"
            type="primary"
            size="medium"
            onClick={handleOpenCancelTestOrderModal}
          >
            Cancel Test Order
          </Button>
        ),
        <Button
          style={{ marginLeft: "1.2rem" }}
          key="printOrder"
          htmlType="button"
          type="primary"
          className="submitBtn"
          size="medium"
          onClick={handlePrintTestOrder}
        >
          Print Test Order
        </Button>,
        showAddNotesButton && !isMinimalOrder && (
          <Button
            style={{ marginLeft: "1.2rem" }}
            key="addNotes"
            htmlType="button"
            type="primary"
            className="submitBtn"
            size="medium"
            onClick={handleOpenAddNotesModal}
          >
            {isTestOrderNoteForUpdate ? "Update Note" : "Add Notes"}
          </Button>
        ),
      ]}
      width={"60rem"}
      title={
        <Typography style={{ fontSize: "0.9rem", marginLeft: "1.5rem" }}>
          <blockquote>ORDER ID: {selectedOrderDetails.id}</blockquote>
        </Typography>
      }
    >
      {!printTestOrder && (
        <Box
          sx={{
            flexGrow: 1,
            padding: {
              xs: "0.3rem",
              sm: "0.3rem",
              md: "0.9rem",
              lg: "2rem",
              xl: "2rem",
            },
          }}
        >
          <HeaderContent
            orderStatus={orderStatus}
            orderNumber={selectedDetails?.autoGeneratedOrderNumber}
            clientDetails={selectedDetails?.client}
            isLoading={isLoading}
            cancelReason={selectedDetails?.cancelReason}
          />
          {!selectedDetails?.isMinimalOrder && (
            <>
              <PatientInformation
                patientDetails={selectedDetails?.patient}
                provider={selectedDetails?.provider}
                isLoading={isLoading}
                specimens={selectedDetails?.patientSpecimens}
              />
              <TestProfileSection
                testOrderDetails={selectedDetails?.testOrderDetails}
                diagnosesList={selectedDetails?.diagnosesList}
                isLoading={isLoading}
                specimens={selectedDetails?.patientSpecimens}
                specialHandlings={selectedDetails?.specialHandlings}
                courierRemarks={fetchedTestOrderDetails?.courierRemarks}
                testOrderNotes={fetchedTestOrderDetails?.notes}
                preparedBy={selectedCreatedDetails?.displayName}
              />
            </>
          )}
          {selectedDetails?.isMinimalOrder && (
            <h1 className="text-center text-3xl mt-10 font-semibold">
              LRF Pickup Request
            </h1>
          )}
          <BillingInformation
            clientDetails={selectedDetails?.client}
            isLoading={isLoading}
          />
        </Box>
      )}
      {printTestOrder && (
        <TestOrderToBePrinted
          encryptedTestOrder={encryptedTestOrder}
          orderStatus={orderStatus}
          orderNumber={selectedDetails?.autoGeneratedOrderNumber}
          isLoading={isLoading}
          cancelReason={selectedDetails?.cancelReason}
          patientDetails={selectedDetails?.patient}
          provider={selectedDetails?.provider}
          specimens={selectedDetails?.patientSpecimens}
          testOrderDetails={selectedDetails?.testOrderDetails}
          diagnosesList={selectedDetails?.diagnosesList}
          specialHandlings={selectedDetails?.specialHandlings}
          courierRemarks={selectedDetails?.courierRemarks}
          preparedBy={selectedCreatedDetails?.displayName}
          clientDetails={selectedDetails?.client}
          isMinimalOrder={selectedDetails?.isMinimalOrder}
          ref={componentRef}
        />
      )}
      <CreateUpdateNotesModal
        openCreateUpdateNotesModal={openCreateUpdateNotesModal}
        defaultNoteValue={fetchedTestOrderDetails?.notes}
        isTestOrderNoteForUpdate={isTestOrderNoteForUpdate}
        onCancel={handleCloseCreateUpdateNotesModal}
        testOrderId={fetchedTestOrderDetails?.id}
        toggleRefetch={toggleRefetch}
      />
    </Modal>
  );
};

export default TestOrderDetailsModal;
