import { Box, Chip, Divider } from "@mui/material";
import {
  Button,
  Collapse,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import React from "react";
import { getRequirementType, requirementTypeOptions } from "./data";
import PopConfirm from "components/mnl/global/popConfirm";

const AddLabtestRequirements = ({
  labTestReqArr,
  selectedReqId,
  handleRemoveSavedLabTest,
  handleSelectReqToEdit,
  handleDiscardChanges,
  handleSaveLabTestReq,
  handleSelectIfRequired,
  handleChangeReqDetails,
  labTestReqInputs,
  handleSelectReqType,
}) => {
  const isLabTestReqFormDirty = !labTestReqInputs.requirementDetails;
  const { Panel } = Collapse;
  const { Text } = Typography;
  const emptySelectedReqId = selectedReqId === null;
  const selectRequirementTypeBorder =
    !emptySelectedReqId && "1px solid #1677FF";

  return (
    <Box>
      <Divider textAlign="center" sx={{ margin: "1rem 0" }}>
        <Chip label="Labtest Requirement" />
      </Divider>
      <Form.Item label="Requirement Details">
        <Input
          style={{
            border: selectRequirementTypeBorder,
          }}
          onChange={handleChangeReqDetails}
          placeholder="Requirement Details"
          value={labTestReqInputs.requirementDetails}
        />
      </Form.Item>
      <Form.Item label="Requirement Type">
        <Select
          style={{
            border: selectRequirementTypeBorder,
            borderRadius: emptySelectedReqId && "0.5rem",
          }}
          value={labTestReqInputs.requirementType}
          onChange={handleSelectReqType}
          options={requirementTypeOptions}
        />
      </Form.Item>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Form.Item label="Required">
          <Switch
            onChange={handleSelectIfRequired}
            checked={labTestReqInputs.isRequired}
            checkedChildren={"Yes"}
            unCheckedChildren={"No"}
          />
        </Form.Item>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Button
            onClick={handleSaveLabTestReq}
            disabled={isLabTestReqFormDirty}
            type="primary"
            htmlType="button"
            className="submitBtn"
          >
            {!emptySelectedReqId ? "Save Changes" : "Add Requirement"}
          </Button>
          {!emptySelectedReqId && (
            <Button
              type="primary"
              htmlType="button"
              danger
              onClick={handleDiscardChanges}
            >
              Discard Changes
            </Button>
          )}
        </Box>
      </Box>
      {labTestReqArr.length > 0 && (
        <Collapse
          style={{
            marginBottom: "0.5rem",
          }}
        >
          {labTestReqArr.length > 0 &&
            labTestReqArr.map((test, idx) => (
              <Panel header={`Requirement Details # ${idx + 1}`} key={idx}>
                <Space style={{ marginRight: "3rem" }}>
                  <p>Requirement Details:</p>
                  <Text code>{test.requirementDetails}</Text>
                </Space>
                <br />
                <Space wrap style={{ margin: "0.5rem 0" }}>
                  <p>Requirement Type:</p>
                  <Text code>
                    {getRequirementType(test.requirementType).requirementType}
                  </Text>
                </Space>
                <br />
                <Space wrap>
                  <p>Required:</p>
                  <Text code>{test.isRequired ? "Yes" : "No"}</Text>
                </Space>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "0.4rem",
                  }}
                >
                  <Button
                    size="small"
                    className="bg-orange-600 text-white hover:enabled:bg-orange-400 hover:enabled:text-white"
                    onClick={() => handleSelectReqToEdit(test, idx)}
                  >
                    Update
                  </Button>
                  <PopConfirm
                    title="Remove requirement info"
                    description="Are you sure do you want to remove this requirement?"
                    onConfirm={() => handleRemoveSavedLabTest(idx)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      danger
                      type="primary"
                      className="danger"
                      size="small"
                    >
                      Remove
                    </Button>
                  </PopConfirm>
                </Box>
              </Panel>
            ))}
        </Collapse>
      )}
    </Box>
  );
};

export default AddLabtestRequirements;
