import React, { useEffect, useState } from "react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Popover } from "antd";
import truncateString from "components/mnl/global/truncateString";
import SelectedInstructionsTable from "./selectedInstructionsTable";
import { useDispatch, useSelector } from "react-redux";
import notification from "components/mnl/global/openNotification";
import { createSpecialHandling, getSpecialHandlingLookUp } from "store";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import { handleChangeHandling } from "store/slice/specialHandlingSlice";

const SpecialInstructionInput = () => {
  const [handlingOptions, setHandlingOptions] = useState([]);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedHandlingItems = useSelector((state) => state.specialHandling);
  const [handlingInput, setHandlingInput] = useState({
    instructions: "",
  });
  const dispatch = useDispatch();
  const isHandlingInputEmpty = handlingInput.instructions.trim().length === 0;
  const recentHandlings = savedHandlingItems.savedHandling.map(
    (data) => data.instructions
  ); //Getting the all saved special instructions display name that is already added
  const isHandlingInputPresent = recentHandlings?.includes(
    handlingInput.instructions
  ); //Checking if the special instruction that will be added is already saved
  const mappedHandlingsInOption = handlingOptions.map(
    (option) => option.instructions
  ); //Getting the all saved special instructions instruction name that is inside the special instruction options
  const isHandlingToBeAddedExistInOption = mappedHandlingsInOption.includes(
    handlingInput.instructions
  ); //Checking if the manually added special instruction is already saved

  const handleHandlingChange = (e) => {
    //Getting the special instruction dropdown search input value
    setHandlingInput({ ...handlingInput, [e.target.name]: e.target.value });
  };

  const handleEnterHandling = (e) => {
    //Adding of special instruction using enter key
    if (
      e.key === "Enter" &&
      !isHandlingInputPresent &&
      !isHandlingInputEmpty &&
      !isHandlingToBeAddedExistInOption
    ) {
      dispatch(
        createSpecialHandling({
          instructions: handlingInput.instructions,
        })
      ).then((res) => {
        if (res.payload.success) {
          handleFetchSpecialHandling();
          setHandlingInput({
            instructions: "",
          });
        }
      });
      notification.success({
        message: "Added Special Handling",
        description: `${handlingInput.instructions} added successfully!`,
      });
    }
    if (
      e.key === "Enter" &&
      isHandlingInputPresent &&
      !isHandlingInputEmpty &&
      isHandlingToBeAddedExistInOption
    ) {
      return notification.warning({
        message: "Unable to add Special Instruction",
        description: `You can't add ${handlingInput.instructions}, because it is already added`,
      });
    }
  };

  const handleSelectHandling = (e, selectedHandling) => {
    //Selecting of special instruction using the dropdown search input field
    const isStatSelected =
      selectedHandling.instructions.toLowerCase() === "stat";

    const handlingIds = savedHandlingItems.savedHandling.map(
      (handling) => handling.id
    );
    const isSelectedHandlingPresent = handlingIds.includes(selectedHandling.id);

    if (isStatSelected && !isSelectedHandlingPresent) {
      setTimeout(() => {
        notification.warning({
          message: "STAT selected successfully",
          description: (
            <div>
              By selecting <strong>STAT</strong>, you will be having an
              additional charge
            </div>
          ),
        });
      }, 1000);
    }
    if (
      isSelectedHandlingPresent ||
      isHandlingInputPresent ||
      isHandlingToBeAddedExistInOption
    ) {
      return notification.warning({
        message: "Unable to add Special Instruction",
        description: `You can't add ${
          selectedHandling.instructions || handlingInput.instructions
        }, because it is already added`,
      });
    }

    if (
      (handlingOptions.includes(handlingInput.instructions) &&
        e.key === "Enter") ||
      e.key !== "Enter"
    ) {
      //If the special instruction is added using a click event or an enter key
      dispatch(
        handleChangeMultiFields({
          specialHandlingIds: [
            ...multiStepFormInputs.specialHandlingIds,
            selectedHandling.id,
          ],
        })
      );
      dispatch(
        handleChangeHandling({
          savedHandling: [
            ...savedHandlingItems.savedHandling,
            selectedHandling,
          ],
        })
      );
      notification.success({
        message: "Added Special Instruction",
        description: `${selectedHandling.instructions} added successfully!`,
      });
    }
    if (
      e.key === "Enter" &&
      !isSelectedHandlingPresent &&
      isHandlingInputEmpty
    ) {
      //Check if the special instruction is added using enter key
      dispatch(
        handleChangeMultiFields({
          specialHandlingIds: [
            ...multiStepFormInputs.specialHandlingIds,
            selectedHandling.id,
          ],
        })
      );
      dispatch(
        handleChangeHandling({
          savedHandling: [
            ...savedHandlingItems.savedHandling,
            selectedHandling,
          ],
        })
      );
      notification.success({
        message: "Added Special Instruction",
        description: `${selectedHandling.instructions} added successfully!`,
      });
    }
    if (
      e.key !== "Enter" &&
      !isSelectedHandlingPresent &&
      isHandlingInputEmpty
    ) {
      //Check if the special instruction is added using a click event
      dispatch(
        handleChangeMultiFields({
          specialHandlingIds: [
            ...multiStepFormInputs.specialHandlingIds,
            selectedHandling.id,
          ],
        })
      );
      dispatch(
        handleChangeHandling({
          savedHandling: [
            ...savedHandlingItems.savedHandling,
            selectedHandling,
          ],
        })
      );
    }
    if (
      e.key !== "Enter" &&
      !isHandlingInputEmpty &&
      !isHandlingInputPresent &&
      !isHandlingToBeAddedExistInOption
    ) {
      //Post request for creating new special instruction that will be included in the options
      dispatch(
        createSpecialHandling({
          instructions: handlingInput.instructions,
        })
      ).then((res) => {
        if (res.payload.success) {
          dispatch(
            handleChangeMultiFields({
              specialHandlingIds: [
                ...multiStepFormInputs.specialHandlingIds,
                res.payload.data.id,
              ],
            })
          );
          dispatch(
            handleChangeHandling({
              savedHandling: [
                ...savedHandlingItems.savedHandling,
                selectedHandling,
              ],
            })
          );
          handleFetchSpecialHandling();
          setHandlingInput({
            instructions: "",
          });
        }
      });

      notification.success({
        message: "Added Special Instruction",
        description: `${selectedHandling.displayName} added successfully!`,
      });
    }
  };

  const handleFetchSpecialHandling = () => {
    //Fetching of special instruction options
    dispatch(getSpecialHandlingLookUp()).then((res) => {
      if (res.payload?.success) {
        setHandlingOptions(res.payload?.data.items);
      }
    });
  };

  useEffect(() => {
    //Invoking of special instruction and icd-10 code get function
    handleFetchSpecialHandling();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography variant="p" sx={{ fontWeight: "bold", color: "#294992" }}>
        Special instructions
      </Typography>
      <Box
        sx={{
          backgroundColor: "grayishBlue.main",
          paddingTop: "0.3rem",
          paddingBottom: "1rem",
          paddingX: "1.4rem",
          marginTop: "0.5rem",
        }}
      >
        <Autocomplete
          clearOnBlur
          inputValue={!isHandlingInputEmpty ? handlingInput.instructions : ""}
          value={handlingInput.instructions}
          noOptionsText={
            !isHandlingInputEmpty
              ? `Press Enter to add "${handlingInput.instructions}"`
              : "No options to be displayed"
          }
          options={handlingOptions}
          onKeyDown={(e) => handleEnterHandling(e)}
          disableClearable
          size="small"
          name="instructions"
          getOptionLabel={(option) =>
            option.instructions ? option.instructions : ""
          }
          onChange={(event, newInputValue) => {
            handleSelectHandling(event, newInputValue);
          }}
          renderOption={(props, handling) => (
            <>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <Popover
                  placement="rightTop"
                  autoAdjustOverflow
                  zIndex={99999}
                  content={`${handling.displayName || handling.instructions}`}
                  title="Special Handling Details"
                >
                  <li style={{ margin: "0.2rem" }} {...props}>
                    {truncateString(handling.displayName)}
                  </li>
                </Popover>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <li style={{ margin: "0.2rem" }} {...props}>
                  {(handling.displayName || handling.instructions).length > 21
                    ? (handling.displayName || handling.instructions).substring(
                        0,
                        25
                      ) + "..."
                    : handling.displayName || handling.instructions}
                </li>
              </Box>
            </>
          )}
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "97%",
              lg: "97%",
              xl: "97%",
            },
          }}
          renderInput={(params) => (
            <TextField
              name="instructions"
              value={handlingInput.instructions}
              onChange={handleHandlingChange}
              sx={{
                marginTop: "1rem",
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
                borderRadius: "5px",
                outline: "none",
              }}
              {...params}
              placeholder="Write or choose instruction"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <Box sx={{ overflow: "hidden", opacity: 0 }}>
                      {params.InputProps.endAdornment}
                    </Box>
                    <InputAdornment
                      sx={{ transform: "translateX(25px)" }}
                      position="end"
                    >
                      <ArrowBackIosNewRoundedIcon
                        sx={{
                          transform: "rotate(-90deg)",
                          color: "lightBlue.main",
                          pointerEvents: "none",
                        }}
                      />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          )}
        />
        <SelectedInstructionsTable />
      </Box>
    </>
  );
};

export default SpecialInstructionInput;
