import { SearchOutlined } from "@mui/icons-material";
import { Input } from "antd";
import React from "react";

const SearchFilter = ({
  handleChangeSearch,
  searchText,
  placeholder,
  inputType,
}) => {
  return (
    <Input
      type={inputType}
      placeholder={placeholder}
      prefix={<SearchOutlined />}
      onChange={handleChangeSearch}
      value={searchText}
    />
  );
};

export default SearchFilter;
