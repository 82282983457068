import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import styles from "components/mnl/testRequest/styles/createPatientModalStyles/styles.module.css";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import {
  createHealthcareProvider,
  getAllPhysicians,
  updateSampleCollector,
} from "store";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import decodedUserDetails from "utils/decodedUserDetails";
import useFetchSpecificPhysician from "components/mnl/testRequest/proxies/useFetchSpecificPhysician";
import useCheckProvidedDate from "hooks/useCheckProvidedDate";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import notification from "components/mnl/global/openNotification";
import { handleChangePhysicianFields } from "store/slice/physicianInputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";

const CreateUpdatePhysicianModal = ({
  handleCloseModal,
  showPhysicianEditModal,
  selectedPhysicianId,
}) => {
  const clientId = decodedUserDetails()?.clientId;
  const dispatch = useDispatch();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const physicianInputs = useSelector((state) => state.physicianInputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  useFetchSpecificPhysician(selectedPhysicianId);
  const savedInputs = useSelector((state) => state.savedInputs);
  const { checkIfProvidedDateInPast } = useCheckProvidedDate();
  const isProvidedLicenseExpirationDateInPast = checkIfProvidedDateInPast(
    physicianInputs.licenseExpirationDate
  );

  const handleCloseFormModal = () => {
    //For closing physician's form modal
    handleCloseModal();
    setIsFormDirty(false);
  };

  const handleSubmit = () => {
    if (
      !physicianInputs.firstName ||
      !physicianInputs.lastName ||
      isProvidedLicenseExpirationDateInPast
    ) {
      return setIsFormDirty(true);
    } //Form validation if both firstname and lastname has been filled, or it will throw an error
    if (showPhysicianEditModal) {
      handleUpdatePhysician();
    } else {
      //Check if it will be a new physician
      if (
        createHealthcareProvider.pending().type ===
        "healthcareProviders/create-physician/pending"
      ) {
        setIsLoading(true); //Check if the create action is processsing
      }
      const { id, ...formattedFormData } = physicianInputs;
      dispatch(
        createHealthcareProvider({
          ...formattedFormData,
          licenseNumber:
            physicianInputs.licenseNumber === ""
              ? null
              : physicianInputs.licenseNumber,
        }) //Dispatch the create physician action
      ).then((res) => {
        if (res.payload.success) {
          //Update the UI and show success notification, and fill the search inputs that is displayed in the UI
          dispatch(
            handleChangeSavedFields({
              physicianCode: res.payload?.data.id,
              physicianName: `${res.payload.data.title} ${
                res.payload.data.firstName
              } ${res.payload.data.lastName} ${
                res.payload.data.specialty && "- " + res.payload.data.specialty
              }`,
              refetchData: !savedInputs.refetchData,
            })
          );
          notification.success({
            message: "Created Physician",
            description: `Successfully added ${physicianInputs.firstName} ${physicianInputs.lastName} to the list!`,
          });
          dispatch(
            handleChangePhysicianFields({
              id: res.payload.data.id,
              licenseNumber: res.payload?.data.licenseNumber
                ? res.payload?.data.licenseNumber
                : "",
              licenseExpirationDate: res.payload?.data.licenseExpirationDate
                ? moment(res.payload?.data.licenseExpirationDate)
                : null,
              firstName: res.payload.data.firstName,
              lastName: res.payload.data.lastName,
              title: res.payload.data?.title,
              specialty: res.payload.data?.specialty,
              phoneNumber: res.payload.data?.phoneNumber,
              email: res.payload.data?.email,
              clientId: res.payload.data?.clientId,
            })
          );

          dispatch(
            handleChangeMultiFields({
              providerId: res.payload.data.id,
            })
          );
          dispatch(
            getAllPhysicians({
              clientId: clientId,
              search: "",
              pageSize: 999,
              pageNumber: 1,
              sortField: "dateCreated",
              sortDirection: 1,
            })
          );
          handleCloseModal();
          setIsLoading(false);
        }
        if (!res.payload.success) {
          //Check if there any error after the request, then show a toast notification to inform the user
          setIsLoading(false);
          notification.error({
            message: "Failed to create physician",
            description: errorMessagesDescription(
              res?.payload?.result?.value?.errorMessages
            ),
          });
        }
      });
    }
  };

  const handleUpdatePhysician = () => {
    //For updating physicians data
    if (
      !physicianInputs.firstName ||
      !physicianInputs.lastName ||
      isProvidedLicenseExpirationDateInPast
    ) {
      return setIsFormDirty(true);
    } //Form validation if both firstname and lastname has been filled, or it will throw an error
    if (
      updateSampleCollector.pending().type ===
      "healthcareProviders/update-physician/pending"
    ) {
      setIsLoading(true); //Check if the update action is processsing
    }
    dispatch(
      updateSampleCollector({
        ...physicianInputs,
        id: multiStepFormInputs.providerId,
        licenseNumber:
          physicianInputs.licenseNumber === ""
            ? null
            : physicianInputs.licenseNumber,
      }) //Dispatch the update physician action
    ).then((res) => {
      if (res.payload.success) {
        //Update the UI and show success notification, and fill the search inputs that is displayed in the UI
        dispatch(
          handleChangeSavedFields({
            physicianCode: res.payload?.data.id,
            physicianName: `${res.payload.data.title} ${
              res.payload.data.firstName
            } ${res.payload.data.lastName} ${
              res.payload.data.specialty && "- " + res.payload.data.specialty
            }`,
            refetchData: !savedInputs.refetchData,
          })
        );
        notification.success({
          message: "Updated Physician",
          description: `Successfully updated ${physicianInputs.firstName} ${physicianInputs.lastName}`,
        });

        dispatch(
          handleChangePhysicianFields({
            id: res.payload?.data.id,
            licenseNumber: res.payload?.data.licenseNumber
              ? res.payload?.data.licenseNumber
              : "",
            licenseExpirationDate: res.payload?.data.licenseExpirationDate
              ? moment(res.payload?.data.licenseExpirationDate)
              : null,
            firstName: res.payload?.data.firstName,
            lastName: res.payload?.data.lastName,
            title: res.payload.data?.title,
            specialty: res.payload?.data.specialty,
            phoneNumber: res.payload?.data.phoneNumber,
            email: res.payload.data?.email,
            clientId: +clientId,
          })
        );
        dispatch(
          handleChangeMultiFields({
            providerId: res.payload.data.id,
          })
        );
        dispatch(
          getAllPhysicians({
            clientId: clientId,
            search: "",
            pageSize: 0,
            pageNumber: 1,
            sortField: "dateCreated",
            sortDirection: 1,
          })
        );

        handleCloseModal();
        setIsLoading(false);
      }
      if (!res.payload.success) {
        setIsLoading(false);
        notification.error({
          message: "Failed to update physician",
          description: errorMessagesDescription(
            res?.payload?.result?.value?.errorMessages
          ),
        });
      }
    });
  };

  useEffect(() => {
    //Check if the search input is empty or providerId is empty, then empty the physician form input fields
    if (multiStepFormInputs.providerId === 0) {
      dispatch(
        handleChangePhysicianFields({
          licenseNumber: "",
          licenseExpirationDate: null,
          firstName: "",
          lastName: "",
          title: "",
          specialty: "",
          phoneNumber: "",
          email: "",
        })
      );
    }
  }, [dispatch, clientId, multiStepFormInputs.providerId]);

  return (
    <Box
      component="form"
      sx={{
        flexGrow: 1,
        padding: 3,
        width: { xs: "100%", sm: "100%", md: "100%", lg: "auto", xl: "auto" },
        height: { xs: "100%", sm: "100%", md: "100%", lg: "auto", xl: "auto" },
      }}
    >
      <Grid spacing={2} container>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Divider sx={{ marginBottom: "1rem" }} textAlign="center">
            <Typography
              variant="h6"
              display="block"
              sx={{ fontWeight: "500", color: "darkBlue.main" }}
            >
              {showPhysicianEditModal ? "Edit Physician" : "Add Physician"}
            </Typography>
          </Divider>
        </Grid>
        {/* First name and last name */}
        <Grid columnSpacing={2} sx={{ marginTop: "0.7rem" }} container>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              className="required"
            >
              First Name
            </Typography>
            <TextField
              error={!physicianInputs.firstName && isFormDirty}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={physicianInputs.firstName}
              onChange={(e) =>
                dispatch(
                  handleChangePhysicianFields({ firstName: e.target.value })
                )
              }
              autoComplete="false"
              placeholder="Enter your first name..."
              size="small"
              variant="outlined"
            />
            <Typography
              sx={{
                fontSize: "0.7rem",
                color: "red",
                opacity: !physicianInputs.firstName && isFormDirty ? 1 : 0,
              }}
            >
              First name is required
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              className="required"
            >
              Last Name
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
              }}
              error={!physicianInputs.lastName && isFormDirty}
              className={styles["form-input"]}
              autoSave={false}
              value={physicianInputs.lastName}
              onChange={(e) =>
                dispatch(
                  handleChangePhysicianFields({ lastName: e.target.value })
                )
              }
              autoComplete="false"
              placeholder="Enter your last name..."
              size="small"
              variant="outlined"
            />
            <Typography
              sx={{
                fontSize: "0.7rem",
                color: "red",
                opacity: !physicianInputs.lastName && isFormDirty ? 1 : 0,
              }}
            >
              Last name is required
            </Typography>
          </Grid>
        </Grid>
        {/* Employee license number and its expiration date */}
        <Grid columnSpacing={2} container>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              License Number
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.main",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={physicianInputs.licenseNumber}
              onChange={(e) =>
                dispatch(
                  handleChangePhysicianFields({
                    licenseNumber: e.target.value,
                  })
                )
              }
              name="licenseNumber"
              autoComplete="false"
              placeholder="Enter your license number..."
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              License Expiration Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disablePast={true}
                slotProps={{
                  textField: {
                    size: "small",
                    error: isProvidedLicenseExpirationDateInPast,
                  },
                }}
                name="licenseExpirationDate"
                value={physicianInputs.licenseExpirationDate || null}
                inputFormat="MM/DD/YYYY"
                onChange={(value) =>
                  dispatch(
                    handleChangePhysicianFields({
                      licenseExpirationDate: value,
                    })
                  )
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "2px solid",
                      borderColor: "darkBlue.main",
                    },
                  },
                  width: "100%",
                }}
                components={{
                  OpenPickerIcon: CalendarMonthIcon,
                }}
              />
            </LocalizationProvider>
            {isProvidedLicenseExpirationDateInPast && (
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  color: "red",
                }}
              >
                License Expiration Date cannot be set in past
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* Title and specialty */}
        <Grid columnSpacing={2} sx={{ marginTop: "0.7rem" }} container>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              Title
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={physicianInputs.title}
              onChange={(e) =>
                dispatch(handleChangePhysicianFields({ title: e.target.value }))
              }
              autoComplete="false"
              placeholder="Enter your title..."
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              Specialty
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={physicianInputs.specialty}
              onChange={(e) =>
                dispatch(
                  handleChangePhysicianFields({ specialty: e.target.value })
                )
              }
              autoComplete="false"
              placeholder="Enter your specialty..."
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
        {/* Phone number and email */}
        <Grid columnSpacing={2} sx={{ marginTop: "0.7rem" }} container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              Phone Number
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={physicianInputs.phoneNumber}
              onChange={(e) =>
                dispatch(
                  handleChangePhysicianFields({ phoneNumber: e.target.value })
                )
              }
              autoComplete="false"
              placeholder="Enter your phoneNumber..."
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid
            sx={{ marginTop: "0.7rem" }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            item
          >
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              Email Address
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={physicianInputs.email}
              onChange={(e) =>
                dispatch(handleChangePhysicianFields({ email: e.target.value }))
              }
              autoComplete="false"
              placeholder="Enter your email address..."
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Footer buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          gap: "2rem",
          marginTop: "3rem",
        }}
      >
        <LoadingButton
          size="medium"
          disabled={isLoading}
          loading={isLoading}
          type="button"
          variant="contained"
          endIcon={showPhysicianEditModal ? <SaveIcon /> : <SendIcon />}
          onClick={handleSubmit}
        >
          {showPhysicianEditModal ? "Save Changes" : "Submit"}
        </LoadingButton>
        <Button
          size="medium"
          onClick={handleCloseFormModal}
          variant="dark"
          endIcon={<CloseIcon />}
          type="button"
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUpdatePhysicianModal;
