import { Button } from "antd";
import React, { useState } from "react";
import SearchFilter from "./searchFilter";
import createSupplyIcon from "../../../../assests/create-supply-order-icon.svg";
import ClientFilter from "./clientFilter";
import DateRangeFilter from "./dateRangeFilter";
import SupplyRequestStatusFilter from "./supplyRequestStatusFilter";
import CreateSupplyRequestModal from "../createSupplyRequestModal";
import SortDirectionFilter from "./sortDirectionFilter";
import { parsedClientDetails } from "utils/parsedClientDetails";

const DisplayFilterOptions = ({
  handleChangeSearchSupplyRequestId,
  searchSupplyRequestIdValue,
  handleChangeSearchSupplyRequestItemId,
  searchSupplyRequestItemIdValue,
  isDescending,
  handleSelectClient,
  handleDateChange,
  selectedDates,
  handleSelectSupplyRequestStatus,
  selectedSupplyRequestStatus,
  handleFetchSupplyRequestData,
  handleSelectSortDirection,
}) => {
  const { isClientAbcLab } = parsedClientDetails();
  const [openCreateSupplyRequestModal, setOpenCreateSupplyRequestModal] =
    useState(false);

  const handleOpenCreateSupplyRequestModal = () => {
    setOpenCreateSupplyRequestModal(true);
  };

  const handleCloseCreateSupplyRequestModal = () => {
    setOpenCreateSupplyRequestModal(false);
  };

  const searchSupplyRequestIdComponent = (
    <SearchFilter
      handleChangeSearch={handleChangeSearchSupplyRequestId}
      searchText={searchSupplyRequestIdValue}
      placeholder="Search by supply request id"
      inputType="number"
    />
  );

  const searchSupplyRequestItemIdComponent = (
    <SearchFilter
      handleChangeSearch={handleChangeSearchSupplyRequestItemId}
      searchText={searchSupplyRequestItemIdValue}
      placeholder="Search by supply request item id"
      inputType="number"
    />
  );

  return (
    <div className="flex flex-col lg:flex-row  gap-4 md:gap-1 items-start justify-between px-1 py-2">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {/* Search Supply Request Id Filter */}
        <div>{searchSupplyRequestIdComponent}</div>
        {/* Start and End Date Filter */}
        <div>
          <DateRangeFilter
            handleDateChange={handleDateChange}
            selectedDates={selectedDates}
          />
        </div>
        {/* Search Supply Request Item Id Filter */}
        <div>{searchSupplyRequestItemIdComponent}</div>
        {/* Client Filter */}
        {isClientAbcLab && (
          <div>
            <ClientFilter handleSelectClient={handleSelectClient} />
          </div>
        )}
        {/* Supply Request Filter */}
        <div>
          <SupplyRequestStatusFilter
            handleSelectSupplyRequestStatus={handleSelectSupplyRequestStatus}
            selectedSupplyRequestStatus={selectedSupplyRequestStatus}
          />
        </div>
        {/* Sort Direction Filter */}
        <div xs={12} sm={12} md={2} lg={2} xl={2} item>
          <SortDirectionFilter
            handleSelectSortDirection={handleSelectSortDirection}
            isDescending={isDescending}
          />
        </div>
      </div>
      <Button
        onClick={handleOpenCreateSupplyRequestModal}
        className="flex items-center gap-1 bg-[#1976D2]"
        type="primary"
      >
        <img alt="Create Supply Request" src={createSupplyIcon} />
        <span className="mr-2">Create Supply Request</span>
      </Button>

      <CreateSupplyRequestModal
        openModal={openCreateSupplyRequestModal}
        onCloseModal={handleCloseCreateSupplyRequestModal}
        handleFetchSupplyRequestData={handleFetchSupplyRequestData}
      />
    </div>
  );
};

export default DisplayFilterOptions;
