export const getSupplyRequestStatusDisplayName = (orderStatus) => {
  const orderStatusTagColorMap = {
    NewRequest: {
      tagColor: "#FFDBDB",
      color: "#CF4E4E",
      borderType: "solid",
      displayName: "New Request",
    },
    Reviewed: {
      tagColor: "#FFFFFF",
      color: "#848587",
      borderType: "solid",
      displayName: "Reviewed",
    },
    PartiallyEvaluated: {
      tagColor: "#FFFAD3",
      color: "#ECA210",
      borderType: "dashed",
      displayName: "Partially Evaluated",
    },
    Evaluated: {
      tagColor: "#FFFAD3",
      color: "#ECA210",
      borderType: "solid",
      displayName: "Evaluated",
    },
    Preparing: {
      tagColor: "#FFE1C2",
      color: "#ED6C02",
      borderType: "solid",
      displayName: "Preparing",
    },
    ForDelivery: {
      tagColor: "#D2DFFF",
      color: "#334980",
      borderType: "dashed",
      displayName: "For Delivery",
    },
    InTransit: {
      tagColor: "#D2DFFF",
      color: "#334980",
      borderType: "solid",
      displayName: "In Transit",
    },
    PartiallyCompleted: {
      tagColor: "#F2FFE2",
      color: "#66A617",
      borderType: "dashed",
      displayName: "Partially Completed",
    },
    Completed: {
      tagColor: "#F2FFE2",
      color: "#66A617",
      borderType: "solid",
      displayName: "Completed",
    },
    Cancelled: {
      tagColor: "#FFFFFF",
      color: "#848587",
      borderType: "dashed",
      displayName: "Cancelled",
    },
  };
  const selectedOrderStatus = orderStatusTagColorMap[orderStatus];
  const { tagColor, color, borderType, displayName } =
    selectedOrderStatus !== undefined && selectedOrderStatus;

  return {
    tagColor,
    color,
    borderType,
    displayName,
  };
};
