import {
  Grid,
  Button,
  Typography,
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Alert,
  Stack,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { clearMessage, userChangePassword } from "store";
import { LoadingButton } from "@mui/lab";
import { message } from "antd";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import { getClientInfoById } from "store";
import { logout } from "store";
import { useEffect, useState } from "react";

const LabChangeUserPassword = () => {
  const { register, handleSubmit, watch } = useForm({ mode: "all" });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const newPassword = watch("newPassword") || "";
  const confirmPassword = watch("confirmPassword") || "";
  const passwordsUnmatched = confirmPassword !== newPassword;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate("/lab");
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);

  const handleMouseDownCurrentPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const submitForm = (data) => {
    const decodedUserDetails = JSON.parse(
      atob(JSON.parse(localStorage.getItem("user"))?.token.split(".")[1])
    );

    const userId = decodedUserDetails?.id;

    dispatch(
      userChangePassword({
        userId: userId,
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      })
    ).then(async (val) => {
      const isPostSuccessful = val?.payload?.success;
      const responseData = val?.payload?.data;

      if (isPostSuccessful) {
        const isRoleMedicalTechnologist =
          decodedUserDetails?.role?.includes("MedicalTechnologist") ||
          decodedUserDetails?.role?.includes("Medical Technologist");
        const isRoleCourier = decodedUserDetails?.role?.includes("Courier");
        const clientDetails = await getClientInfo(responseData?.user?.clientId);

        await Promise.all([clientDetails]).then((_) => {
          if (isRoleCourier) {
            navigate("/lab/tagging");
            localStorage.setItem("clInf", JSON.stringify(clientDetails));
            window.history.pushState({ id: 1 }, null, "/lab/tagging");
            messageApi.success("Successfully Changed Password!");
          } else if (isRoleMedicalTechnologist) {
            navigate("/lab/receiving");
            localStorage.setItem("clInf", JSON.stringify(clientDetails));
            window.history.pushState({ id: 1 }, null, "/lab/receiving");
            messageApi.success("Successfully Changed Password!");
          }
        });
      }
    });
  };

  const getClientInfo = async (clientId) => {
    const clientDetails = await dispatch(getClientInfoById({ id: clientId }));
    return clientDetails?.payload?.data;
  };

  const handleReturn = () => {
    dispatch(logout());
    navigate("/lab");
  };

  const errorMessageLists = errorMessage.map((value) => {
    return (
      <Alert variant="outlined" severity="error">
        {value}
      </Alert>
    );
  });

  const successMessageLists = successMessage.map((value) => {
    return (
      <Alert variant="outlined" severity="success">
        {value}
      </Alert>
    );
  });

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={3}
      sx={{
        width: {
          xs: "auto",
          sm: "auto",
          md: "auto",
          lg: "30%",
          xl: "30%",
        },
        mt: "5rem",
        mx: { xs: "1.3rem", sm: "1.3rem", md: "auto", lg: "auto", xl: "auto" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "50vh",
        flexDirection: "column",
      }}
      component="form"
      onSubmit={handleSubmit(submitForm)}
    >
      {contextHolder}
      <Typography
        component="h1"
        variant="h4"
        sx={{
          fontWeight: "bold",
          mb: 2,
        }}
      >
        Change Password
      </Typography>
      {error && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          {errorMessageLists}
        </Stack>
      )}
      {successMessage && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          {successMessageLists}
        </Stack>
      )}
      <FormControl fullWidth variant="outlined" sx={{ mt: 4 }}>
        <InputLabel htmlFor="outlined-adornment-currentpassword">
          Current Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-currentpassword"
          type={showCurrentPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowCurrentPassword}
                onMouseDown={handleMouseDownCurrentPassword}
                edge="end"
              >
                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Current Password"
          {...register("currentPassword")}
          required
          disabled={isLoading}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel htmlFor="outlined-adornment-newpassword">
          New Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-newpassword"
          type={showNewPassword ? "text" : "password"}
          name="newPassword"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowNewPassword}
                onMouseDown={handleMouseDownNewPassword}
                edge="end"
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="New Password"
          {...register("newPassword")}
          required
          disabled={isLoading}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
        <InputLabel
          error={passwordsUnmatched}
          htmlFor="outlined-adornment-confirmpassword"
        >
          Confirm New Password
        </InputLabel>
        <OutlinedInput
          error={passwordsUnmatched}
          id="outlined-adornment-confirmpassword"
          type={showNewPassword ? "text" : "password"}
          label="Confirm New Password"
          required
          {...register("confirmPassword")}
          disabled={isLoading}
        />
      </FormControl>
      {passwordsUnmatched && (
        <Box sx={{ textAlign: "start", padding: "0.6rem 0" }}>
          <Typography sx={{ color: "red" }} variant="p">
            Passwords doesn't match
          </Typography>
        </Box>
      )}
      <Grid container>
        <LoadingButton
          loading={isLoading}
          loadingPosition="end"
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={!newPassword || passwordsUnmatched || isLoading}
        >
          {isLoading ? "Changing Password..." : "Change Password"}
        </LoadingButton>
        <Button
          fullWidth
          color="error"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={isLoading}
          onClick={handleReturn}
        >
          Return to login
        </Button>
      </Grid>
    </Grid>
  );
};
export default LabChangeUserPassword;
