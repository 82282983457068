import { FormControl, TextField } from "@mui/material";
import { Modal } from "antd";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import notification from "components/mnl/global/openNotification";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateTestOrderNotes } from "store";

const CreateUpdateNotesModal = ({
  openCreateUpdateNotesModal,
  defaultNoteValue,
  isTestOrderNoteForUpdate,
  onCancel,
  testOrderId,
  toggleRefetch,
}) => {
  const [notesInputValue, setNotesInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const disableSubmitbtn = notesInputValue?.trim()?.length === 0;
  const dispatch = useDispatch();

  const handleNotesInputValueChange = (e) => {
    setNotesInputValue(e.target.value);
  };

  const handleCancel = () => {
    setNotesInputValue("");
    onCancel();
  };

  const onSubmit = () => {
    if (
      updateTestOrderNotes.pending().type ===
      "test-orders/update-test-order-notes"
    ) {
      setIsLoading(true);
    }

    dispatch(
      updateTestOrderNotes({
        id: testOrderId,
        notes: notesInputValue,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;

      if (isFetchSuccessful) {
        notification.success({
          message: isTestOrderNoteForUpdate ? "Updated Note" : "Created Notes",
          description: `Successfully ${
            isTestOrderNoteForUpdate ? "updated" : "created"
          } note`,
        });
        toggleRefetch();
        handleCancel();
        setIsLoading(false);
        setNotesInputValue("");
      }

      if (!isFetchSuccessful) {
        notification.error({
          message: `Failed to ${
            isTestOrderNoteForUpdate ? "update" : "create"
          } note`,
          description: errorMessagesDescription(
            res.payload?.response?.data?.errorMessages
          ),
        });
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (isTestOrderNoteForUpdate) {
      setNotesInputValue(defaultNoteValue);
    } else {
      setNotesInputValue("");
    }
  }, [isTestOrderNoteForUpdate, defaultNoteValue]);

  return (
    <Modal
      title="Add Notes"
      open={openCreateUpdateNotesModal}
      onCancel={handleCancel}
      okButtonProps={{
        className: "submitBtn",
        disabled: isLoading || disableSubmitbtn,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      okText="Submit"
      centered
      onOk={onSubmit}
    >
      <FormControl fullWidth variant="filled">
        <TextField
          value={notesInputValue}
          onChange={handleNotesInputValueChange}
          sx={{ marginTop: "0.5rem" }}
          id="test-order-notes"
          label="Input your notes..."
          multiline
          rows={3}
          inputProps={{
            autoFocus: true,
          }}
        />
      </FormControl>
    </Modal>
  );
};

export default CreateUpdateNotesModal;
