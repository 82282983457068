import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { typeOptions } from "./data";
import useAdditionalInfoFunctions from "./useAdditionalInfoFunctions";

const ProfilePanelPackageLabReq = ({
  inputValues,
  setInputValues,
  labTestNames,
  fetchedInputFields,
}) => {
  const { handleInputChange, handleInputCheckboxChange } =
    useAdditionalInfoFunctions();
  const showTestName = (labtestInfoId, previousLabtestId) => {
    //Function for showing labtest name
    if (labtestInfoId !== previousLabtestId) {
      return true;
    }

    return false;
  };

  return (
    <Box sx={{ marginBottom: "2rem" }}>
      <Typography
        sx={{
          fontSize: "1rem",
          textAlign: "center",
          paddingTop: "0.5rem",
          color: "grayishBlue.light",
          fontWeight: "bold",
        }}
      >
        Additional Patient Information
      </Typography>
      <Box
        sx={{
          backgroundColor: "#E7EBF4",
          padding: "1rem 0.7rem",
          margin: "0.8rem 1rem",
        }}
      >
        {fetchedInputFields?.map((input, index) => {
          return (
            <Box key={index}>
              {showTestName(
                input.labTestInfoId,
                fetchedInputFields[fetchedInputFields.indexOf(input) - 1]
                  ?.labTestInfoId
              ) && (
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    pb: 3,
                  }}
                >
                  For{" "}
                  {
                    labTestNames?.find(
                      (data) => data.id === input.labTestInfoId
                    )?.name
                  }
                </Typography>
              )}

              {input.requirementType === 3 ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={(e) =>
                          handleInputCheckboxChange(e, index, setInputValues)
                        }
                        checked={
                          inputValues.find((_, inputIdx) => inputIdx === index)
                            ?.value === "true"
                        }
                      />
                    }
                    id={index}
                    label={
                      <Typography
                        className={input.isRequired ? "required" : ""}
                        sx={{
                          color: "grayishBlue.light",
                          fontSize: "1rem",
                        }}
                        variant="p"
                      >
                        {input.requirementDetails}
                      </Typography>
                    }
                  />
                </FormGroup>
              ) : input.requirementType === 4 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "0.2rem",
                  }}
                >
                  <FormControl>
                    <Typography
                      className={input.isRequired ? "required" : ""}
                      sx={{
                        color: "grayishBlue.light",
                        fontSize: "1rem",
                      }}
                      variant="p"
                    >
                      {input.requirementDetails}
                    </Typography>
                    <RadioGroup
                      row
                      value={
                        inputValues?.find((_, inputIdx) => inputIdx === index)
                          ?.value || ""
                      }
                      onChange={(e) =>
                        handleInputChange(e, index, setInputValues)
                      }
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : (
                <Box sx={{ margin: "0.5rem 0" }}>
                  <Typography
                    className={input.isRequired ? "required" : ""}
                    sx={{
                      color: "grayishBlue.light",
                      fontSize: "1rem",
                    }}
                    variant="p"
                  >
                    {input.requirementDetails}
                  </Typography>
                  <TextField
                    id={index}
                    size="small"
                    value={
                      inputValues?.find((_, inputIdx) => inputIdx === index)
                        ?.value || ""
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": {
                          border: "1px solid",
                          borderColor: "darkBlue.secondary",
                          borderRadius: "0.7rem",
                        },
                      },
                      marginTop: "0.5rem",
                      backgroundColor: "white",
                      width: "100%",
                      borderRadius: "0.7rem",
                    }}
                    required={input.isRequired}
                    type={
                      typeOptions.find(
                        (type) => type.id === input.requirementType
                      )?.type || "text"
                    }
                    onChange={(e) =>
                      handleInputChange(e, index, setInputValues)
                    }
                  />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ProfilePanelPackageLabReq;
