import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Avatar,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import styles from "../testRequest/styles/createPatientModalStyles/styles.module.css";
import { createPatient, updatePatient } from "store";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Switch, Checkbox } from "antd";
import {
  birthDateSkeleton,
  fullWidthSkeleton,
  genderSkeleton,
  inputSkeleton,
  statusSkeleton,
} from "./skeletonLoaders";
import PopConfirm from "../global/popConfirm";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import useCheckProvidedDate from "hooks/useCheckProvidedDate";
import useValidateEmail from "hooks/useValidateEmail";
import { handleChangeFields } from "store/slice/inputSlice";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import notification from "components/mnl/global/openNotification";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import AddressSelector from "./addressSelector";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { showInputErrorMessage } from "utils/showInputErrorMessage";

const CreateUpdatePatientModal = ({
  isPatientDataLoading,
  handleCloseModal,
  countryOptions,
  provinceOptions,
  cityMunicipalityOptions,
  barangayOptions,
  phAddressesTypeOptions,
}) => {
  const inputs = useSelector((state) => state.inputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedInputs = useSelector((state) => state.savedInputs);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [copyPatientData, setCopyPatientData] = useState(false);
  const location = useLocation();
  const { setErrorMessage, errorMessage, isEmailValid } =
    useValidateEmail(inputEmail);
  const isPatientForUpdate = multiStepFormInputs.patientId !== 0;
  const emailRef = useRef();
  const dispatch = useDispatch();

  const disableMiddleNameInput = inputs.middlename === null;
  const { checkIfProvidedDateInFuture } = useCheckProvidedDate();
  const isProvidedDateOfBirthInFuture = checkIfProvidedDateInFuture(
    inputs.dateOfBirth
  );
  const isMiddleNameEmpty =
    inputs.middlename !== null && inputs.middlename.trim().length === 0;
  const isRequiredInputsEmpty =
    !inputs.firstname ||
    !inputs.lastname ||
    !inputs.dateOfBirth ||
    !inputs.gender ||
    isMiddleNameEmpty;

  const { allowEmailSendingOfRequest } = parsedClientDetails();
  const threeGridOptions = {
    columnSpacing: 2,
    rowSpacing: 1,
    sx: { marginTop: "1rem", marginBottom: "1rem" },
  };

  const threeGrid = {
    xs: 12,
    sm: 12,
    md: 4,
    lg: 4,
    xl: 4,
  };

  const formInputStyle = {
    width: "100%",
    outline: "none",
    borderRadius: "5px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        border: "2px solid",
        borderColor: "darkBlue.secondary",
      },
    },
  };

  const inputLabelStyle = {
    fontWeight: "bold",
    color: "darkBlue.main",
  };

  const handleAddEmail = (selectedEmail) => {
    if (inputEmail && isEmailValid()) {
      dispatch(
        handleChangeFields({
          emails: [...inputs.emails, selectedEmail],
        })
      );
      setInputEmail("");
    }
  };

  const handleCloseFormModal = () => {
    handleCloseModal();
    setIsFormDirty(false);
    if (copyPatientData) {
      dispatch(
        handleChangeFields({
          id: 0,
          firstname: "",
          cardNumber: "",
          lastname: "",
          middlename: "",
          gender: "",
          dateOfBirth: "",
          contactNumber: "",
          emails: [],
          medicalRecordNo: "",
          phAddresses: [],
          governmentId: "",
          isPatientHasAnAcceptedOrder: false,
        })
      );
      dispatch(
        handleChangeMultiFields({
          id: 0,
          clientWillDrawSpecimen: true,
          patientId: 0,
          providerId: null,
          diagnosesList: [],
          specialHandlingIds: [],
          testOrderDetails: [],
          patientSpecimens: [],
        })
      );
      dispatch(
        handleChangeSavedFields({
          searchPatientName: "",
        })
      );
    }
  };

  const handleEnterEmail = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (inputEmail && isEmailValid()) {
        dispatch(
          handleChangeFields({
            emails: [...inputs.emails, e.target.value],
          })
        );
        setInputEmail("");
      }
    }
    if (
      inputEmail === "" &&
      e.key === "Backspace" &&
      inputs.emails.length > 0
    ) {
      const updatedEmail = [...inputs.emails];
      updatedEmail.pop();
      dispatch(handleChangeFields({ emails: updatedEmail }));
    }
  };

  const handleRemoveEmail = (selectedEmail) => {
    const updatedEmails = inputs.emails.filter((m) => m !== selectedEmail);
    dispatch(handleChangeFields({ emails: updatedEmails }));
  };

  const handleSelectStatus = (value) => {
    dispatch(
      handleChangeFields({
        isActive: value,
      })
    );
  };

  const handleValidateFormInputs = () => {
    setIsFormDirty(true);
  };

  const handleCreatePatient = () => {
    if (isRequiredInputsEmpty) {
      handleValidateFormInputs();
    } else {
      const {
        id,
        isActive,
        isPatientHasAnAcceptedOrder,
        patientEmailForDisplay,
        ...formattedData
      } = inputs;

      if (createPatient.pending().type === "patients/create-patient/pending") {
        setIsLoading(true);
      }
      dispatch(
        createPatient({
          ...formattedData,
          dateOfBirth: moment(inputs.dateOfBirth).format(),
        })
      ).then((response) => {
        const isFetchSuccess = response?.payload?.success;
        const responseData = response?.payload?.data;
        if (isFetchSuccess) {
          dispatch(
            handleChangeSavedFields({
              refetchData: !savedInputs.refetchData,
            })
          );
          dispatch(
            handleChangeFields({
              id: responseData?.id,
              isActive: responseData?.isActive,
              patientEmailForDisplay: responseData?.emails,
            })
          );
          if (location.pathname === "/test-request") {
            dispatch(
              handleChangeSavedFields({
                dateOfBirth: moment(responseData?.dateOfBirth),
                searchPatientName: `${
                  responseData?.lastname ? responseData?.lastname + "," : ""
                } ${responseData?.firstname ? responseData?.firstname : ""} ${
                  responseData?.middlename ? responseData?.middlename : ""
                }`,
                searchMedicalRecordNo: responseData?.medicalRecordNo,
                gender: responseData?.gender,
                isPatientNull: false,
              })
            );
            dispatch(
              handleChangeMultiFields({
                patientId: responseData?.id,
              })
            );
          }

          setIsLoading(false);
          notification.success({
            message: "Add Patient",
            description: `Successfully added ${inputs.firstname} ${inputs.lastname} to the list!`,
          });
          handleCloseModal();
        }
        if (!isFetchSuccess) {
          setIsLoading(false);
          notification.error({
            message: "Failed to add patient",
            description: errorMessagesDescription(
              response?.payload?.response?.data?.errorMessages
            ),
          });
        }
      });
    }
  };

  const handleUpdatePatient = () => {
    if (isRequiredInputsEmpty) {
      handleValidateFormInputs();
    } else {
      const {
        isPatientHasAnAcceptedOrder,
        patientEmailForDisplay,
        ...formattedData
      } = inputs;
      const refetchTimeout = setTimeout(() => {
        dispatch(
          handleChangeSavedFields({
            refetchData: !savedInputs.refetchData,
          })
        );
      }, 500);

      if (updatePatient.pending().type === "patients/update-patient/pending") {
        setIsLoading(true);
      }
      dispatch(
        updatePatient({
          ...formattedData,
          dateOfBirth: moment(inputs.dateOfBirth).format(),
        })
      ).then((response) => {
        const isFetchSuccess = response?.payload?.success;
        const responseData = response?.payload?.data;

        if (isFetchSuccess) {
          dispatch(
            handleChangeSavedFields({
              refetchData: !savedInputs.refetchData,
            })
          );
          if (location.pathname === "/test-request") {
            dispatch(
              handleChangeFields({
                patientEmailForDisplay: responseData?.emails,
              })
            );
            dispatch(
              handleChangeSavedFields({
                searchPatientName: `${
                  responseData?.lastname ? responseData?.lastname + "," : ""
                } ${responseData?.firstname ? responseData?.firstname : ""} ${
                  responseData?.middlename ? responseData?.middlename : ""
                }`,
                setSearchMedicalRecordNo: responseData?.medicalRecordNo,
                searchMedicalRecordNo: responseData?.medicalRecordNo,
                medicalRecordNo: responseData?.medicalRecordNo,
              })
            );
          }

          notification.success({
            message: "Updated Patient",
            description: `Successfully updated ${responseData?.firstname} ${responseData?.lastname}`,
          });
          setIsFormDirty(false);
          handleCloseModal();
          setIsLoading(false);
        }
        if (!isFetchSuccess) {
          notification.error({
            message: "Failed to update patient",
            description: errorMessagesDescription(
              response?.payload?.response?.data?.errorMessages
            ),
          });
          setIsLoading(false);
        }
      });
      return () => clearTimeout(refetchTimeout);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        multiStepFormInputs.patientId === 0 &&
        !copyPatientData &&
        location.pathname === "/test-request"
      ) {
        dispatch(
          handleChangeFields({
            firstname: "",
            cardNumber: "",
            lastname: "",
            middlename: "",
            gender: "",
            dateOfBirth: "",
            contactNumber: "",
            emails: [],
            medicalRecordNo: "",
            phAddresses: [],
            governmentId: "",
            isPatientHasAnAcceptedOrder: false,
          })
        );
      }
    }, 300);
  }, [
    location.pathname,
    dispatch,
    multiStepFormInputs.patientId,
    copyPatientData,
  ]);

  const handleDisableMiddleNameInput = (e) => {
    const { checked } = e.target;
    dispatch(
      handleChangeFields({
        middlename: checked ? null : "",
      })
    );
  };

  const handleReCreatePatient = () => {
    setCopyPatientData(true);
    dispatch(
      handleChangeFields({
        id: 0,
        isPatientHasAnAcceptedOrder: false,
      })
    );
    dispatch(
      handleChangeMultiFields({
        patientId: 0,
      })
    );
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Divider sx={{ marginBottom: "1rem" }} textAlign="center">
        <Typography
          variant="h6"
          display="block"
          sx={{ fontWeight: "500", color: "#294991" }}
        >
          {isPatientForUpdate ? "Edit Patient" : "Add Patient"}
        </Typography>
      </Divider>
      <Grid {...threeGridOptions} container>
        <Grid {...threeGrid} item>
          <Typography variant="p" sx={inputLabelStyle} className="required">
            First Name
          </Typography>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            <TextField
              disabled={inputs.isPatientHasAnAcceptedOrder}
              error={!inputs.firstname && isFormDirty}
              sx={formInputStyle}
              value={inputs.firstname}
              autoSave={false}
              placeholder="Enter your first name..."
              size="small"
              variant="outlined"
              onChange={(e) =>
                dispatch(handleChangeFields({ firstname: e.target.value }))
              }
            />
          )}
          {showInputErrorMessage(inputs.firstname, isFormDirty, "First Name")}
        </Grid>
        <Grid {...threeGrid} item>
          <div className="flex items-center justify-between">
            <Typography
              variant="p"
              sx={inputLabelStyle}
              className={!disableMiddleNameInput ? "required" : ""}
            >
              Middle Name
            </Typography>
            {!inputs.isPatientHasAnAcceptedOrder && !inputs.middlename && (
              <div className="relative flex items-center justify-center gap-1 pr-3 text-md">
                <Checkbox
                  checked={inputs.middlename === null}
                  value={disableMiddleNameInput}
                  onChange={handleDisableMiddleNameInput}
                >
                  N/A
                </Checkbox>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="right-start"
                  title="If middle name will be empty, please check this"
                  arrow
                >
                  <InfoRoundedIcon
                    sx={{
                      position: "absolute",
                      top: -2,
                      right: 3,
                      fontSize: "1rem",
                      color: "defaultBlue.main",
                      ":hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            <TextField
              disabled={
                inputs.isPatientHasAnAcceptedOrder || disableMiddleNameInput
              }
              error={isMiddleNameEmpty && isFormDirty}
              sx={formInputStyle}
              value={inputs.middlename}
              onChange={(e) =>
                dispatch(handleChangeFields({ middlename: e.target.value }))
              }
              autoSave={false}
              placeholder="Enter your middle name..."
              size="small"
              variant="outlined"
            />
          )}
          {!disableMiddleNameInput &&
            showInputErrorMessage(
              inputs.middlename,
              isFormDirty,
              "Middle Name"
            )}
        </Grid>
        <Grid {...threeGrid} xs={12} sm={12} md={4} lg={4} xl={4} item>
          <Typography variant="p" sx={inputLabelStyle} className="required">
            Last Name
          </Typography>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            <TextField
              disabled={inputs.isPatientHasAnAcceptedOrder}
              error={!inputs.lastname && isFormDirty}
              sx={formInputStyle}
              value={inputs.lastname}
              onChange={(e) =>
                dispatch(handleChangeFields({ lastname: e.target.value }))
              }
              autoSave={false}
              placeholder="Enter your last name..."
              size="small"
              variant="outlined"
            />
          )}
          {showInputErrorMessage(inputs.lastname, isFormDirty, "Last Name")}
        </Grid>
      </Grid>
      <Grid {...threeGridOptions} container>
        <Grid {...threeGrid} item>
          <Typography variant="p" sx={inputLabelStyle}>
            ABC Lab Member Number
          </Typography>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            <TextField
              disabled={true}
              sx={formInputStyle}
              value={inputs.cardNumber}
              autoSave={false}
              placeholder="Enter your abc member card number..."
              size="small"
              variant="outlined"
              onChange={(e) =>
                dispatch(handleChangeFields({ cardNumber: e.target.value }))
              }
            />
          )}
        </Grid>
        <Grid {...threeGrid} item>
          <Typography variant="p" sx={inputLabelStyle}>
            Contact Number
          </Typography>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            <TextField
              sx={formInputStyle}
              value={inputs.contactNumber}
              autoSave={false}
              placeholder="Enter your contact number..."
              size="small"
              variant="outlined"
              onChange={(e) =>
                dispatch(handleChangeFields({ contactNumber: e.target.value }))
              }
            />
          )}
        </Grid>
        <Grid {...threeGrid} item>
          <Typography variant="p" sx={inputLabelStyle}>
            Medical Record No.
          </Typography>
          {isPatientDataLoading ? (
            inputSkeleton
          ) : (
            <TextField
              sx={formInputStyle}
              value={inputs.medicalRecordNo}
              autoSave={false}
              placeholder="Enter your medical record no..."
              size="small"
              variant="outlined"
              onChange={(e) =>
                dispatch(
                  handleChangeFields({ medicalRecordNo: e.target.value })
                )
              }
            />
          )}
        </Grid>
      </Grid>
      <Divider textAlign="center" sx={{ margin: "2rem 0" }}>
        <Chip label="Address" />
      </Divider>
      <AddressSelector
        countryOptions={countryOptions}
        phAddressesTypeOptions={phAddressesTypeOptions}
        provinceOptions={provinceOptions}
        cityMunicipalityOptions={cityMunicipalityOptions}
        barangayOptions={barangayOptions}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1.5rem",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormControl error={!inputs.gender && isFormDirty}>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              className="required"
            >
              Sex at Birth
            </Typography>
            {isPatientDataLoading ? (
              genderSkeleton
            ) : (
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  disabled={inputs.isPatientHasAnAcceptedOrder}
                  value="Male"
                  checked={inputs.gender === "Male"}
                  onChange={(e) =>
                    dispatch(handleChangeFields({ gender: e.target.value }))
                  }
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                          {
                            color:
                              !inputs.gender && isFormDirty
                                ? "red"
                                : "darkBlue.secondary",
                          },
                        "& .MuiSvgIcon-root": {
                          fontSize: 22,
                        },
                      }}
                    />
                  }
                  label="Male"
                />
                <FormControlLabel
                  disabled={inputs.isPatientHasAnAcceptedOrder}
                  checked={inputs.gender === "Female"}
                  onChange={(e) =>
                    dispatch(handleChangeFields({ gender: e.target.value }))
                  }
                  value="Female"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                          {
                            color:
                              !inputs.gender && isFormDirty
                                ? "red"
                                : "darkBlue.secondary",
                          },
                        "& .MuiSvgIcon-root": {
                          fontSize: 22,
                        },
                      }}
                    />
                  }
                  label="Female"
                />
              </RadioGroup>
            )}
          </FormControl>
          {showInputErrorMessage(inputs.gender, isFormDirty, "Gender")}
        </Box>
        <Box
          sx={{
            display: isPatientForUpdate ? "flex" : "none",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          >
            Status
          </Typography>
          {isPatientDataLoading ? (
            statusSkeleton
          ) : (
            <Switch
              onChange={handleSelectStatus}
              checked={inputs.isActive}
              checkedChildren={"Active"}
              unCheckedChildren={"Inactive"}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            className="required"
          >
            Date of Birth
          </Typography>
          {isPatientDataLoading ? (
            birthDateSkeleton
          ) : (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disabled={inputs.isPatientHasAnAcceptedOrder}
                slotProps={{
                  textField: {
                    error:
                      (!inputs.dateOfBirth && isFormDirty) ||
                      (inputs.dateOfBirth && isProvidedDateOfBirthInFuture),
                    size: "small",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "2px solid",
                      borderColor: "darkBlue.secondary",
                    },
                  },
                }}
                value={inputs.dateOfBirth || null}
                onChange={(newValue) =>
                  dispatch(handleChangeFields({ dateOfBirth: newValue }))
                }
                inputFormat="MM/DD/YYYY"
                disableFuture={true}
                components={{
                  OpenPickerIcon: CalendarMonthIcon,
                }}
              />
            </LocalizationProvider>
          )}
          {showInputErrorMessage(inputs.dateOfBirth, isFormDirty, "Birthdate")}
          {inputs.dateOfBirth && isProvidedDateOfBirthInFuture && (
            <Typography
              sx={{
                fontSize: "0.7rem",
                color: "red",
              }}
            >
              Date of birth cannot be set in future
            </Typography>
          )}
        </Box>
      </Box>
      {allowEmailSendingOfRequest && (
        <Box sx={{ marginTop: "1.5rem", position: "relative" }}>
          <Typography variant="p" sx={inputLabelStyle}>
            Email Address
          </Typography>
          {isPatientDataLoading ? (
            fullWidthSkeleton
          ) : (
            <div
              onKeyDown={handleEnterEmail}
              tabIndex={1}
              className={styles["email-input-wrapper"]}
              onClick={() => emailRef.current.focus()}
            >
              <ul>
                {inputs?.emails?.map((email) => (
                  <li>
                    <Chip
                      color="primary"
                      variant="outline"
                      avatar={<Avatar>{email.charAt(0).toUpperCase()}</Avatar>}
                      label={email}
                      onDelete={() => handleRemoveEmail(email)}
                    />
                  </li>
                ))}
              </ul>
              <input
                ref={emailRef}
                type="text"
                name="emails"
                value={inputEmail}
                onKeyDown={(e) => handleEnterEmail(e)}
                onChange={(e) => {
                  setInputEmail(e.target.value);
                  setErrorMessage("");
                }}
                autoComplete="false"
                placeholder="Enter your email address..."
              />
            </div>
          )}
          {errorMessage && (
            <p className={styles["error-message"]}>{errorMessage}</p>
          )}
          {inputEmail?.includes("@") &&
            inputEmail?.length > 6 &&
            !errorMessage && (
              <div className={styles["email-suggestion-box"]}>
                <p
                  onKeyDown={() => handleAddEmail(inputEmail)}
                  tabIndex={0}
                  onClick={() => handleAddEmail(inputEmail)}
                >
                  {inputEmail}
                </p>
              </div>
            )}
        </Box>
      )}
      <Box sx={{ marginTop: "1.5rem" }}>
        <Typography variant="p" sx={inputLabelStyle}>
          Government ID
        </Typography>
        {isPatientDataLoading ? (
          fullWidthSkeleton
        ) : (
          <TextField
            onChange={(e) =>
              dispatch(handleChangeFields({ governmentId: e.target.value }))
            }
            sx={formInputStyle}
            value={inputs.governmentId}
            autoSave={false}
            placeholder="Enter your government id..."
            size="small"
            variant="outlined"
          />
        )}
      </Box>
      {inputs.isPatientHasAnAcceptedOrder && (
        <Box
          sx={{
            marginBottom: "1.3rem",
            marginTop: "1.5rem",
            textAlign: "center",
            px: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontStyle: "italic",
              borderLeft: "2px solid red",
              paddingLeft: "0.3rem",
            }}
            variant="p"
          >
            Some input fields cannot be updated and are disabled because{" "}
            <Typography sx={{ fontWeight: "bold" }} variant="p">
              ABC Laboratories
            </Typography>{" "}
            has already accepted this patient's test order. For any concerns,
            please contact our Client Service.
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: 2,
          marginTop: !inputs.isPatientHasAnAcceptedOrder && "1.5rem",
        }}
      >
        {isPatientForUpdate && inputs.isPatientHasAnAcceptedOrder && (
          <PopConfirm
            cancelText="Cancel"
            description="Are you sure you want to create new patient using this data?"
            okText="Confirm"
            placement="leftTop"
            title="Create new patient"
            onConfirm={handleReCreatePatient}
          >
            <Button
              variant="contained"
              color="success"
              endIcon={<FileCopyIcon />}
            >
              Copy to NEW
            </Button>
          </PopConfirm>
        )}
        {!isRequiredInputsEmpty && (
          <PopConfirm
            cancelText="Cancel"
            description="Are you sure that all the information you've input is correct?"
            okText="Confirm"
            onConfirm={
              isPatientForUpdate ? handleUpdatePatient : handleCreatePatient
            }
            placement="leftTop"
            title="Please double check input fields"
          >
            <LoadingButton
              size="medium"
              type="button"
              loading={isLoading}
              variant="contained"
              endIcon={isPatientForUpdate ? <SaveIcon /> : <SendIcon />}
            >
              {isPatientForUpdate ? "Save Changes" : "Submit"}
            </LoadingButton>
          </PopConfirm>
        )}
        {isRequiredInputsEmpty && (
          <LoadingButton
            size="medium"
            type="button"
            loading={isLoading}
            variant="contained"
            endIcon={isPatientForUpdate ? <SaveIcon /> : <SendIcon />}
            onClick={
              isPatientForUpdate ? handleUpdatePatient : handleCreatePatient
            }
          >
            {isPatientForUpdate ? "Save Changes" : "Submit"}
          </LoadingButton>
        )}
        <Button
          onClick={handleCloseFormModal}
          size="medium"
          variant="dark"
          endIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUpdatePatientModal;
