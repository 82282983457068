import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clientLookUp } from "store";
import { sortDataAlphabetically } from "utils/sortDataAlphabetically";

const ClientSelectFilter = ({ selectedClientIds, handleSelectClientId }) => {
  const [clientData, setClientData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const fetchClientDatas = () => {
    if (clientLookUp.pending().type === "client/client-lookup/pending") {
      setIsLoading(true);
    }
    dispatch(clientLookUp()).then((res) => {
      const isFetchSuccessful = res?.payload?.data;

      if (isFetchSuccessful) {
        setClientData(res?.payload?.data);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchClientDatas();
    // eslint-disable-next-line
  }, []);

  const clientOptions =
    clientData?.length > 0 &&
    sortDataAlphabetically(clientData, "clientName")?.map((client) => ({
      label: client?.displayName,
      value: client?.id,
    }));

  return (
    <Select
      allowClear
      loading={isLoading}
      mode="multiple"
      style={{ width: selectedClientIds.length > 0 ? "auto" : 250 }}
      options={clientOptions}
      value={selectedClientIds}
      onChange={handleSelectClientId}
      maxTagCount={1}
      placeholder="Select client"
      virtual={false}
      filterOption={filterOption}
    />
  );
};

export default ClientSelectFilter;
