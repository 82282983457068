import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/mnl/global/menuBar";
import { theme } from "components/mnl/global/theme";
import GetAllICD10Codes from "components/mnl/admin/icdCodes/getAllICD10Codes";
import V2sessionTimeout from "components/mnl/global/v2SessionTimeout";
import TableLayout from "views/mnl/layout/tableLayout";

const Icd10CodesPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"ICD10 Code"} />
        <TableLayout>
          <GetAllICD10Codes />
        </TableLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default Icd10CodesPage;
