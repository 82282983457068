import {
  Autocomplete,
  Box,
  Button,
  LinearProgress,
  TextField,
} from "@mui/material";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import notification from "components/mnl/global/openNotification";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clientLookUp, createLrfPickupRequest } from "store";
import { sortDataAlphabetically } from "utils/sortDataAlphabetically";

const CreateLrfPickupRequest = () => {
  const [clientsData, setClientsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const hasClientsData = clientsData?.length > 0;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clientLookUp()).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        setClientsData(res?.payload?.data);
      }
    });
  }, [dispatch]);

  const formattedClientOptions =
    hasClientsData &&
    sortDataAlphabetically(clientsData, "clientName")?.map((client) => ({
      label: client?.displayName,
      id: client?.id,
    }));

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      createLrfPickupRequest.pending().type ===
      "test-orders-minimal/create-lrf-pickup-request/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(
      createLrfPickupRequest({
        clientId: selectedClientId,
      })
    ).then((res) => {
      const isPostSuccessful = res?.payload?.success;

      if (isPostSuccessful) {
        notification.success({
          message: "Created LRF Pickup Request",
          description: "Successfully created lrf pickup request",
        });
        setSelectedClientId(null);
        setIsLoading(false);
      }

      if (!isPostSuccessful) {
        notification.error({
          message: "Failed to create LRF Pickup Request",
          description: errorMessagesDescription(
            res.payload?.response.data.errorMessages
          ),
        });
        setIsLoading(false);
      }
    });
  };

  const onClientIdChange = (event, newValue) => {
    if (!newValue) return;
    setSelectedClientId(newValue.id);
  };

  const renderSelectedClientName = (clientId) => {
    const isClientIdNull = clientId === null;

    const clientName = isClientIdNull
      ? ""
      : formattedClientOptions?.find((client) => client.id === clientId)?.label;

    return clientName;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "50vh",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "25rem",
          padding: "1.2rem 0.8rem",
          backgroundColor: "whitesmoke",
          borderRadius: "0.5rem",
          boxShadow: "1px 1px 5px",
          position: "relative",
        }}
        component="form"
        onSubmit={onSubmit}
      >
        {isLoading && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          />
        )}
        <h1 className="text-center text-2xl font-bold my-3">
          LRF Pickup Request
        </h1>
        <label className="required">Client</label>
        <Autocomplete
          disabled={!hasClientsData}
          disableClearable
          disablePortal
          options={formattedClientOptions}
          value={renderSelectedClientName(selectedClientId)}
          sx={{ width: "100%", backgroundColor: "white" }}
          onChange={onClientIdChange}
          renderInput={(params) => (
            <TextField {...params} label="Select Client..." />
          )}
        />
        <Button
          disabled={isLoading}
          sx={{ width: "100%", mt: 2 }}
          type="submit"
          variant="contained"
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateLrfPickupRequest;
