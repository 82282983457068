import React, { useCallback, useEffect, useState } from "react";
import { EmptyData } from "../global/emptyData";
import { Button, ConfigProvider, Popconfirm, Table } from "antd";
import DisplayFilterOptions from "./filterOptions/displayFilterOptions";
import { getAllSupplyRequest } from "store";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useCurrentHeight } from "utils/getScreenSize";
import { renderSupplyRequestStatusTag } from "./renderSupplyRequestStatusTag";
import useDebounce from "hooks/useDebounce";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import DescriptionIcon from "@mui/icons-material/Description";
import CancelIcon from "@mui/icons-material/Cancel";
import AuditTrailModal from "./auditTrail/auditTrailModal";
import { cancelSupplyRequest } from "store";
import { showToastNotification } from "utils/showToastNotification";
import SupplyRequestDetailsModal from "./supplyRequestDetailsModal";
import errorMessagesDescription from "../global/errorMessagesDescription";
import notification from "../global/openNotification";

const GetAllSupplyRequest = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [supplyRequestData, setSupplyRequestData] = useState([]);
  // Filter Inputs
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [isDescending, setIsDescending] = useState(true);
  const [sortField] = useState("");
  const [requestId, setRequestId] = useState("");
  const [labIssueId, setLabIssueId] = useState("");
  const debouncedSupplyRequestIdValue = useDebounce(requestId);
  const debouncedSupplyRequestItemIdValue = useDebounce(labIssueId);
  const [requestStatus, setRequestStatus] = useState(null);
  const [clientCode, setClientCode] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [totalSupplyRequest, setTotalSupplyRequest] = useState(null);
  const [openAuditTrailModal, setOpenAuditTrailModal] = useState(false);
  const [openSupplyRequestDetailsModal, setOpenSupplyRequestDetailsModal] =
    useState(false);
  const [selectedSupplyRequestDetails, setSelectedSupplyRequestDetails] =
    useState(null);
  const [selectedRequestIdForAuditTrail, setSelectedRequestIdForAuditTrail] =
    useState(null);
  const [isCancellationPending, setIsCancellationPending] = useState(false);

  const dateFromValue = selectedDates[0];
  const dateToValue = selectedDates[1];
  const isSelectedDateEmpty = selectedDates.length === 0;
  const isDateFromEmpty = dateFromValue === "";
  const isDateToEmpty = dateToValue === "";

  const dateFrom = new Date(dateFromValue?.toString());
  const dateTo = new Date(dateToValue?.toString());

  const onChange = (page) => {
    setPageIndex(page);
  };

  const onShowSizeChange = (_, pageSize) => {
    setPageSize(pageSize);
  };

  const renderEmptyData = () => (
    <EmptyData description="No Supply Request Detected" />
  );

  const handleFetchSupplyRequestData = useCallback(() => {
    if (
      getAllSupplyRequest.pending().type ===
      "supply-orders/get-all-supply-request/pending"
    ) {
      setIsLoading(true);
    }

    try {
      dispatch(
        getAllSupplyRequest({
          pageSize: pageSize,
          pageIndex: pageIndex,
          isDescending: isDescending,
          clientCode: clientCode,
          sortField: sortField,
          requestId: debouncedSupplyRequestIdValue,
          requestStatus: requestStatus,
          labIssueId: debouncedSupplyRequestItemIdValue,
          startDate: isSelectedDateEmpty || isDateFromEmpty ? null : dateFrom,
          endDate: isSelectedDateEmpty || isDateToEmpty ? null : dateTo,
        })
      ).then((res) => {
        const isFetchSuccessful = res?.payload?.success;

        if (isFetchSuccessful) {
          setSupplyRequestData(res?.payload?.data?.items);
          setIsLoading(false);
          setTotalSupplyRequest(res?.payload?.data?.totalPages * pageSize);
        }
      });
    } catch (error) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    pageSize,
    pageIndex,
    isDescending,
    sortField,
    debouncedSupplyRequestIdValue,
    debouncedSupplyRequestItemIdValue,
    requestStatus,
    clientCode,
    isDateFromEmpty,
    isDateToEmpty,
    isSelectedDateEmpty,
  ]);

  const handleCancelSupplyRequest = (requestId) => {
    dispatch(
      cancelSupplyRequest({
        requestId: requestId,
      })
    ).then((res) => {
      const isCancellationSuccessful = res?.payload?.success;

      if (isCancellationSuccessful) {
        showToastNotification(
          "success",
          "Successfully cancelled supply request."
        );
        handleFetchSupplyRequestData();
      }

      if (!isCancellationSuccessful) {
        setIsCancellationPending(false);
        notification.error({
          message: "Supply Request",
          description: errorMessagesDescription(
            res?.payload?.response?.data?.errorMessages
          ),
        });
      }
    });
  };

  useEffect(() => {
    handleFetchSupplyRequestData();
  }, [dispatch, handleFetchSupplyRequestData]);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      width: 30,
      render: (value) => <span className="font-[400] leading-4">{value}</span>,
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (value) => <span className="font-[400] leading-4">{value}</span>,
      width: 100,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (value) => <span className="font-[400] leading-4">{value}</span>,
      width: 70,
    },
    {
      title: "Requested by",
      dataIndex: "requestedBy",
      key: "requestedBy",
      render: (value) => <span className="font-[400] leading-4">{value}</span>,
      width: 60,
    },
    {
      title: "Date Requested",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (value) => (
        <span className="font-[400] leading-4">
          {moment(value).format("LLL")}
        </span>
      ),
      width: 80,
    },
    {
      title: "Order Status",
      dataIndex: "supplyRequestStatus",
      key: "supplyRequestStatus",
      render: (value, record) => {
        const pendingCountValue = record?.statusSummary?.pendingCount;
        const approvedCountValue = record?.statusSummary?.approvedCount;
        const releasedCountValue = record?.statusSummary?.releasedCount;

        const hideStatusSummaryCount =
          pendingCountValue === 0 &&
          approvedCountValue === 0 &&
          releasedCountValue === 0;

        return (
          <div className="space-y-1">
            {renderSupplyRequestStatusTag(value)}
            {!hideStatusSummaryCount && (
              <div className="flex flex-wrap items-center gap-[4px] border-2 border-[#F0F0F0] w-fit rounded-[4px] p-[6px] bg-white ">
                <span className="text-[#848587] border-r border-[#F0F0F0] pr-1">
                  Pending:{" "}
                  <strong className="text-[#383E45]">
                    {pendingCountValue}
                  </strong>
                </span>
                <span className="text-[#848587] border-r border-[#F0F0F0] pr-1">
                  Approved:{" "}
                  <strong className="text-[#383E45]">
                    {approvedCountValue}
                  </strong>
                </span>
                <span className="text-[#848587]">
                  Released:{" "}
                  <strong className="text-[#383E45]">
                    {releasedCountValue}
                  </strong>
                </span>
              </div>
            )}
          </div>
        );
      },
      width: 160,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "",
      render: (record) => {
        const supplyRequestStatusValue = record?.supplyRequestStatus;
        const showCancellationButton =
          supplyRequestStatusValue !== "Cancelled" &&
          supplyRequestStatusValue !== "Completed";

        return (
          <div className="flex items-center gap-1 flex-wrap">
            <Button
              size="middle"
              style={{
                backgroundColor: "#1677ff",
                color: "white",
                fontSize: "14px",
              }}
              icon={<InfoRoundedIcon fontSize="inherit" />}
              onClick={() => {
                setSelectedSupplyRequestDetails(record);
                setOpenSupplyRequestDetailsModal(true);
              }}
            >
              Details
            </Button>
            <Button
              size="middle"
              style={{
                backgroundColor: "#2C7C74",
                color: "white",
                fontSize: "14px",
              }}
              onClick={() => {
                setSelectedRequestIdForAuditTrail(record?.id);
                setOpenAuditTrailModal(true);
              }}
              icon={<DescriptionIcon fontSize="inherit" />}
            >
              Audit Trail
            </Button>
            {showCancellationButton && (
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => handleCancelSupplyRequest(record?.id)}
                okButtonProps={{
                  loading: isCancellationPending,
                }}
                cancelButtonProps={{
                  loading: isCancellationPending,
                }}
              >
                <Button
                  size="middle"
                  style={{
                    backgroundColor: "#FF5050",
                    color: "white",
                    fontSize: "14px",
                  }}
                  loading={isCancellationPending}
                  icon={<CancelIcon fontSize="inherit" />}
                >
                  Cancel
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
      width: 150,
    },
  ];

  const handleSearchSupplyRequestId = (e) => {
    setRequestId(e.target.value);
  };

  const handleSearchSupplyRequestItemId = (e) => {
    setLabIssueId(e.target.value);
  };

  const handleSelectClient = (value) => {
    setClientCode(value);
  };

  const handleDateChange = (dateString) => {
    setSelectedDates(dateString);
  };

  const handleSelectSupplyRequestStatus = (value) => {
    setRequestStatus(value);
  };

  const handleSelectSortDirection = (value) => {
    setIsDescending(value);
  };

  return (
    <div>
      <DisplayFilterOptions
        handleChangeSearchSupplyRequestId={handleSearchSupplyRequestId}
        handleChangeSearchSupplyRequestItemId={handleSearchSupplyRequestItemId}
        searchSupplyRequestIdValue={requestId}
        searchSupplyRequestItemIdValue={labIssueId}
        isDescending={isDescending}
        handleSelectClient={handleSelectClient}
        handleDateChange={handleDateChange}
        selectedDates={selectedDates}
        handleSelectSupplyRequestStatus={handleSelectSupplyRequestStatus}
        selectedSupplyRequestStatus={requestStatus}
        handleFetchSupplyRequestData={handleFetchSupplyRequestData}
        handleSelectSortDirection={handleSelectSortDirection}
      />
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-[#DFE5ED] h-[60px]"
          columns={columns}
          dataSource={supplyRequestData}
          loading={isLoading}
          size="small"
          pagination={{
            total: totalSupplyRequest,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: pageIndex,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <AuditTrailModal
        openModal={openAuditTrailModal}
        onCloseModal={() => {
          setOpenAuditTrailModal(false);
          setSelectedRequestIdForAuditTrail(null);
        }}
        supplyRequestId={selectedRequestIdForAuditTrail}
      />
      <SupplyRequestDetailsModal
        openModal={openSupplyRequestDetailsModal}
        onCloseModal={() => {
          setOpenSupplyRequestDetailsModal(false);
          setSelectedSupplyRequestDetails(null);
        }}
        supplyRequestDetails={selectedSupplyRequestDetails}
      />
    </div>
  );
};

export default GetAllSupplyRequest;
