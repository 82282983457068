import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import GetAllLabTest from "components/mnl/admin/labtest/getAllLabTest";
import MenuBar from "components/mnl/global/menuBar";
import { theme } from "components/mnl/global/theme";
import V2sessionTimeout from "components/mnl/global/v2SessionTimeout";
import TableLayout from "views/mnl/layout/tableLayout";

const LabTestPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Laboratory Test"} />
        <TableLayout>
          <GetAllLabTest />
        </TableLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default LabTestPage;
