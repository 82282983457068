import { Button, Modal } from "antd";
import React, { useState } from "react";
import gradientLine from "../../../assests/supply-request-gradient.svg";
import CreateSupplyRequestFormInputs from "./createSupplyRequestFormInputs";
import CreatedSupplyRequestItem from "./createdSupplyRequestItem";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { createSupplyOrder } from "store";
import { useDispatch, useSelector } from "react-redux";
import notification from "../global/openNotification";
import errorMessagesDescription from "../global/errorMessagesDescription";

const REQUESTED_ITEM_INPUTS = {
  prevSelectedInventoryItemId: null,
  inventoryItemId: null,
  quantityRequested: 1,
  purpose: null,
};

const SUPPLY_REQUEST_FORM_INPUTS = {
  requestedItems: [],
  clientCode: null,
  createdBy: "",
  requestedBy: "",
  requestSource: null,
};

const CreateSupplyRequestModal = ({
  openModal,
  onCloseModal,
  handleFetchSupplyRequestData,
}) => {
  const { isClientAbcLab, clientCode } = parsedClientDetails();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestedItemInputs, setRequestedItemInputs] = useState(
    REQUESTED_ITEM_INPUTS
  );

  const [supplyRequestFormInputs, setSupplyRequestFormInputs] = useState(
    SUPPLY_REQUEST_FORM_INPUTS
  );
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isDataForUpdate, setIsDataForUpdate] = useState(false);

  const hasAddedRequestedItems =
    supplyRequestFormInputs.requestedItems.length > 0;

  const handleChangeSupplyRequestFormInputs = (id, value) => {
    setSupplyRequestFormInputs({
      ...supplyRequestFormInputs,
      [id]: value,
    });
  };

  const handleChangeRequestedItemInputs = (id, value) => {
    setRequestedItemInputs({
      ...requestedItemInputs,
      [id]: value,
    });
  };

  const resetRequestedItemInputs = () => {
    setRequestedItemInputs(REQUESTED_ITEM_INPUTS);
  };

  const resetSupplyRequestItemInputs = () => {
    setSupplyRequestFormInputs(SUPPLY_REQUEST_FORM_INPUTS);
  };

  const closeModal = () => {
    resetSupplyRequestItemInputs();
    resetRequestedItemInputs();
    onCloseModal();
    setIsFormDirty(false);
  };

  const isOuterInputsEmpty = () => {
    if (
      (isClientAbcLab && supplyRequestFormInputs.clientCode === null) ||
      (isClientAbcLab &&
        (supplyRequestFormInputs.requestedBy === "" ||
          supplyRequestFormInputs.requestedBy === null))
    ) {
      return true;
    }
    return false;
  };

  const onSubmit = () => {
    if (isOuterInputsEmpty()) {
      setIsFormDirty(true);
    } else {
      if (
        createSupplyOrder.pending().type ===
        "supply-orders/create-supply-order/pending"
      ) {
        setIsSubmitting(true);
      }
      const formattedRequestedItems =
        supplyRequestFormInputs.requestedItems.map(
          ({ prevSelectedInventoryItemId, ...rest }) => rest
        );
      dispatch(
        createSupplyOrder({
          requestedItems: formattedRequestedItems,
          clientCode: isClientAbcLab
            ? supplyRequestFormInputs.clientCode
            : clientCode,
          createdBy: user?.displayName,
          requestedBy: !isClientAbcLab
            ? user?.displayName
            : supplyRequestFormInputs.requestedBy,
          requestSource: isClientAbcLab ? "ERequestCS" : "ERequestClient",
        })
      ).then((res) => {
        const isPostSuccessful = res?.payload?.success;

        if (isPostSuccessful) {
          handleFetchSupplyRequestData();
          closeModal();
          setIsSubmitting(false);
          notification.success({
            message: "Created Supply Order",
            description: "Successfully created supply order",
          });
          setIsDataForUpdate(false);
          setIsFormDirty(false);
        }

        if (!isPostSuccessful) {
          setIsSubmitting(false);
          notification.error({
            message: "Supply Request",
            description: errorMessagesDescription(
              res?.payload?.response?.data?.errorMessages
            ),
          });
        }
      });
    }
  };

  return (
    <Modal
      open={openModal}
      onCancel={onCloseModal}
      footer={null}
      width={860}
      closable={false}
    >
      <div
        className="relative overflow-y-auto"
        style={{ maxHeight: "calc(100vh - 300px)" }}
      >
        <div className="bg-[#dfe5ed] w-full pt-7 rounded-[4px] px-[10px] py-[4px]">
          <img className="mt-1" alt="gradient" src={gradientLine} />
          <span className=" absolute top-4 w-max left-0 right-0 mx-auto text-[20px] font-[700] bg-[#1976D2] text-white px-[10px] py-[4px] rounded-[4px]">
            Create Supply Request
          </span>
          <CreateSupplyRequestFormInputs
            supplyRequestFormInputs={supplyRequestFormInputs}
            requestedItemInputs={requestedItemInputs}
            handleChangeSupplyRequestFormInputs={
              handleChangeSupplyRequestFormInputs
            }
            handleChangeRequestedItemInputs={handleChangeRequestedItemInputs}
            resetRequestedItemInputs={resetRequestedItemInputs}
            isFormDirty={isFormDirty}
            isDataForUpdate={isDataForUpdate}
            setSupplyRequestFormInputs={setSupplyRequestFormInputs}
            setIsDataForUpdate={setIsDataForUpdate}
          />
        </div>
        {/* Created Supply Request Item */}
        {hasAddedRequestedItems && (
          <div className="bg-[#dfe5ed]">
            <CreatedSupplyRequestItem
              supplyRequestFormInputs={supplyRequestFormInputs}
              setRequestedItemInputs={setRequestedItemInputs}
              setSupplyRequestFormInputs={setSupplyRequestFormInputs}
              setIsDataForUpdate={setIsDataForUpdate}
            />
          </div>
        )}
        {/* Submit Button */}
        <div className="flex justify-center items-center gap-2 mt-4">
          <Button
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={closeModal}
            className="text-[#1976D2]"
            type="text"
          >
            Cancel
          </Button>
          <Button
            loading={isSubmitting}
            disabled={
              isSubmitting ||
              supplyRequestFormInputs.requestedItems.length === 0
            }
            onClick={onSubmit}
            className="bg-[#1976D2]"
            type="primary"
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateSupplyRequestModal;
