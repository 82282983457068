import { Box, Grid, Typography } from "@mui/material";
import { forwardRef } from "react";
import styles from "./styles.module.css";
import abcLogo from "assests/abc.png";
import moment from "moment";
import { Empty, Spin } from "antd";
import QRCode from "react-qr-code";

const PreviousOrdersContent = forwardRef((props, ref) => {
  const {
    clientDetails,
    paginatedOrderData,
    printPreviousTestOrders,
    isPreviousTestOrderFetching,
  } = props;

  const getPatientAge = (dateOfBirth) => {
    const patientDateOfBirth = new Date(dateOfBirth);
    const monthDiff = new Date() - patientDateOfBirth.getTime();
    const ageDiff = new Date(monthDiff);
    const year = ageDiff.getUTCFullYear();
    const patientAge = Math.abs(year - 1970);
    return patientAge;
  };

  const getGridSizeBeforePrint = (gridSizeDuringPrint, defaultGridSize) => {
    const gridSize = printPreviousTestOrders
      ? gridSizeDuringPrint
      : defaultGridSize;

    return gridSize;
  };

  const renderGridItem = (label, element) => {
    return (
      <Box className={styles["grid-item"]}>
        <span className={styles.label}>{label}</span>
        <span className={styles.colon}>:</span>
        {element}
      </Box>
    );
  };

  return (
    <Box
      ref={ref}
      sx={{
        flexGrow: 1,
        padding: {
          xs: "0.3rem",
          sm: "0.3rem",
          md: "0.9rem",
          lg: "1.5rem",
          xl: "1.5rem",
        },
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {!isPreviousTestOrderFetching && paginatedOrderData.length === 0 && (
          <Empty />
        )}
      </Box>
      {isPreviousTestOrderFetching ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "5rem 0",
          }}
        >
          <Spin tip="Please wait..." size="large" />
        </Box>
      ) : (
        paginatedOrderData?.map((data) => {
          const isMinimalOrder = data?.isMinimalOrder;
          const providerTitle = !data?.provider?.title
            ? ""
            : data?.provider?.title && !data?.provider?.title.includes(".")
            ? data?.provider?.title + "."
            : data?.provider?.title;
          const patientMiddleName = !data.patient?.middleInitial
            ? ""
            : data.patient?.middleInitial &&
              !data.patient?.middleInitial.includes(".")
            ? data.patient?.middleInitial + "."
            : data.patient?.middleInitial;
          const providerFirstName = data?.provider?.firstName
            ? data?.provider?.firstName
            : "";
          const providerLastName = data?.provider?.lastName
            ? data?.provider?.lastName
            : "";
          const dash = data?.provider?.specialty ? " - " : "";
          const providerSpecialty = data?.provider?.specialty
            ? data?.provider?.specialty
            : "";
          const timeCollected =
            data?.patientSpecimens.length === 0
              ? moment().format("MM/DD/YYYY hh:mm a")
              : moment(data?.patientSpecimens[0]?.collectionDateTime).format(
                  "MM/DD/YYYY hh:mm a"
                );

          return (
            <Grid
              key={data.id}
              sx={{
                position: "relative",
                "::after": {
                  content: '""',
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  height: "0.05rem",
                  backgroundColor: "#203977",
                  transform: "translateY(20px)",
                },
                breakInside: "avoid",
                marginBottom: "1rem",
              }}
              container
            >
              <Grid
                sx={{
                  position: "relative",
                  "::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: "0.05rem",
                    backgroundColor: "#203977",
                    transform: "translateY(21px)",
                  },
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
                container
              >
                <Grid
                  xs={getGridSizeBeforePrint(6, 12)}
                  sm={getGridSizeBeforePrint(6, 12)}
                  md={getGridSizeBeforePrint(6, 12)}
                  lg={6}
                  xl={6}
                  item
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontWeight: "500",
                      color: "darkBlue.main",
                      fontSize: "1.4rem",
                    }}
                  >
                    Master Log
                  </Typography>
                  <Box sx={{ display: "flex", marginTop: "0.5rem" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        width: "max-content",
                        color: "black",
                        marginTop: "0.3rem",
                        fontSize: "1rem",
                        marginRight: "2rem",
                        fontWeight: "600",
                      }}
                    >
                      {clientDetails?.clientCode}
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        width: "max-content",
                        color: "black",
                        marginTop: "0.3rem",
                        fontSize: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      {clientDetails?.clientName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  xs={getGridSizeBeforePrint(4, 12)}
                  sm={getGridSizeBeforePrint(4, 12)}
                  md={getGridSizeBeforePrint(4, 12)}
                  lg={4}
                  xl={4}
                  item
                >
                  <Box className={styles["grid-container"]}>
                    <Box>
                      {renderGridItem(
                        "Order date",
                        <span className={styles.value}>
                          {moment(data.orderDate).format("MM/DD/YYYY")}
                        </span>
                      )}
                      {renderGridItem(
                        "Order Status",
                        <span className={styles.value}>{data.orderStatus}</span>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  xs={getGridSizeBeforePrint(2, 12)}
                  sm={getGridSizeBeforePrint(2, 12)}
                  md={getGridSizeBeforePrint(2, 12)}
                  lg={2}
                  xl={2}
                  item
                >
                  <img
                    className={styles["abc-logo"]}
                    alt="ABC Logo"
                    src={abcLogo}
                  />
                </Grid>
                {<p className={styles.divider} />}
              </Grid>
              {!isMinimalOrder && (
                <Grid container>
                  <Grid
                    sx={{ marginRight: "auto" }}
                    xs={getGridSizeBeforePrint(5, 12)}
                    sm={getGridSizeBeforePrint(5, 12)}
                    md={5}
                    lg={5}
                    xl={5}
                    item
                  >
                    <Box className={styles["grid-container"]}>
                      {renderGridItem(
                        "Patient Name",
                        <span className={styles.value}>
                          {data?.patient?.firstname} {patientMiddleName}{" "}
                          {data?.patient?.lastname}
                        </span>
                      )}
                      {renderGridItem(
                        "DoB/Age/Sex",
                        <span className={styles.value}>
                          {moment(data?.patient?.dateOfBirth).format(
                            "MM/DD/YYYY"
                          )}{" "}
                          {getPatientAge(
                            moment(data?.patient?.dateOfBirth).format(
                              "YYYY-MM-DD"
                            )
                          )}
                          Y {data?.patient?.gender}
                        </span>
                      )}

                      {renderGridItem(
                        "Time Collected",
                        <span className={styles.value}>{timeCollected}</span>
                      )}
                      {renderGridItem(
                        "Specimens",
                        <span key={data.id} className={styles.value}>
                          {data?.patientSpecimens
                            ?.map((data) => data?.specimen?.name)
                            .join(", ")}
                        </span>
                      )}
                      {renderGridItem(
                        "Spc. instructions",
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          {data?.specialHandlings?.map((instruction) => {
                            let specialInstructions = "";
                            const statText = (
                              <span className={styles.value}>
                                STAT - [
                                <span className="font-bold text-sm px-1">
                                  {data?.testOrderDetails
                                    ?.filter((test) => test.isStat === true)
                                    ?.map(
                                      (testOrder) => testOrder?.labTest?.name
                                    )
                                    .join(", ")}
                                </span>
                                ]
                              </span>
                            );
                            if (
                              instruction.instructions.toLowerCase() === "stat"
                            ) {
                              specialInstructions = statText;
                            } else {
                              specialInstructions = `${instruction.instructions}`;
                            }
                            return (
                              <span
                                key={instruction.id}
                                className={styles.value}
                              >
                                {specialInstructions}
                              </span>
                            );
                          })}
                        </Box>
                      )}
                      {renderGridItem(
                        "Clin. diagnosis",
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          {data?.diagnosesList?.map((diagnosis) => (
                            <span key={diagnosis.id} className={styles.value}>
                              {diagnosis?.displayName}
                            </span>
                          ))}
                        </Box>
                      )}
                      {renderGridItem(
                        "Tests",
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          {data?.testOrderDetails?.map((test) => (
                            <span key={test} className={styles.value}>
                              {test?.labTest?.name}
                            </span>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    sx={{ position: "relative" }}
                    xs={getGridSizeBeforePrint(5, 12)}
                    sm={getGridSizeBeforePrint(5, 12)}
                    md={5}
                    lg={5}
                    xl={5}
                    item
                  >
                    <Box className={styles["grid-container"]}>
                      <Box key={data.id}>
                        {renderGridItem(
                          "Physician Name",
                          <span className={styles.value}>
                            {`${providerTitle} ${providerFirstName} ${providerLastName} ${dash} ${providerSpecialty}`}
                          </span>
                        )}
                        {renderGridItem(
                          "Add. Info",
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            {data.testOrderDetails?.map((order) =>
                              order.patientTestRequirementDatas?.map((test) =>
                                test.patientRequirementDataDetails
                                  ?.filter(
                                    (testData) => testData.dataValue !== ""
                                  )
                                  .map((req, reqIndex) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                      key={req.id}
                                    >
                                      <span className={styles.value}>
                                        {req?.description}
                                      </span>
                                      <span
                                        style={{
                                          listStyleType: "disc",
                                          display: "list-item",
                                          marginLeft: "0.9rem",
                                        }}
                                        className={styles.value}
                                      >
                                        {req.dataValue === "true"
                                          ? "Yes"
                                          : req.dataValue === "false"
                                          ? "No"
                                          : req.dataValue}
                                      </span>
                                    </Box>
                                  ))
                              )
                            )}
                          </Box>
                        )}
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: { xs: -18, sm: -18, md: 0, lg: 0, xl: 0 },
                            right: { xs: 0, sm: 0, md: 20, lg: 20, xl: 20 },
                          }}
                        >
                          {data?.autoGeneratedOrderNumber !== null && (
                            <QRCode
                              style={{ transform: "translateX(120px)" }}
                              size={75}
                              value={data?.autoGeneratedOrderNumber + ""}
                            />
                          )}
                          <Box className={styles["grid-item"]}>
                            <span
                              style={{ width: "max-content" }}
                              className={styles.label}
                            >
                              Order No
                            </span>
                            <span
                              style={{
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                              }}
                              className={styles.colon}
                            >
                              :
                            </span>
                            <span
                              style={{
                                marginRight: "0.5rem",
                                width: "max-content",
                              }}
                              className={styles.value}
                            >
                              {data?.autoGeneratedOrderNumber === null ? (
                                <Typography
                                  sx={{
                                    fontStyle: "italic",
                                    color: "softBlue.secondary",
                                    fontWeight: "bold",
                                  }}
                                  variant="p"
                                >
                                  {"[pending]"}
                                </Typography>
                              ) : (
                                data?.autoGeneratedOrderNumber
                              )}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {isMinimalOrder && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <h1 className="text-center text-2xl mt-10 font-semibold">
                    LRF Pickup Request
                  </h1>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      mr: 4,
                    }}
                  >
                    {data?.autoGeneratedOrderNumber !== null && (
                      <QRCode
                        style={{ transform: "translateX(120px)" }}
                        size={75}
                        value={data?.autoGeneratedOrderNumber + ""}
                      />
                    )}
                    <Box>
                      <span
                        style={{ width: "max-content" }}
                        className={styles.label}
                      >
                        Order No :
                      </span>{" "}
                      <span className={styles.value}>
                        {data?.autoGeneratedOrderNumber !== null
                          ? data?.autoGeneratedOrderNumber
                          : ""}
                      </span>
                    </Box>
                  </Box>
                </Box>
              )}
              <p className={styles.divider} />
            </Grid>
          );
        })
      )}
    </Box>
  );
});

export default PreviousOrdersContent;
