import { Box, Typography } from "@mui/material";
import { Modal, Typography as AntDTypography } from "antd";
import React from "react";
import LogDetailsTable from "./logDetailsTable";

const LogDetails = ({
  handleCloseModal,
  selectedErrorDetails,
  openErrorModal,
  modalTitle,
}) => {
  const descMinLength = 620;

  const parsedText = (text) => {
    return JSON.parse(text);
  };

  const exceptionText =
    modalTitle === "Exception" && selectedErrorDetails.exception;
  const parsedLogEventText =
    modalTitle === "Log Event" && parsedText(selectedErrorDetails.logEvent);

  const isDescriptionShort =
    selectedErrorDetails.exception?.length < descMinLength ||
    selectedErrorDetails.logEvent?.length < descMinLength;

  return (
    <Modal
      open={openErrorModal}
      closable={true}
      footer={false}
      onCancel={handleCloseModal}
      width={1000}
      title={
        <AntDTypography style={{ fontSize: "0.9rem" }}>
          <blockquote>{modalTitle}</blockquote>
        </AntDTypography>
      }
    >
      <Box
        sx={{
          height: "30rem",
          mt: 4,
          overflowY: isDescriptionShort ? "auto" : "scroll",
        }}
      >
        <Typography
          variant="p"
          sx={{ lineHeight: "2", fontSize: "1rem", marginTop: "0.7rem" }}
        >
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {/* Log Event Text */}
            {modalTitle === "Log Event" && (
              <LogDetailsTable data={parsedLogEventText} />
            )}
            {/* Exception Text */}
            {modalTitle === "Exception" && (
              <code className="font-bold">{exceptionText}</code>
            )}
          </pre>
        </Typography>
      </Box>
    </Modal>
  );
};

export default LogDetails;
