import { FormControl, TextField } from "@mui/material";
import { Modal } from "antd";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import notification from "components/mnl/global/openNotification";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { cancelTestOrder } from "store";

const CancelTestOrderModal = ({
  id,
  onCancel,
  open,
  refetchTestOrder,
  handleCloseOrderModal,
}) => {
  const [cancelReason, setCancelReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleCancelReasonChange = (e) => {
    const { value } = e.target;
    setCancelReason(value);
  };

  const handleCancel = () => {
    setCancelReason("");
    onCancel();
  };
  const onSubmit = () => {
    if (cancelTestOrder.pending().type === "test-orders/cancel-test-order") {
      setIsLoading(true);
    }
    dispatch(
      cancelTestOrder({
        id: id,
        cancelReason: cancelReason,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        notification.success({
          message: "Cancelled Test Order",
          description: "Successfully cancelled test order",
        });
        onCancel();
        const modalCloseTimeout = setTimeout(() => {
          handleCloseOrderModal();
          refetchTestOrder();
        }, 1200);
        setIsLoading(false);
        setCancelReason("");

        return () => clearTimeout(modalCloseTimeout);
      }

      if (!isFetchSuccessful) {
        notification.error({
          message: "Failed to cancel test order",
          description: errorMessagesDescription(
            res.payload?.response?.data?.errorMessages
          ),
        });
        setIsLoading(false);
      }
    });
  };
  return (
    <Modal
      title="Reason for cancellation"
      okButtonProps={{
        className: "submitBtn",
        disabled: isLoading || cancelReason.length === 0,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      okText="Submit"
      confirmLoading={isLoading}
      centered
      closable={true}
      open={open}
      onCancel={handleCancel}
      onOk={onSubmit}
    >
      <FormControl fullWidth sx={{ m: 1 }} variant="filled">
        <TextField
          value={cancelReason}
          onChange={handleCancelReasonChange}
          sx={{ marginTop: "1.4rem" }}
          id="cancellation-reason"
          label="Enter your reason..."
          multiline
          rows={4}
        />
      </FormControl>
    </Modal>
  );
};

export default CancelTestOrderModal;
