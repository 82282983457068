import { Box, ThemeProvider } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTestOrderByOrderNo,
  getUserInfoById,
  logout,
  updateOrderLabStatus,
  encryptTestOrder,
} from "store";
import { useNavigate, useParams } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import SnackbarNotification from "./snackbarNotification";
import ReasonFormInputModal from "./reasonFormInputModal";
import parseJwt from "utils/parseJwt";
import { handleChangeOrderData } from "store/slice/orderSlice";
import { theme } from "components/mnl/global/theme";
import HeaderContent from "components/mnl/admin/testOrder/headerContent";
import PatientInformation from "components/mnl/admin/testOrder/patientInformation";
import TestProfileSection from "components/mnl/admin/testOrder/testProfileSection";
import BillingInformation from "components/mnl/admin/testOrder/billingInformation";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import ReceivingMenuBar from "./receivingMenuBar";
import ReceivingSearchInput from "./receivingSearchInput";
import { useReactToPrint } from "react-to-print";
import TestOrderToBePrinted from "components/mnl/admin/testOrder/testOrderPrintLayout/testOrderToBePrinted";

const ReceivingScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { testOrderId } = useParams();
  const orderData = useSelector((state) => state.orderData);
  const componentRef = useRef();
  const promiseResolveRef = useRef(null);
  const testOrderDetailsRef = useRef(null);
  const [printTestOrder, setPrintTestOrder] = useState(false);
  const [encryptedTestOrder, setEncryptedTestOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [snackbarOptions, setSnackbarOptions] = useState({
    snackbarMessage: "",
    openSnackbar: false,
    snackbarType: "",
  });
  const [reasonModalControls, setReasonModalControls] = useState({
    showHoldReasonModal: false,
    showRejectReasonModal: false,
  });
  const [inputOrderId, setInputOrderId] = useState("");
  const [userCreatedDetails, setUserCreatedDetails] = useState({});
  const isMinimalOrder = data?.isMinimalOrder;
  const hasFetchedData = data !== null;

  useEffect(() => {
    if (printTestOrder && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [printTestOrder]);

  useEffect(() => {
    if (hasFetchedData) {
      dispatch(
        encryptTestOrder({
          orderId: data?.id,
        })
      ).then((res) => {
        setEncryptedTestOrder(res?.payload?.data);
      });
    }
  }, [dispatch, data?.id, hasFetchedData]);

  useEffect(() => {
    if (!isUserLoggedIn()) {
      window.location.replace("/lab");
    }
  }, []);

  const onBeforePrintContent = () => {
    //Before printing the test order summary, it should display all of the data not the paginated one using a state
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintTestOrder(true);
    });
  };

  const onAfterPrintContent = () => {
    //After printing set the state to false, to re display the paginated data
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintTestOrder(false);
    });
  };

  const handlePrintTestOrder = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: onBeforePrintContent,
    onAfterPrint: onAfterPrintContent,
  });

  const clearInputOrderId = () => {
    setInputOrderId("");
  };

  const fetchTestOrderAndUserDetails = () => {
    if (testOrderId === undefined) return;

    dispatch(getTestOrderByOrderNo({ orderNo: testOrderId })).then((item) => {
      const isTestOrderFetchedSuccessfully = item?.payload?.success;
      if (isTestOrderFetchedSuccessfully) {
        setData(item?.payload?.data);
        //GET USER CREATED
        dispatch(getUserInfoById(item?.payload?.data?.createdBy)).then(
          (userDetailsRes) => {
            const isUserDetailsFetchedSuccessfully =
              userDetailsRes?.payload?.success;
            if (isUserDetailsFetchedSuccessfully) {
              setUserCreatedDetails(userDetailsRes?.payload?.data);
              setIsLoading(false);
            }
          }
        );
      }
      if (!isTestOrderFetchedSuccessfully) {
        navigate("/lab/receiving");
        handleOpenSnackbarNotification(
          `${item?.payload?.response?.data?.errorMessages[0]}`,
          "error"
        );
        setIsLoading(false);
        clearInputOrderId();
      }
    });
  };

  useEffect(() => {
    fetchTestOrderAndUserDetails();
    // eslint-disable-next-line
  }, [testOrderId]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"))?.token;
    if (token === null || token === undefined) {
      dispatch(logout());
      navigate("/lab");
    } else {
      const userDetails = parseJwt(token);
      const isRoleCourier = userDetails?.role?.includes("Courier");
      const isRoleMedicalTechnologist =
        userDetails?.role?.includes("MedicalTechnologist") ||
        userDetails?.role?.includes("Medical Technologist");

      if (
        userDetails?.isSuperAdmin !== "True" &&
        userDetails?.isSuperAdmin !== undefined
      ) {
        if (
          !isRoleCourier &&
          !isRoleMedicalTechnologist &&
          userDetails?.role !== undefined
        ) {
          dispatch(logout());
          navigate("/lab");
        } else if (
          isRoleCourier &&
          !isRoleMedicalTechnologist &&
          userDetails?.role !== undefined
        ) {
          navigate("/lab/tagging");
        }
      }
    }
  }, [dispatch, navigate]);

  const handleChangeInputOrderId = (e) => {
    setInputOrderId(e.target.value);
  };

  const handleCloseReasonModals = () => {
    setReasonModalControls({
      showHoldReasonModal: false,
      showRejectReasonModal: false,
    });
  };
  const handleCloseSnackbarNotification = () => {
    setSnackbarOptions({
      snackbarMessage: "",
      openSnackbar: false,
      snackbarType: "",
    });
  };
  const handleOpenSnackbarNotification = (message, type) => {
    setSnackbarOptions({
      snackbarMessage: message,
      openSnackbar: true,
      snackbarType: type,
    });
  };

  const getOrderStatusName = (labStatus) => {
    const orderStatusNameMap = {
      1: "Accepted",
      4: "Pending",
      9: "Rejected",
      12: "Hold",
    };

    return orderStatusNameMap[labStatus];
  };

  const updateTestOrderStatus = (labStatus) => {
    if (
      updateOrderLabStatus.pending().type ===
      "test-orders/update-test-order-lab-status/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(
      updateOrderLabStatus({
        id: data?.id,
        labStatus: labStatus,
        labStatusComment: orderData.reasonInput,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        handleOpenSnackbarNotification(
          `Successfully changed Order #${
            data?.id
          } status to ${getOrderStatusName(labStatus)}!`,
          "success"
        );
        clearInputOrderId();
        setData(null);
        navigate("/lab/receiving");
        setIsLoading(false);
        dispatch(
          handleChangeOrderData({
            reasonInput: "",
          })
        );
        if (
          reasonModalControls.showHoldReasonModal ||
          reasonModalControls.showRejectReasonModal
        ) {
          handleCloseReasonModals();
        }
      }
      if (!isFetchSuccessful) {
        setIsLoading(false);
      }
    });
  };

  const onClickHoldBtn = () => {
    updateTestOrderStatus(12);
  };

  const onClickAcceptBtn = () => {
    updateTestOrderStatus(1);
  };

  const onClickRejectBtn = () => {
    updateTestOrderStatus(9);
  };

  const onShowHoldReasonModal = () => {
    setReasonModalControls({
      ...reasonModalControls,
      showHoldReasonModal: true,
    });
  };
  const onShowRejectReasonModal = () => {
    setReasonModalControls({
      ...reasonModalControls,
      showRejectReasonModal: true,
    });
  };

  const onReturn = () => {
    setData(null);
    clearInputOrderId();
    navigate("/lab/receiving");
  };

  useEffect(() => {
    if (data !== null) {
      setTimeout(() => {
        window.scrollTo({
          top: testOrderDetailsRef.current.offsetTop - 60,
          behavior: "smooth",
        });
      }, 300);
    }
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 1,
          padding: {
            xs: "0.3rem",
            sm: "0.3rem",
            md: "0.9rem",
            lg: "2rem",
            xl: "2rem",
          },
          maxWidth: "1110px",
          mx: "auto",
        }}
      >
        <ReceivingMenuBar
          orderStatus={data?.orderStatus}
          onClickAcceptBtn={onClickAcceptBtn}
          onReturn={onReturn}
          onShowHoldReasonModal={onShowHoldReasonModal}
          onShowRejectReasonModal={onShowRejectReasonModal}
          isLoading={isLoading}
          isMinimalOrder={isMinimalOrder}
          hasFetchedData={hasFetchedData}
        />
        <Toolbar />
        {testOrderId ? (
          <>
            <HeaderContent
              orderStatus={data?.orderStatus}
              cancelReason={data?.cancelReason}
              cancelledBy={data?.cancelledBy}
              clientDetails={data?.client}
              isLoading={isLoading}
              orderNumber={data?.autoGeneratedOrderNumber}
              handlePrintTestOrder={handlePrintTestOrder}
            />
            {!isMinimalOrder && (
              <div ref={testOrderDetailsRef}>
                <PatientInformation
                  patientDetails={data?.patient}
                  provider={data?.provider}
                  isLoading={isLoading}
                  specimens={data?.patientSpecimens}
                />
                <TestProfileSection
                  testOrderDetails={data?.testOrderDetails}
                  diagnosesList={data?.diagnosesList}
                  isLoading={isLoading}
                  specimens={data?.patientSpecimens}
                  specialHandlings={data?.specialHandlings}
                  courierRemarks={data?.courierRemarks}
                  preparedBy={userCreatedDetails?.displayName}
                />
              </div>
            )}

            {isMinimalOrder && (
              <h1 className="text-center text-3xl mt-10 font-semibold">
                LRF Pickup Request
              </h1>
            )}
            <BillingInformation
              orderStatus={data?.orderStatus}
              cancelReason={data?.cancelReason}
              clientDetails={data?.client}
              isLoading={isLoading}
            />
          </>
        ) : (
          <ReceivingSearchInput
            handleChangeInputOrderId={handleChangeInputOrderId}
            inputOrderId={inputOrderId}
          />
        )}
        <SnackbarNotification
          onOpen={snackbarOptions.openSnackbar}
          onClose={handleCloseSnackbarNotification}
          message={snackbarOptions.snackbarMessage}
          severity={snackbarOptions.snackbarType}
          setSnackbarOptions={setSnackbarOptions}
        />
        {/* Modal for showing hold reason modal */}
        <ReasonFormInputModal
          open={reasonModalControls.showHoldReasonModal}
          handleClose={handleCloseReasonModals}
          modalTitle="Reason for holding this test order"
          onSubmit={onClickHoldBtn}
          isLoading={isLoading}
          status="Hold"
        />
        {/* Modal for showing reject reason modal */}
        <ReasonFormInputModal
          open={reasonModalControls.showRejectReasonModal}
          handleClose={handleCloseReasonModals}
          modalTitle="Reason for rejecting this test order"
          onSubmit={onClickRejectBtn}
          isLoading={isLoading}
          status="Reject"
        />
      </Box>
      {printTestOrder && (
        <TestOrderToBePrinted
          encryptedTestOrder={encryptedTestOrder}
          orderStatus={data?.orderStatus}
          orderNumber={data?.autoGeneratedOrderNumber}
          isLoading={isLoading}
          cancelReason={data?.cancelReason}
          patientDetails={data?.patient}
          provider={data?.provider}
          specimens={data?.patientSpecimens}
          testOrderDetails={data?.testOrderDetails}
          diagnosesList={data?.diagnosesList}
          specialHandlings={data?.specialHandlings}
          courierRemarks={data?.courierRemarks}
          preparedBy={userCreatedDetails?.displayName}
          clientDetails={data?.client}
          isMinimalOrder={data?.isMinimalOrder}
          ref={componentRef}
        />
      )}
    </ThemeProvider>
  );
};
export default ReceivingScreen;
