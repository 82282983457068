import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/mnl/global/menuBar";
import { theme } from "components/mnl/global/theme";
import V2sessionTimeout from "components/mnl/global/v2SessionTimeout";
import QrLayout from "components/mnl/admin/qrRegistration/qrLayout";
import TableLayout from "views/mnl/layout/tableLayout";

const QrRegistrationPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Qr Code Registration"} />
        <TableLayout>
          <QrLayout />
        </TableLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default QrRegistrationPage;
