import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import notification from "components/mnl/global/openNotification";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticateExternalSystemCredential } from "store";
import { isDataEmpty } from "utils/isDataEmpty";
import { parsedClientDetails } from "utils/parsedClientDetails";
import { showInputErrorMessage } from "utils/showInputErrorMessage";

const systemNameOptions = ["LIS", "ECourier", "ESupplies"];

const formInputsData = {
  systemName: null,
  userName: "",
  password: "",
};

const AuthenticateExternalSystemCredentials = () => {
  const [formInputs, setFormInputs] = useState(formInputsData);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emptyUserName = isDataEmpty(formInputs.userName);
  const emptyPassword = isDataEmpty(formInputs.password);
  const { isSuperAdmin } = parsedClientDetails();

  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };

  const handleFormInputsChange = (e) => {
    const { name, value } = e.target;

    setFormInputs({
      ...formInputs,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (emptyUserName || emptyPassword) {
      setIsFormDirty(true);
    } else {
      if (
        authenticateExternalSystemCredential.pending().type ===
        "external-system-credentials/authenticate/pending"
      ) {
        setIsLoading(true);
      }
      dispatch(
        authenticateExternalSystemCredential({
          systemName: isDataEmpty(formInputs.systemName)
            ? null
            : formInputs.systemName,
          userName: formInputs.userName,
          password: formInputs.password,
        })
      ).then((res) => {
        const isAuthenticationSuccessful = res?.payload?.success;

        if (isAuthenticationSuccessful) {
          notification.success({
            message: "Authenticated",
            description: res?.payload?.successMessage,
          });
          setIsLoading(false);
          setFormInputs(formInputsData);
        } else {
          notification.error({
            message: "Failed to Authenticate",
            description: errorMessagesDescription(
              res.payload?.response?.data.errorMessages
            ),
          });
          setIsLoading(false);
        }
      });
    }
  };

  const showInputError = (input) => {
    if (isDataEmpty(input) && isFormDirty) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isSuperAdmin) {
      navigate("/unauthorized");
    }
  }, [isSuperAdmin, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "50vh",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "25rem",
          padding: "1.2rem 0.8rem",
          backgroundColor: "whitesmoke",
          borderRadius: "0.5rem",
          boxShadow: "1px 1px 5px",
          position: "relative",
          margin: 10,
        }}
        component="form"
        onSubmit={onSubmit}
      >
        {isLoading && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          />
        )}
        <h1 className="text-center text-2xl font-bold my-3">Authentication</h1>
        {/* System Name */}
        <Box>
          <label>System Name</label>
          <Autocomplete
            disableClearable={isDataEmpty(formInputs.systemName) ? true : false}
            disablePortal
            options={systemNameOptions}
            value={formInputs.systemName}
            sx={{
              width: "100%",
              marginTop: "0.2rem",
              backgroundColor: "white",
            }}
            onChange={(event, newValue) => {
              setFormInputs({
                ...formInputs,
                systemName: isDataEmpty(newValue) ? null : newValue,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select system..." />
            )}
          />
        </Box>
        {/* User Name */}
        <Box sx={{ marginTop: "0.6rem" }}>
          <label className="required">User Name</label>
          <TextField
            name="userName"
            value={formInputs.userName}
            error={showInputError(formInputs.userName)}
            label="User name..."
            variant="outlined"
            placeholder="Please input user name..."
            type="text"
            sx={{
              width: "100%",
              marginTop: "0.2rem",
              backgroundColor: "white",
            }}
            onChange={handleFormInputsChange}
          />
          {showInputErrorMessage(formInputs.userName, isFormDirty, "User Name")}
        </Box>
        {/* Password */}
        <Box sx={{ marginTop: "0.6rem" }}>
          <label className="required">Password</label>
          <TextField
            name="password"
            value={formInputs.password}
            error={showInputError(formInputs.password)}
            label="Password..."
            variant="outlined"
            placeholder="Please input password..."
            type={showPassword ? "text" : "password"}
            sx={{
              width: "100%",
              marginTop: "0.2rem",
              backgroundColor: "white",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisiblity}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleFormInputsChange}
          />
          {showInputErrorMessage(formInputs.password, isFormDirty, "Password")}
        </Box>
        <button
          disabled={isLoading}
          type="submit"
          className="rounded-md w-full flex items-center justify-center py-2 px-4 mt-3 bg-[#1976D2] text-white disabled:bg-blue-400"
        >
          {isLoading ? "Submitting..." : "Submit"}{" "}
        </button>
      </Box>
    </Box>
  );
};

export default AuthenticateExternalSystemCredentials;
