import { Typography } from "@mui/material";
import { Button, Form, Input, Modal } from "antd";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import notification from "components/mnl/global/openNotification";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withoutSecondAuthorityDeleteLabtest } from "store";

const DeleteLabtestForm = ({
  labtestId,
  showDeleteForm,
  handleHideDeleteForm,
  handleCloseModal,
  refetchLabtestData,
  updateData,
  setSearchText,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  /**
   * Handles the deletion of a lab test.
   *
   * @param {Object} values - An object containing user input values.
   * @param {string} values.username - The username provided by the user.
   * @param {string} values.password - The password provided by the user.
   */
  const handleDeleteLabtest = (values) => {
    // Check if a delete operation is already pending
    if (
      withoutSecondAuthorityDeleteLabtest.pending().type ===
      "labTest/delete-labTest/pending"
    ) {
      setIsLoading(true); // Set loading state while operation is pending
    }

    // Dispatch the delete lab test action
    dispatch(
      withoutSecondAuthorityDeleteLabtest({
        id: labtestId, // The ID of the lab test to delete
        username: values.username,
        password: values.password,
      })
    ).then((response) => {
      const isFetchSuccess = response?.payload?.success; // Check if the deletion was successful
      if (isFetchSuccess) {
        handleHideDeleteForm(); // Hide the delete form
        const modalCloseTimeout = setTimeout(() => {
          handleCloseModal(); // Close the modal after a delay
          refetchLabtestData(); // Fetch updated data after modal is closed
          setSearchText("");
        }, 500);

        setIsLoading(false); // Clear loading state
        form.setFieldsValue({
          username: "",
          password: "",
        });
        // Show success notification
        notification.success({
          message: "Deleted Labtest",
          description: `${response?.payload?.successMessage}`,
        });
        return () => clearTimeout(modalCloseTimeout);
      }
      if (!isFetchSuccess) {
        // Show error notification if deletion was not successful
        notification.error({
          message: "Failed to delete labtest",
          description: errorMessagesDescription(
            response.payload?.response?.data?.errorMessages
          ),
        });
      }

      setIsLoading(false); // Clear loading state
    });
  };
  return (
    <Modal
      centered
      open={showDeleteForm}
      title="Delete Labtest Second Authority"
      onCancel={handleHideDeleteForm}
      footer={[
        <Button
          size="small"
          disabled={isLoading}
          form="deleteLabtest"
          key="submit"
          htmlType="submit"
          type="primary"
          className="submitBtn"
          loading={isLoading}
        >
          {isLoading ? "Confirming..." : "Confirm"}
        </Button>,
        <Button
          size="small"
          onClick={handleHideDeleteForm}
          type="primary"
          ghost
          danger
        >
          Cancel
        </Button>,
      ]}
      width={400}
    >
      <Form
        style={{
          paddingTop: "1rem",
        }}
        onFinish={handleDeleteLabtest}
        name="deleteLabtest"
        autoComplete="off"
        form={form}
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: (
                <i style={{ fontSize: "0.8rem" }}>Username is required</i>
              ),
            },
          ]}
        >
          <Input
            autoComplete="new-user"
            placeholder="Plase enter your username..."
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: (
                <i style={{ fontSize: "0.8rem" }}>Password is required</i>
              ),
            },
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder="Plase enter your password..."
          />
        </Form.Item>
      </Form>
      <Typography
        sx={{ fontStyle: "italic", fontSize: "0.73rem", textAlign: "center" }}
        variant="p"
      >
        To be able to delete this record, a second authority is required.
      </Typography>
    </Modal>
  );
};

export default DeleteLabtestForm;
