import { getSupplyRequestStatusDisplayName } from "./filterOptions/getSupplyRequestStatusDisplayName";

export const renderSupplyRequestStatusTag = (orderStatus) => {
  const { tagColor, color, borderType, displayName } =
    getSupplyRequestStatusDisplayName(orderStatus);
  const isBorderDashed = borderType === "dashed";

  // Encode the SVG with dynamic stroke color
  const svgBackgroundImage = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${encodeURIComponent(
    color
  )}' stroke-width='3' stroke-dasharray='8%2c14' stroke-dashoffset='1' stroke-linecap='square'/%3e%3c/svg%3e")`;

  return (
    <div
      className="w-max order-status-tag"
      style={{
        color: color,
        backgroundColor: tagColor,
        borderRadius: "4px",
        padding: "6px",
        backgroundImage: isBorderDashed ? svgBackgroundImage : "none",
        border: !isBorderDashed && `1px solid ${color}`,
        display: "inline-flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <span
        className={`font-[500] leading-4 ${
          orderStatus === "Cancelled" ? "line-through" : ""
        }`}
      >
        {displayName}
      </span>
    </div>
  );
};
