import useCheckUserSession from "hooks/useCheckUserSession";
import "./App.css";
import { useEffect } from "react";
import { getLatestFeatureUpdates } from "utils/getLatestFeatureUpdates";
import { isUserLoggedIn } from "utils/isUserLoggedIn";
import ProtectedRoute from "components/mnl/global/protectedRoute";
import { Route, Routes } from "react-router-dom";
import AuthorizedRoute from "components/mnl/global/authorizedRoute";
import { mnlRoutes } from "routes/mnlRoutes";
import { Toolbar } from "@mui/material";
import Footer from "components/mnl/global/footer";

function MnlApp() {
  // eslint-disable-next-line
  const appTitle = "eRequest Client";
  document.title = appTitle;
  const { autoLogoutUser } = useCheckUserSession();

  useEffect(() => {
    getLatestFeatureUpdates();
  }, []);

  //Automatically logout user after 24hrs if they didn't end their previous session
  useEffect(() => {
    if (isUserLoggedIn()) {
      autoLogoutUser();
    }
  }, [autoLogoutUser]);

  //Conditionally render element wether they are protected or not
  const renderRouteElement = (isRouteProtected, element) => {
    if (isRouteProtected) {
      return <ProtectedRoute>{element}</ProtectedRoute>;
    }
    return element;
  };

  return (
    <>
      <Routes>
        {mnlRoutes.map((route) => {
          const routeElement = route.permission ? (
            <AuthorizedRoute permission={route.permission}>
              {route.element}
            </AuthorizedRoute>
          ) : (
            route.element
          );
          return (
            <Route
              key={route.path}
              path={route.path}
              element={renderRouteElement(route.isProtected, routeElement)}
            />
          );
        })}
      </Routes>
      <Toolbar />
      <Footer />
    </>
  );
}

export default MnlApp;
