import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import useClearReduxStore from "hooks/useClearReduxStore";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { parsedClientDetails } from "utils/parsedClientDetails";

const RegularMenuItem = ({ pageName }) => {
  const { open } = useSelector((state) => state.settings);
  const navigate = useNavigate();
  const { isClientAbcLab, isSuperAdmin } = parsedClientDetails();
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const { handleResetReduxStore } = useClearReduxStore();

  const navigateToDashboardPage = () => {
    navigate("/");
    if (multiStepFormInputs.patientId !== 0) {
      handleResetReduxStore();
    }
  };

  const navigateToRequestPage = () => {
    navigate("/test-request");
  };

  const navigateToExternalSystemCredentials = () => {
    navigate("/external-system-credential");
  };

  return (
    <div>
      {isClientAbcLab && (
        <Tooltip title={!open ? "Dashboard" : ""}>
          <ListItemButton
            selected={pageName === "Dashboard"}
            onClick={navigateToDashboardPage}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Tooltip>
      )}
      <Tooltip title={!open ? "Test Request" : ""}>
        <ListItemButton
          selected={pageName === "Test Request"}
          onClick={navigateToRequestPage}
        >
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Test Request" />
        </ListItemButton>
      </Tooltip>
      {isSuperAdmin && (
        <Tooltip
          arrow
          placement="right"
          title={!open ? "External System Credential" : ""}
        >
          <ListItemButton
            selected={pageName === "External System Credential"}
            onClick={navigateToExternalSystemCredentials}
          >
            <ListItemIcon>
              <LockPersonIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ whiteSpace: "normal" }}
              primary="External System Credential"
            />
          </ListItemButton>
        </Tooltip>
      )}
    </div>
  );
};

export default RegularMenuItem;
