export const typeOptions = [
  //Input type of labtest requirement
  {
    id: 0,
    type: "text",
  },
  {
    id: 1,
    type: "number",
  },
  {
    id: 2,
    type: "date",
  },
  {
    id: 3,
    type: "checkbox",
  },
  {
    id: 4,
    type: "radio",
  },
];
