import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useEffect } from "react";

const SnackbarNotification = ({
  onOpen,
  severity,
  setSnackbarOptions,
  onClose,
  message,
}) => {
  const snackbarPosition = {
    vertical: "top",
    horizontal: "center",
  };

  useEffect(() => {
    const snackbarDurationTimeout = setTimeout(() => {
      setSnackbarOptions({
        snackbarMessage: "",
        openSnackbar: false,
        snackbarType: "",
      });
    }, 7000);

    return () => clearTimeout(snackbarDurationTimeout);
    // eslint-disable-next-line
  }, []);

  return (
    <Snackbar
      sx={{ zIndex: "9999999999", marginTop: "1rem" }}
      anchorOrigin={snackbarPosition}
      key={snackbarPosition.vertical + snackbarPosition.horizontal}
      autoHideDuration={7000}
      open={onOpen}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        variant="filled"
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarNotification;
