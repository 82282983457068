import { Select } from "antd";
import React from "react";

const SortDirectionFilter = ({ handleSelectSortDirection, isDescending }) => {
  const sortDirectionOption = [
    {
      label: "Ascending",
      value: false,
    },
    {
      label: "Descending",
      value: true,
    },
  ];
  return (
    <Select
      className="w-full"
      virtual={false}
      placeholder="Select sort direction"
      onChange={handleSelectSortDirection}
      options={sortDirectionOption}
      value={isDescending}
    />
  );
};

export default SortDirectionFilter;
