import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/mnl/global/menuBar";
import { theme } from "components/mnl/global/theme";
import GetAllProvinces from "components/mnl/admin/provinces/getAllProvinces";
import V2sessionTimeout from "components/mnl/global/v2SessionTimeout";
import TableLayout from "views/mnl/layout/tableLayout";

const ProvincePage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Provinces"} />
        <TableLayout>
          <GetAllProvinces />
        </TableLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default ProvincePage;
