import React from "react";
import { timePeriodOptions } from "./data";
import { Badge, Card, Modal, Space, Tag, Typography } from "antd";
import { Box, Divider, Chip } from "@mui/material";
import useConvertHours from "hooks/useConvertHours";
import useCheckPermission from "hooks/useCheckPermission";
import decodedUserDetails from "utils/decodedUserDetails";

const LabtestDetails = ({
  openInfo,
  selectedInfoDetails,
  handleCancelInfo,
  handleOkInfo,
  getStringWeekName,
}) => {
  const { Text, Paragraph } = Typography;
  const { convertHoursToDay } = useConvertHours();
  const { handleVerifyPermission } = useCheckPermission();
  const isLabtestSpecimenEmpty =
    selectedInfoDetails?.testDetails?.labTestSpecimens.length === 0;
  const isSuperAdmin = decodedUserDetails()?.isSuperAdmin === "True";
  const isRoleAdmin =
    decodedUserDetails()?.role?.includes("Admin") || isSuperAdmin;
  const isUserAllowedToViewSomeLabtestDetails = handleVerifyPermission(
    "Laboratory:LabTest:Read"
  );
  const showSomeLabtestInfo =
    isRoleAdmin && isUserAllowedToViewSomeLabtestDetails;

  return (
    <Modal
      title={
        <Paragraph>
          <blockquote>
            {selectedInfoDetails?.code + " - " + selectedInfoDetails?.name}
          </blockquote>
        </Paragraph>
      }
      open={openInfo && selectedInfoDetails !== null}
      onOk={handleOkInfo}
      onCancel={handleCancelInfo}
      footer={null}
      width={600}
    >
      <Box sx={{ height: "30rem", overflowY: "scroll" }}>
        <Space direction="vertical">
          <Space align="center">
            Abbreviation
            <Text code>{selectedInfoDetails?.abbreviation}</Text>
          </Space>
          <Space align="center">
            Methodology
            <Text code>{selectedInfoDetails?.testDetails.methodology}</Text>
          </Space>
          <Space align="center">
            Sample Preparation
            <Paragraph>
              <pre> {selectedInfoDetails?.testDetails.samplePreparation}</pre>
            </Paragraph>
          </Space>
          <Space align="center">
            Send Out
            {selectedInfoDetails?.testDetails.sendOut ? (
              <Tag color="success">Yes</Tag>
            ) : (
              <Tag>No</Tag>
            )}
          </Space>

          <Space align="center">
            Allow Stat
            {selectedInfoDetails?.testDetails.isStatAllowed ? (
              <Tag color="success">Yes</Tag>
            ) : (
              <Tag>No</Tag>
            )}
          </Space>
          <Space align="center">
            Specimen
            <Text code>
              {isLabtestSpecimenEmpty
                ? "N/A"
                : selectedInfoDetails?.testDetails?.labTestSpecimens
                    ?.map((specimen) => specimen.name)
                    .join(" / ")}
            </Text>
          </Space>
          <Space align="center">
            Specimen Container
            <Text code>
              {isLabtestSpecimenEmpty
                ? "N/A"
                : selectedInfoDetails?.testDetails?.labTestSpecimens
                    .map((specimen) => specimen?.container)
                    .join(" / ")}
            </Text>
          </Space>
          {showSomeLabtestInfo && (
            <Space align="center">
              Unit of Measure
              <Text code>
                {isLabtestSpecimenEmpty
                  ? "N/A"
                  : selectedInfoDetails?.testDetails?.labTestSpecimens
                      .map((specimen) => specimen?.unitOfMeasure)
                      .join(" / ")}
              </Text>
            </Space>
          )}
          <Space align="center">
            Volume Requirement
            <Text code>
              {isLabtestSpecimenEmpty
                ? "N/A"
                : selectedInfoDetails?.testDetails?.labTestSpecimens
                    .map((specimen) =>
                      specimen?.testVolumeOrSizeRequirements.length > 0
                        ? specimen?.testVolumeOrSizeRequirements?.find((vol) =>
                            Math.min(vol?.minVolume)
                          )?.minVolume + ` ${specimen.unitOfMeasure}`
                        : "N/A"
                    )
                    .join(" / ")}
            </Text>
          </Space>
          <Space align="center">
            TAT In Hours
            <Text code>{selectedInfoDetails?.testDetails.tatInHours}</Text>
          </Space>
          <Space align="center">
            Specimen Validity
            <Text code>
              {selectedInfoDetails?.testDetails.maxSpecimenAgeInHours
                ? convertHoursToDay(
                    selectedInfoDetails?.testDetails.maxSpecimenAgeInHours
                  )
                : "N/A"}
            </Text>
          </Space>
          <Space align="center">
            Cut Off of Acceptance
            <Text code>
              {selectedInfoDetails?.testDetails?.cutoff === null
                ? "N/A"
                : getStringWeekName(selectedInfoDetails?.testDetails?.cutoff)
                    ?.long}
            </Text>
          </Space>
          {selectedInfoDetails?.testDetails.schedules.length > 0 && (
            <Box sx={{ marginTop: "1rem" }}>
              <Divider textAlign="left">
                <Chip label="Schedule" />
              </Divider>
              <Box sx={{ marginTop: "0.8rem" }}>
                <Space direction="vertical" style={{ marginBottom: "0.7rem" }}>
                  <Badge status="success" text="Scheduled to run" />
                  <Badge status="default" text="Not scheduled to run" />
                </Space>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    my: 2,
                  }}
                >
                  {[0, 1, 2, 3, 4, 5, 6].map((item) => {
                    const matchingSchedules =
                      selectedInfoDetails.testDetails?.schedules.filter(
                        (i) => i.day === item
                      );
                    const timePeriods = matchingSchedules.map(
                      (schedule) => schedule.timePeriod
                    );
                    return matchingSchedules.length > 0 ? (
                      <Badge.Ribbon
                        className="w-full"
                        text={getStringWeekName(item).long}
                        color="green"
                      >
                        <Card className="pt-5 bg-green-50 w-24" size="small">
                          <strong>
                            {
                              timePeriodOptions.find(
                                (period) => period.value === +timePeriods
                              )?.label
                            }
                          </strong>
                        </Card>
                      </Badge.Ribbon>
                    ) : (
                      <Badge.Ribbon
                        className="w-full"
                        text={getStringWeekName(item).long}
                        color="gray"
                      >
                        <Card
                          className="pt-5 font-bold bg-gray-100 w-24"
                          size="small"
                        ></Card>
                      </Badge.Ribbon>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          )}
        </Space>
      </Box>
    </Modal>
  );
};

export default LabtestDetails;
