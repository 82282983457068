import decodedUserDetails from "utils/decodedUserDetails";
import { useNavigate, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FeedIcon from "@mui/icons-material/Feed";
import * as React from "react";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

const AdminMenuItemRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { open } = useSelector((state) => state.settings);

  const adminClaims =
    decodedUserDetails()?.role === "Admin" ||
    decodedUserDetails()?.isSuperAdmin === "True"
      ? ["User", "Role", "Logs"]
      : [];

  return adminClaims.map((element, index) => {
    let routeName = "Route";
    let routeLink = "route";
    let routeIcon = "";
    switch (element) {
      case "User":
        routeName = "User";
        routeLink = "/user";
        routeIcon = <PeopleIcon />;
        break;
      case "Role":
        routeName = "Role";
        routeLink = "/role";
        routeIcon = <ManageAccountsIcon />;
        break;
      case "Logs":
        routeName = "Logs";
        routeLink = "/logs";
        routeIcon = <FeedIcon />;
        break;
      default:
        break;
    }
    return (
      <Tooltip key={index} title={!open ? routeName : ""}>
        <ListItemButton
          onClick={() => navigate(routeLink)}
          selected={routeLink === location.pathname}
        >
          <ListItemIcon>{routeIcon}</ListItemIcon>
          <ListItemText primary={routeName} />
        </ListItemButton>
      </Tooltip>
    );
  });
};
export default AdminMenuItemRoute;
