import { Button, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import gradientLine from "../../../../assests/supply-request-gradient.svg";
import auditTrailIcon from "../../../../assests/audit-trail-icon.svg";
import { useDispatch } from "react-redux";
import { getSupplyRequestAuditTrail } from "store";
import Timeline from "./timeline";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { parsedClientDetails } from "utils/parsedClientDetails";

const AuditTrailModal = ({ openModal, onCloseModal, supplyRequestId }) => {
  const [auditTrailData, setAuditTrailData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [showDescription, setShowDescription] = useState(false);
  const { isClientAbcLab } = parsedClientDetails();

  const handleFetchAuditTrailData = () => {
    if (
      getSupplyRequestAuditTrail.pending().type ===
      "supply-orders/get-supply-request-audit-trail/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(getSupplyRequestAuditTrail({ requestId: supplyRequestId })).then(
      (res) => {
        const isFetchSuccessful = res?.payload?.success;

        if (isFetchSuccessful) {
          setAuditTrailData(res?.payload?.data);
          setIsLoading(false);
        }

        if (!isFetchSuccessful) {
          setIsLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (supplyRequestId !== null) {
      handleFetchAuditTrailData();
    }
    // eslint-disable-next-line
  }, [supplyRequestId]);

  const filteredAuditTrailData = auditTrailData?.filter((item) => {
    if (!isClientAbcLab) {
      return (
        item.action !== "ReviewedSupplyRequest" &&
        item.action !== "ApprovedRequestedItem" &&
        item.action !== "ReleasedRequestedItem"
      );
    }
    return item;
  });

  return (
    <Modal
      open={openModal}
      onCancel={onCloseModal}
      footer={
        <div className="flex items-center justify-center mt-4">
          <Button
            className="flex items-center justify-center gap-1 bg-[#1976D2] px-[10px] py-[7px] text-[18px] h-11 w-54"
            type="primary"
            icon={showDescription ? <EyeInvisibleFilled /> : <EyeFilled />}
            onClick={() => {
              setShowDescription(!showDescription);
            }}
          >
            {`${showDescription ? "Hide" : "Show"} Description`}
          </Button>
        </div>
      }
      width={860}
      closable={false}
    >
      <div
        className="relative overflow-y-auto"
        style={{ maxHeight: "calc(100vh - 300px)" }}
      >
        <div className="bg-[#dfe5ed] w-full pt-7 rounded-[4px] px-[10px] py-[4px]">
          <img className="mt-1" alt="gradient" src={gradientLine} />
          <span className=" absolute flex items-center gap-2 -tracking-tighter top-4 w-max left-0 right-0 mx-auto text-[20px] font-[700] bg-[#1976D2] text-white px-[10px] py-[4px] rounded-[4px]">
            <img alt="Audit Trail" src={auditTrailIcon} /> Audit Trail
          </span>
          <div className="mt-10">
            {isLoading ? (
              <div className="space-y-6 mb-3">
                {Array.from({ length: 5 })
                  .fill("")
                  .map((_, index) => (
                    <div key={index} className="flex items-center gap-5">
                      <div className="flex items-center">
                        <Skeleton.Button active size="large" />
                        <Skeleton.Button
                          active
                          block
                          shape="circle"
                          size="large"
                        />
                      </div>
                      <Skeleton.Button active block />
                    </div>
                  ))}
              </div>
            ) : (
              filteredAuditTrailData?.map((item) => (
                <Timeline
                  key={item.id}
                  {...item}
                  showDescription={showDescription}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuditTrailModal;
