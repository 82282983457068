import { Grid, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  Table,
  Input,
  ConfigProvider,
  Select,
  Button as BtnAntD,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import useCheckPermission from "hooks/useCheckPermission";
import useDebounce from "hooks/useDebounce";
import { getAllSampleCollector } from "store";
import { clientLookUp } from "store";
import { EmptyData } from "components/mnl/global/emptyData";
import CreateUpdateSampleCollector from "./createUpdateSampleCollector";
import { useCurrentHeight } from "utils/getScreenSize";
import decodedUserDetails from "utils/decodedUserDetails";
import { useCallback, useEffect, useState } from "react";

const GetAllSampleCollectors = () => {
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Laboratory:SampleCollector:Modify"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = useState("firstName");
  const [sortDirection, setSortDirection] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sampleCollectorData, setSampleCollectorData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  // eslint-disable-next-line
  const clientId = decodedUserDetails().clientId;
  const [clientData, setClientData] = useState([]);
  const [isForUpdate, setIsForUpdate] = useState(false);
  const [idForUpdate, setIdForUpdate] = useState(0);

  const LoadData = useCallback(() => {
    //HANDLE LOADING
    if (
      getAllSampleCollector.pending().type ===
      "sample-collectors/get-all-sample-collectors/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(clientLookUp()).then((res) => {
      setClientData(res.payload.data);
    });
    dispatch(
      getAllSampleCollector({
        clientId: clientId,
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((res) => {
      if (
        res.type === "sample-collectors/get-all-sample-collectors/fulfilled"
      ) {
        setIsLoading(false);
        setSampleCollectorData(res.payload.data.items);
        setTotal(res.payload.data.totalPages * pageSize);
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
    clientId,
  ]);

  useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const onChange = (page) => {
    setCurrent(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Sample Collector Detected"
      action={showCreateUpdate}
      btnTitle="Add New Province"
      renderButton={accessRightsToModify}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(0);
  };
  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };
  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
    setIdForUpdate(0);
    setIsForUpdate(false);
  };

  const handleUpdate = (id) => {
    setIsForUpdate(true);
    setIdForUpdate(id);
  };

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: 60,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: 60,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 75,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 100,
    },
    {
      title: "Collector Type",
      dataIndex: "collectorType",
      key: "collectorType",
      width: 120,
    },
    {
      title: "Client",
      dataIndex: "clientId",
      key: "clientId",
      width: 80,
      render: (rec, row) => (
        <>{clientData.filter((element) => element.id === rec)[0]?.clientName}</>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 45,
      render: (rec, row) => (
        <>{rec ? <Tag color="success">Active</Tag> : <Tag>Inactive</Tag>}</>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 50,
      render: (rec, row) => (
        <>
          {accessRightsToModify ? (
            <BtnAntD
              size="small"
              type="text"
              style={{
                marginLeft: 2,
              }}
              icon={<EditIcon fontSize="inherit" />}
              className="bg-orange-600 text-white hover:enabled:bg-orange-400 hover:enabled:text-white"
              onClick={() => {
                handleUpdate(row);
                showCreateUpdate();
              }}
            >
              Update
            </BtnAntD>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder="Search Sample Collector"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "firstName",
                label: "First Name",
              },
              {
                value: "lastName",
                label: "Last Name",
              },
              {
                value: "collectorType",
                label: "Collector Type",
              },
              {
                value: "clientId",
                label: "Client",
              },
              {
                value: "isActive",
                label: "Status",
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid>

        {sampleCollectorData.length !== 0 && accessRightsToModify ? (
          <Grid item xs={12} md={3} lg={3}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New Sample Collector
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={
            accessRightsToModify
              ? columns
              : columns.filter((col) => col.dataIndex !== "")
          }
          dataSource={sampleCollectorData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <CreateUpdateSampleCollector
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
        clientData={clientData}
      />
    </>
  );
};
export default GetAllSampleCollectors;
