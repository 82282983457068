import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTestsLookUp } from "store";
import { typeOptions } from "./data";
import useAdditionalInfoFunctions from "./useAdditionalInfoFunctions";
import decodedUserDetails from "utils/decodedUserDetails";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import notification from "components/mnl/global/openNotification";

const EditAdditionalPatientInfoModal = ({
  handleCloseModal,
  selectedProfile,
  inputValues,
  setInputValues,
}) => {
  const savedTestInputsInfo = useSelector((state) => state.savedTestInputsInfo);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const inputs = useSelector((state) => state.inputs);
  const [labTestNames, setLabTestNames] = useState([]);
  const dispatch = useDispatch();
  const { handleInputChange, handleInputCheckboxChange } =
    useAdditionalInfoFunctions();

  useEffect(() => {
    //Fetching of labtest lookup
    dispatch(
      getTestsLookUp({
        clientId: decodedUserDetails()?.clientId,
        gender: inputs.gender,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.data;
      if (isFetchSuccessful) {
        setLabTestNames(res.payload.data);
      }
    });
  }, [dispatch, inputs.gender]);

  const handleCloseInputModal = () => {
    //For closing specimen and additional patient information modal
    handleCloseModal();
  };

  const handleSave = (e) => {
    //Function for updating labtest requirements
    e.preventDefault();
    const updatedTestOrderDetails = multiStepFormInputs?.testOrderDetails.map(
      (detail) => {
        if (detail.testId === selectedProfile.id) {
          const inputIds = inputValues.map((data) => data.id);
          const savedDataKey =
            detail.patientTestRequirementDatas[0]?.patientRequirementDataDetails?.map(
              (req) => req.dataKey
            );
          const updatedPatientRequirementDataDetails =
            detail.patientTestRequirementDatas[0]?.patientRequirementDataDetails
              .filter((data) => inputIds.includes(data.dataKey))
              .map((patientInfoData) => {
                const updatedValue = inputValues.find(
                  (inputData) => inputData.id === patientInfoData.dataKey
                )?.value;

                return {
                  ...patientInfoData,
                  dataValue: updatedValue,
                };
              });

          const newlyAddedPatientRequirementDataDetails = inputValues
            .filter(
              (data) => !savedDataKey.includes(data.id) && data.value !== ""
            )
            .map(({ id, value, description }) => ({
              patientTestRequirementDataId: id,
              dataKey: id,
              dataValue: value,
              description: description,
            }));

          return {
            ...detail,
            patientTestRequirementDatas: [
              {
                ...detail.patientTestRequirementDatas[0],
                patientRequirementDataDetails:
                  updatedPatientRequirementDataDetails.concat(
                    newlyAddedPatientRequirementDataDetails
                  ),
              },
            ],
          };
        }
        return detail;
      }
    );
    dispatch(
      handleChangeMultiFields({
        testOrderDetails: updatedTestOrderDetails,
      })
    );
    notification.success({
      message: "Updated Test Profile",
      description: `Successfully updated ${selectedProfile.name}`,
    });
    handleCloseInputModal();
  };

  const showTestName = (labtestInfoId, previousLabtestId) => {
    if (labtestInfoId !== previousLabtestId) {
      return true;
    }

    return false;
  };
  return (
    <Box
      sx={{
        marginBottom: "2rem",
        height:
          savedTestInputsInfo?.fetchedInputFields?.testDetails
            ?.labTestRequirements?.length > 3 ||
          savedTestInputsInfo?.fetchedInputFields?.length > 3
            ? "30rem"
            : "20rem",
        overflowY:
          savedTestInputsInfo?.fetchedInputFields?.testDetails
            ?.labTestRequirements?.length > 3 ||
          savedTestInputsInfo?.fetchedInputFields?.length > 3
            ? "scroll"
            : "auto",
      }}
    >
      <Typography
        sx={{
          fontSize: "1rem",
          textAlign: "center",
          paddingTop: "0.5rem",
          color: "grayishBlue.light",
          fontWeight: "bold",
        }}
      >
        Additional Patient Information
      </Typography>
      <Box
        sx={{
          backgroundColor: "#E7EBF4",
          padding: "1rem 0.7rem",
          margin: "0.8rem 1rem",
        }}
      >
        {selectedProfile.type === "Test"
          ? savedTestInputsInfo?.fetchedInputFields?.testDetails?.labTestRequirements?.map(
              (input, index) => {
                return (
                  <Box key={index}>
                    {showTestName(
                      input.labTestInfoId,
                      savedTestInputsInfo?.fetchedInputFields?.testDetails
                        ?.labTestRequirements[
                        savedTestInputsInfo?.fetchedInputFields?.testDetails?.labTestRequirements?.indexOf(
                          input
                        ) - 1
                      ]?.labTestInfoId
                    ) && (
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                        }}
                      >
                        For {selectedProfile.name}
                      </Typography>
                    )}
                    {input.requirementType === 3 ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              onChange={(e) =>
                                handleInputCheckboxChange(
                                  e,
                                  index,
                                  setInputValues
                                )
                              }
                              checked={
                                inputValues.find(
                                  (_, inputIdx) => inputIdx === index
                                )?.value === "true"
                              }
                            />
                          }
                          id={index}
                          label={
                            <Typography
                              className={input.isRequired ? "required" : ""}
                              sx={{
                                color: "grayishBlue.light",
                                fontSize: "1rem",
                              }}
                              variant="p"
                            >
                              {input.requirementDetails}
                            </Typography>
                          }
                        />
                      </FormGroup>
                    ) : input.requirementType === 4 ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <FormControl>
                          <Typography
                            className={input.isRequired ? "required" : ""}
                            sx={{
                              color: "grayishBlue.light",
                              fontSize: "1rem",
                            }}
                            variant="p"
                          >
                            {input.requirementDetails}
                          </Typography>
                          <RadioGroup
                            row
                            value={
                              inputValues?.find(
                                (_, inputIdx) => inputIdx === index
                              )?.value || ""
                            }
                            onChange={(e) =>
                              handleInputChange(e, index, setInputValues)
                            }
                          >
                            <FormControlLabel
                              value="Yes"
                              control={<Radio size="small" />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio size="small" />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    ) : (
                      <Box sx={{ margin: "0.5rem 0" }}>
                        <Typography
                          className={input.isRequired ? "required" : ""}
                          sx={{
                            color: "grayishBlue.light",
                            fontSize: "1rem",
                          }}
                          variant="p"
                        >
                          {input.requirementDetails}
                        </Typography>
                        <TextField
                          id={index}
                          size="small"
                          value={
                            inputValues?.find(
                              (_, inputIdx) => inputIdx === index
                            )?.value || ""
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": {
                                border: "1px solid",
                                borderColor: "darkBlue.secondary",
                                borderRadius: "0.7rem",
                              },
                            },
                            marginTop: "0.5rem",
                            backgroundColor: "white",
                            width: "100%",
                            borderRadius: "0.7rem",
                          }}
                          required={input.isRequired}
                          type={
                            typeOptions.find(
                              (type) => type.id === input.requirementType
                            )?.type || "text"
                          }
                          onChange={(e) =>
                            handleInputChange(e, index, setInputValues)
                          }
                        />
                      </Box>
                    )}
                  </Box>
                );
              }
            )
          : savedTestInputsInfo.fetchedInputFields.length > 0 &&
            savedTestInputsInfo.fetchedInputFields?.map((input, index) => {
              return (
                <Box key={index}>
                  {showTestName(
                    input.labTestInfoId,
                    savedTestInputsInfo.fetchedInputFields[
                      savedTestInputsInfo.fetchedInputFields.indexOf(input) - 1
                    ]?.labTestInfoId
                  ) && (
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                      }}
                    >
                      For{" "}
                      {
                        labTestNames?.find(
                          (data) => data.id === input.labTestInfoId
                        )?.name
                      }
                    </Typography>
                  )}
                  {input.requirementType === 3 ? (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            onChange={(e) =>
                              handleInputCheckboxChange(
                                e,
                                index,
                                setInputValues
                              )
                            }
                            checked={
                              inputValues.find(
                                (_, inputIdx) => inputIdx === index
                              )?.value === "true"
                            }
                          />
                        }
                        id={index}
                        label={
                          <Typography
                            className={input.isRequired ? "required" : ""}
                            sx={{
                              color: "grayishBlue.light",
                              fontSize: "1rem",
                            }}
                            variant="p"
                          >
                            {input.requirementDetails}
                          </Typography>
                        }
                      />
                    </FormGroup>
                  ) : input.requirementType === 4 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <FormControl>
                        <Typography
                          className={input.isRequired ? "required" : ""}
                          sx={{
                            color: "grayishBlue.light",
                            fontSize: "1rem",
                          }}
                          variant="p"
                        >
                          {input.requirementDetails}
                        </Typography>
                        <RadioGroup
                          row
                          value={
                            inputValues?.find(
                              (_, inputIdx) => inputIdx === index
                            )?.value || ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, index, setInputValues)
                          }
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio size="small" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio size="small" />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box sx={{ margin: "0.5rem 0" }}>
                      <Typography
                        className={input.isRequired ? "required" : ""}
                        sx={{
                          color: "grayishBlue.light",
                          fontSize: "1rem",
                        }}
                        variant="p"
                      >
                        {input.requirementDetails}
                      </Typography>
                      <TextField
                        id={index}
                        size="small"
                        value={
                          inputValues?.find((_, inputIdx) => inputIdx === index)
                            ?.value || ""
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                              border: "1px solid",
                              borderColor: "darkBlue.secondary",
                              borderRadius: "0.7rem",
                            },
                          },
                          marginTop: "0.5rem",
                          backgroundColor: "white",
                          width: "100%",
                          borderRadius: "0.7rem",
                        }}
                        required={input.isRequired}
                        type={
                          typeOptions.find(
                            (type) => type.id === input.requirementType
                          )?.type || "text"
                        }
                        onChange={(e) =>
                          handleInputChange(e, index, setInputValues)
                        }
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.9rem",
          marginTop: "1rem",
          position: "absolute",
          bottom: 10,
          left: 50,
        }}
      >
        <button onClick={handleSave} className={styles["save_btn"]}>
          Save
        </button>
        <button
          className={styles["cancel_btn"]}
          onClick={handleCloseInputModal}
        >
          Cancel
        </button>
      </Box>
    </Box>
  );
};

export default EditAdditionalPatientInfoModal;
