import newRequest from "../assests/new-request-supply-req-icon.svg";
import reviewed from "../assests/reviewed-supply-req-icon.svg";
import evaluated from "../assests/evaluated-supply-req-icon.svg";
import partiallyEvaluated from "../assests/partially-evaluated-supply-req-icon.svg";
import preparing from "../assests/preparing-supply-req-icon.svg";
import forDelivery from "../assests/for-delivery-supply-req-icon.svg";
import inTransit from "../assests/in-transit-supply-req-icon.svg";
import partiallyCompleted from "../assests/partially-completed-supply-req-icon.svg";
import completed from "../assests/completed-supply-req-icon.svg";
import cancelled from "../assests/cancelled-supply-req-icon.svg";

export const renderSupplyRequestAuditTrailIcon = (orderType) => {
  const auditTrailIconMap = {
    NewRequest: newRequest,
    Reviewed: reviewed,
    PartiallyEvaluated: partiallyEvaluated,
    Evaluated: evaluated,
    Preparing: preparing,
    ForDelivery: forDelivery,
    InTransit: inTransit,
    PartiallyCompleted: partiallyCompleted,
    Completed: completed,
    Cancelled: cancelled,
  };

  const selectedAuditTrailIcon = auditTrailIconMap[orderType];

  return (
    <img
      className="ml-auto bg-[#C1CFE0] h-16 w-16 p-[1px] absolute -right-3 rounded-full"
      alt={orderType}
      src={selectedAuditTrailIcon}
    />
  );
};
