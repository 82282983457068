import {
  Modal,
  Form,
  Input,
  Button,
  Popconfirm,
  Select,
  Col,
  Space,
  Divider as DividerAntd,
  InputNumber,
  Row,
  Switch,
} from "antd";
import { useDispatch } from "react-redux";
import { createSpecimen, deleteSpecimen, updateSpecimen } from "store";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Chip, Box } from "@mui/material";
import useCheckPermission from "hooks/useCheckPermission";
import notification from "components/mnl/global/openNotification";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import { useCallback, useEffect, useState } from "react";

const CreateUpdateSpecimen = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
    measurementType,
  } = props;

  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Laboratory:Specimen:Delete"
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");

  const LoadUpdateData = useCallback(() => {
    setSelectedType(idForUpdate.measurementType);
    form.setFieldsValue({
      name: idForUpdate.name,
      container: idForUpdate.container,
      measurementType: idForUpdate.measurementType,
      size: idForUpdate.size,
      unitOfMeasure: idForUpdate.unitOfMeasure,
      requiresBloodDraw: idForUpdate.requiresBloodDraw ? true : false,
      requiresSampleOrigin: idForUpdate.requiresSampleOrigin ? true : false,
      testVolumeOrSizeRequirements: idForUpdate.testVolumeOrSizeRequirements,
    });
  }, [form, idForUpdate]);

  useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const clearInputs = () => {
    form.resetFields();
    setSelectedType("");
  };

  const onFinish = (values) => {
    let valueToSubmit = values;
    valueToSubmit.testVolumeOrSizeRequirements =
      valueToSubmit.testVolumeOrSizeRequirements.map((item) => {
        if (selectedType === 2) {
          return {
            ...item,
            UoM: valueToSubmit.unitOfMeasure,
            minSize: valueToSubmit.size,
          };
        } else {
          return { ...item, UoM: valueToSubmit.unitOfMeasure };
        }
      });

    if (isForUpdate) {
      if (
        updateSpecimen.pending().type === "specimen/update-specimen/pending"
      ) {
        setLoading(true);
      }
      dispatch(updateSpecimen([idForUpdate.id, valueToSubmit])).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Updated Specimen",
            description: `Successfully updated ${values.name}`,
          });
          clearInputs();
        }
        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Update Specimen",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    } else {
      if (
        createSpecimen.pending().type === "specimen/create-specimen/pending"
      ) {
        setLoading(true);
      }
      dispatch(createSpecimen(valueToSubmit)).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created Specimen",
            description: `Successfully created ${values.name}`,
          });
          clearInputs();
        }
        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Create Specimen",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    }
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (deleteSpecimen.pending().type === "specimen/delete-specimen/pending") {
      setLoading(true);
    }
    dispatch(deleteSpecimen(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Specimen",
          description: `Successfully deleted ${idForUpdate.name}`,
        });
      }
      if (!val.payload?.success) {
        notification.error({
          message: "Failed to Delete Specimen",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      open={openCreateUpdate}
      title={isForUpdate ? "Update Specimen" : "Create Specimen"}
      onCancel={handleCancel}
      footer={[
        <Button
          form="specimen"
          loading={loading}
          type="primary"
          htmlType="submit"
          style={{ margin: 2 }}
          className="submitBtn"
        >
          Submit
        </Button>,
        accessRightsToDelete && isForUpdate ? (
          <Popconfirm
            title="Delete the specimen"
            description="Are you sure to delete?"
            onConfirm={handleClickDelete}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ loading: loading, className: "submitBtn" }}
          >
            <Button loading={loading} type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        ) : null,
      ]}
    >
      <Box
        sx={{
          height:
            idForUpdate?.testVolumeOrSizeRequirements?.length > 2
              ? "32rem"
              : " auto",
          overflowY:
            idForUpdate?.testVolumeOrSizeRequirements?.length > 2
              ? "scroll"
              : "auto",
        }}
      >
        <Form
          name="specimen"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          {/* Name */}
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: <i>{"Please input Name."}</i>,
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>

          {/* Container */}
          <Form.Item
            label="Container"
            name="container"
            rules={[
              {
                required: true,
                message: <i>{"Please input Container."}</i>,
              },
            ]}
          >
            <Input placeholder="Container" />
          </Form.Item>

          {/* Measurement Type */}
          <Form.Item
            label="Measurement Type"
            name="measurementType"
            rules={[
              {
                required: true,
                message: <i>{"Please input Measurement Type."}</i>,
              },
            ]}
          >
            <Select
              options={measurementType.map((item) => {
                return { label: item, value: measurementType.indexOf(item) };
              })}
              onChange={(e) => {
                setSelectedType(e);
                form.setFieldsValue({ testVolumeOrSizeRequirements: [] });
              }}
            />
          </Form.Item>

          {/* Size */}
          {selectedType === 2 && (
            <Form.Item label="Size" name="size">
              <Input placeholder="Size" />
            </Form.Item>
          )}

          {/* Unit Of Measure */}
          <Form.Item
            label="Unit Of Measure"
            name="unitOfMeasure"
            rules={[
              {
                required: true,
                message: <i>{"Please input Unit Of Measure."}</i>,
              },
            ]}
          >
            <Input placeholder="Unit Of Measure" />
          </Form.Item>

          {/* Requires Sample Origin */}
          <Form.Item
            label="Require Sample Origin"
            name="requiresSampleOrigin"
            rules={[
              {
                required: true,
              },
            ]}
            valuePropName="checked"
            initialValue={false}
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>

          {/* Requires Blood Draw */}
          <Form.Item
            label="Require Blood Draw"
            name="requiresBloodDraw"
            rules={[
              {
                required: true,
              },
            ]}
            valuePropName="checked"
            initialValue={false}
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>

          <Form.List name="testVolumeOrSizeRequirements">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, key) => (
                  <Box
                    border={2}
                    borderRadius={4}
                    sx={{ m: 2, borderColor: "primary.main" }}
                    key={key}
                  >
                    <Row>
                      <Col Col span={24}>
                        <DividerAntd orientation="left" plain>
                          <Chip label={"Specimen Details #" + (key + 1)} />
                        </DividerAntd>
                      </Col>
                    </Row>
                    <Row>
                      {(selectedType === 0 ||
                        selectedType === 1 ||
                        selectedType === 3) && (
                        <Col span={20} key={key}>
                          <Space align="baseline">
                            {/* Min Test */}
                            <Form.Item
                              {...field}
                              label="Min Test"
                              name={[field.name, "minTest"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing minimum test",
                                },
                              ]}
                              key={key}
                            >
                              <InputNumber min={1} />
                            </Form.Item>

                            {/* Max Test */}
                            <Form.Item
                              {...field}
                              label="Max Test"
                              name={[field.name, "maxTest"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing maximum test",
                                },
                              ]}
                              key={key}
                            >
                              <InputNumber min={1} />
                            </Form.Item>
                          </Space>
                          <Space align="baseline">
                            {/* Min Volume */}
                            <Form.Item
                              {...field}
                              label="Min Volume"
                              name={[field.name, "minVolume"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing minimum volume",
                                },
                              ]}
                              key={key}
                            >
                              <Input />
                            </Form.Item>
                          </Space>
                        </Col>
                      )}

                      {(selectedType === 0 ||
                        selectedType === 1 ||
                        selectedType === 3) && (
                        <Col span={4}>
                          <div className="space-align-block">
                            <Space align="center">
                              <span className="mock-block">
                                <MinusCircleOutlined
                                  onClick={() => remove(key)}
                                />
                              </span>
                            </Space>
                          </div>
                        </Col>
                      )}

                      {selectedType === 2 && (
                        <Col span={24} key={key}>
                          <Space align="baseline">
                            {/* Min Test */}
                            <Form.Item
                              {...field}
                              label="Min Test"
                              name={[field.name, "minTest"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing minimum test",
                                },
                              ]}
                              key={key}
                            >
                              <InputNumber min={1} />
                            </Form.Item>

                            {/* Max Test */}
                            <Form.Item
                              {...field}
                              label="Max Test"
                              name={[field.name, "maxTest"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing maximum test",
                                },
                              ]}
                              key={key}
                            >
                              <InputNumber min={1} />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(key)} />
                          </Space>
                        </Col>
                      )}
                    </Row>
                  </Box>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    disabled={selectedType === ""}
                  >
                    Add specimen details
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Box>
    </Modal>
  );
};

export default CreateUpdateSpecimen;
