import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/mnl/global/menuBar";
import { theme } from "components/mnl/global/theme";
import TableLayout from "views/mnl/layout/tableLayout";
import CreateLrfPickupRequest from "components/mnl/lrfPickupRequest/createLrfPickupRequest";
import V2sessionTimeout from "components/mnl/global/v2SessionTimeout";

const LrfPickupRequestPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"LRF Pickup Request"} />
        <TableLayout>
          <CreateLrfPickupRequest />
        </TableLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default LrfPickupRequestPage;
