import { Box, Typography } from "@mui/material";
import { Button, Modal, Spin, notification } from "antd";
import moment from "moment";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout, refreshUser } from "store";
import { useNavigate } from "react-router-dom";

const V2sessionTimeout = () => {
  const [events] = useState(["click", "load", "scroll"]);
  const [seconds, setSeconds] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let timeStamp = useRef();
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();
  const gracePeriodTime = 5;
  const isAuthenticated = JSON.parse(localStorage.getItem("user"));
  const lastRefreshTokenDT = JSON.parse(
    localStorage.getItem("last-refresh-token")
  );

  const warningInactive = useCallback(
    (timeString) => {
      clearTimeout(startTimerInterval.current);

      warningInactiveInterval.current = setInterval(() => {
        const maxTime = 3;
        const popTime = 2;
        const diff = moment.duration(moment().diff(moment(timeString)));
        const minPast = diff.minutes();
        const leftSecond = 60 - diff.seconds();

        if (minPast === popTime) {
          setSeconds(leftSecond);
          setShowModal(true);
        }

        if (minPast === maxTime) {
          setIsLoading(true);

          const modalShowTimeout = setTimeout(() => {
            clearInterval(warningInactiveInterval.current);
            sessionStorage.removeItem("lastTimeStamp");
            setShowModal(false);
            dispatch(refreshUser());
            dispatch(logout());
            navigate("/login");
            setIsLoading(false);
          }, 2000);

          return () => clearTimeout(modalShowTimeout);
        }
      });
    },
    [dispatch, navigate]
  );

  const timeChecker = useCallback(() => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 50 * 1000 * gracePeriodTime);
  }, [warningInactive]);

  const resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (isAuthenticated) {
      timeStamp.current = moment();
      sessionStorage.setItem("lastTimeStamp", timeStamp);
    } else {
      sessionStorage.removeItem("lastTimeStamp");
    }

    timeChecker();
  }, [timeChecker, isAuthenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  useEffect(() => {
    const dtNow = new Date();
    const dtPrev =
      lastRefreshTokenDT !== null
        ? new Date(lastRefreshTokenDT.dt)
        : new Date();

    let seconds = Math.floor((dtNow - dtPrev) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;

    if (hours > 6) {
      setIsLoading(true);
      setTimeout(() => {
        clearInterval(warningInactiveInterval.current);
        clearTimeout(startTimerInterval.current);
        sessionStorage.clear();
        localStorage.clear();
        dispatch(refreshUser());
        setShowModal(false);
        dispatch(logout());
        setIsLoading(false);
        window.location.reload();
        navigate("/login");
        notification.success({
          placement: "bottomRight",
          top: 0,
          duration: 4,
          message: "Logged out",
          description: "Your session token expires, please login in again",
          maxCount: 1,
        });
      }, 2000);
    }
  }, [lastRefreshTokenDT, navigate, dispatch]);

  const handleLogout = () => {
    setIsLoading(true);

    const logoutTimeout = setTimeout(() => {
      setShowModal(false);
      sessionStorage.removeItem("lastTimeStamp");
      dispatch(logout());
      dispatch(refreshUser());
      navigate("/login");
      setIsLoading(false);
    }, 2000);

    if (startTimerInterval.current) {
      clearTimeout(startTimerInterval.current);
    }
    if (warningInactiveInterval.current) {
      clearTimeout(warningInactiveInterval.current);
    }
    return () => clearTimeout(logoutTimeout);
  };

  const handleContinueSession = () => {
    resetTimer();
    setShowModal(false);
    if (startTimerInterval.current) {
      clearTimeout(startTimerInterval.current);
    }
    if (warningInactiveInterval.current) {
      clearTimeout(warningInactiveInterval.current);
    }
  };

  return (
    <Modal
      okButtonProps={{
        className: "popconfirm-btn",
      }}
      open={showModal}
      title={!isLoading && "Session Timeout"}
      closable={false}
      centered
      footer={false}
      p
    >
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <Typography sx={{ fontSize: "1.3rem" }} variant="p">
            Logging out
          </Typography>
          <Spin size="middle" />
        </Box>
      )}
      {!isLoading && (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "0.9rem" }} variant="p">
              Your session is about to expire.
            </Typography>
            <Typography
              sx={{ fontSize: "0.9rem", marginTop: "0.5rem" }}
              variant="p"
            >
              You will be logged out in{" "}
              <span className="font-bold bg-[#E7EBF4] p-[0.3rem] mx-1">
                {seconds}
              </span>{" "}
              seconds.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              gap: "0.8rem",
              marginTop: "1rem",
            }}
          >
            <Button onClick={handleLogout} type="default">
              Logout
            </Button>
            <Button
              onClick={handleContinueSession}
              type="primary"
              className="submitBtn"
            >
              Continue Session
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default V2sessionTimeout;
