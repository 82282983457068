import { Popconfirm as AntdPopConfirm } from "antd";

const PopConfirm = ({
  title,
  description,
  onConfirm,
  okText,
  cancelText,
  placement,
  children,
}) => {
  return (
    <AntdPopConfirm
      okButtonProps={{
        className: "popconfirm-btn",
      }}
      title={title}
      description={description}
      onConfirm={onConfirm}
      okText={okText}
      cancelText={cancelText}
      placement={placement || "rightTop"}
    >
      {children}
    </AntdPopConfirm>
  );
};

export default PopConfirm;
