import { Box } from "@mui/material";
import { forwardRef } from "react";
import HeaderContent from "./headerContent";
import PatientInformation from "./patientInformation";
import TestProfileSection from "./testProfileSection";
import BillingInformation from "./billingInformation";

const TestOrderToBePrinted = forwardRef((props, ref) => {
  const {
    encryptedTestOrder,
    orderStatus,
    orderNumber,
    isMinimalOrder,
    isLoading,
    cancelReason,
    patientDetails,
    provider,
    specimens,
    testOrderDetails,
    diagnosesList,
    specialHandlings,
    courierRemarks,
    preparedBy,
    clientDetails,
  } = props;

  const concatenatedSaltIdAndEncryptedText = `${encryptedTestOrder.saltId}:${encryptedTestOrder.encryptedText}`;

  return (
    <Box
      sx={{
        flexGrow: 1,
        breakInside: "avoid",
        whiteSpace: "normal",
      }}
      ref={ref}
      className="test-order-wrapper"
    >
      <HeaderContent
        orderStatus={orderStatus}
        orderNumber={orderNumber}
        clientDetails={clientDetails}
        isLoading={isLoading}
        cancelReason={cancelReason}
      />
      {!isMinimalOrder && (
        <>
          <PatientInformation
            patientDetails={patientDetails}
            provider={provider}
            isLoading={isLoading}
            specimens={specimens}
          />
          <TestProfileSection
            testOrderDetails={testOrderDetails}
            diagnosesList={diagnosesList}
            isLoading={isLoading}
            specimens={specimens}
            specialHandlings={specialHandlings}
            courierRemarks={courierRemarks}
            preparedBy={preparedBy}
            encryptedTestOrder={encryptedTestOrder}
          />
        </>
      )}
      {isMinimalOrder && (
        <h1 className="text-center text-3xl mt-10 font-semibold">
          LRF Pickup Request
        </h1>
      )}
      <BillingInformation clientDetails={clientDetails} isLoading={isLoading} />
      {!isMinimalOrder && (
        <p
          className="whitespace-normal text-center mt-8"
          style={{
            wordBreak: "break-all",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}
        >
          {concatenatedSaltIdAndEncryptedText}
        </p>
      )}
    </Box>
  );
});
export default TestOrderToBePrinted;
