import { Grid, Button } from "@mui/material";
import { getAllLabDept } from "store";
import { useDispatch } from "react-redux";
import { Table, Input, ConfigProvider, Select, Button as BtnAntD } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CreateUpdateLabDept from "./createUpdateLabDept";
import useDebounce from "hooks/useDebounce";
import { useCurrentHeight } from "utils/getScreenSize";
import useCheckPermission from "hooks/useCheckPermission";
import { useCallback, useEffect, useState } from "react";
import { EmptyData } from "components/mnl/global/emptyData";

const GetAllLabDept = () => {
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Administration:LabDepartment:Modify"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = useState("name");
  const [sortDirection, setSortDirection] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [labDeptData, setLabDeptData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);

  const [isForUpdate, setIsForUpdate] = useState(false);
  const [idForUpdate, setIdForUpdate] = useState(0);

  const LoadData = useCallback(() => {
    //HANDLE LOADING
    if (
      getAllLabDept.pending().type === "labDepartments/get-all-lab-dept/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(
      getAllLabDept({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((element) => {
      if (element.type === "labDepartments/get-all-lab-dept/fulfilled") {
        setIsLoading(false);
        setLabDeptData(element.payload.data.items);
        setTotal(element.payload.data.totalPages * pageSize);
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
  ]);

  useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  const onChange = (page) => {
    setCurrent(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Laboratory Department Detected"
      action={showCreateUpdate}
      btnTitle="Add New Laboratory Department"
      renderButton={accessRightsToModify}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(0);
  };
  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };
  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
    setIdForUpdate(0);
    setIsForUpdate(false);
  };

  const handleUpdate = (id) => {
    setIsForUpdate(true);
    setIdForUpdate(id);
  };

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 480,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 180,
      render: (rec, row) => (
        <>
          {accessRightsToModify ? (
            <BtnAntD
              size="small"
              type="text"
              style={{
                marginLeft: 2,
              }}
              icon={<EditIcon fontSize="inherit" />}
              className="bg-orange-600 text-white hover:enabled:bg-orange-400 hover:enabled:text-white"
              onClick={() => {
                handleUpdate(row);
                showCreateUpdate();
              }}
            >
              Update
            </BtnAntD>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder="Search Laboratory Department"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "name",
                label: "Name",
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid>

        {labDeptData.length !== 0 && accessRightsToModify ? (
          <Grid item xs={12} md={4} lg={4}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New Laboratory Department
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={
            accessRightsToModify
              ? columns
              : columns.filter((col) => col.dataIndex !== "")
          }
          dataSource={labDeptData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <CreateUpdateLabDept
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
      />
    </>
  );
};
export default GetAllLabDept;
