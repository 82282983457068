import {
  Button,
  Paper,
  Box,
  Grid,
  Typography,
  Alert,
  Stack,
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  AlertTitle,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userResetPassword, logout } from "store";
import eRequestLogo from "assests/erequest.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import notification from "components/mnl/global/openNotification";
import Copyright from "components/mnl/global/copyright";
import { useEffect, useState } from "react";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [passwordStr, setPasswordStr] = useState("");
  const [passwordConfirmStr, setPasswordConfirmStr] = useState("");
  const [isNotMatch, setIsNotMatch] = useState(true);
  const { register, handleSubmit } = useForm({ mode: "all" });
  const dispatch = useDispatch();
  const { isLoading, error, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get("u");
  const resetToken = searchParams.get("t");

  useEffect(() => {
    if (!userToken || !resetToken) {
      navigate("/login");
    }
  }, [userToken, resetToken, navigate]);
  const submitForm = (data) => {
    dispatch(
      userResetPassword({
        id: userToken,
        password: data.password,
        resetToken: resetToken,
      })
    ).then((val) => {
      if (val.type === "user/reset-password/fulfilled") {
        if (val.payload?.success) {
          notification.success({
            message: "Reset Password",
            description: `Reset Successful`,
          });
        }
      } else {
        notification.error({
          message: "Reset Password",
          description: `Please try again.`,
        });
      }
    });
  };
  const errorMessageLists = errorMessage.map((value) => {
    return (
      <>
        • {value} <br></br>
      </>
    );
  });

  const handleClickToLogin = () => {
    dispatch(logout());
    navigate("/login");
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };
  const onChangePassword = (e) => {
    setPasswordStr(e.target.value);
    if (
      e.target.value === passwordConfirmStr &&
      e.target.value !== "" &&
      passwordConfirmStr !== ""
    ) {
      setIsNotMatch(false);
    } else {
      setIsNotMatch(true);
    }
  };
  const onChangeConfirmPassword = (e) => {
    setPasswordConfirmStr(e.target.value);
    if (
      e.target.value === passwordStr &&
      e.target.value !== "" &&
      passwordStr !== ""
    ) {
      setIsNotMatch(false);
    } else {
      setIsNotMatch(true);
    }
  };
  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img alt="eRequest Client" src={eRequestLogo} />
        <Typography component="h1" variant="h4" sx={{ fontWeight: "bold" }}>
          Reset Password
        </Typography>
        <Box
          sx={{
            mt: 1,
            mb: 2,
            display: successMessage.length > 0 ? "none" : "",
          }}
          component="form"
          onSubmit={handleSubmit(submitForm)}
        >
          {error ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert variant="outlined" severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorMessageLists}
              </Alert>
            </Stack>
          ) : (
            ""
          )}

          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              {...register("password")}
              disabled={isLoading}
              required
              onChange={(event) => onChangePassword(event)}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel htmlFor="outlined-adornment-password-confirm">
              Confirm new password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password-confirm"
              type={showPasswordConfirm ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility confirm"
                    onClick={handleClickShowPasswordConfirm}
                    onMouseDown={handleMouseDownPasswordConfirm}
                    edge="end"
                  >
                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm new password"
              {...register("passwordConfirm")}
              disabled={isLoading}
              required
              onChange={(event) => onChangeConfirmPassword(event)}
            />
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                fullWidth
                onClick={handleClickToLogin}
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                disabled={isLoading}
              >
                Login
              </Button>
            </Grid>
            <Grid item xs={8}>
              {isLoading ? (
                <LoadingButton
                  fullWidth
                  sx={{ mt: 2, mb: 2 }}
                  loading
                  loadingPosition="end"
                  endIcon={<SendIcon />}
                  variant="contained"
                >
                  Reset Password
                </LoadingButton>
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  disabled={isNotMatch}
                >
                  Reset Password
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: successMessage.length > 0 ? "" : "none",
            alignItems: "center",
          }}
        >
          <Alert severity="success">
            <AlertTitle>Password Changed</AlertTitle>
            Please log in with the new password.
            <Button
              fullWidth
              onClick={handleClickToLogin}
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              Login
            </Button>
          </Alert>
        </Box>
      </Box>

      <Copyright />
    </Grid>
  );
};
export default ResetPasswordForm;
