import React from "react";
import { DatePicker } from "antd";

const DateFilter = ({ handleChangeDate }) => {
  //Date filtering, user can select a start and end date
  const { RangePicker } = DatePicker;

  return <RangePicker onChange={handleChangeDate} format={"MM/DD/YYYY"} />;
};

export default DateFilter;
