import { Tag } from "antd";
import { isDataEmpty } from "utils/isDataEmpty";

export const clientWillDrawSpecimen = (isClientWillDrawTheBlood) => {
  if (!isClientWillDrawTheBlood) {
    return "No";
  }
  return "Yes";
};

export const formattedPatientName = (firstName, middleName, lastName) => {
  return `${isDataEmpty(firstName) ? "" : firstName} ${
    isDataEmpty(middleName) ? "" : middleName
  }${!isDataEmpty(middleName) && !middleName?.includes(".") ? "." : ""} ${
    isDataEmpty(lastName) ? "" : lastName
  }`;
};

export const renderDiagnosesList = (
  diagnosesList,
  valueType,
  oldValue,
  newValue
) => {
  return (
    <ul className="list-disc">
      {diagnosesList?.map((item) => {
        const isDiagnosisRemovedOrAdded =
          valueType === "newValue"
            ? !oldValue?.DiagnosesList?.some(
                (diagnosis) => diagnosis?.id === item?.id
              )
            : !newValue?.DiagnosesList?.some(
                (diagnosis) => diagnosis?.id === item?.id
              );

        return (
          <li className="ml-7" key={item?.id}>
            <Tag
              color={
                getTagColorAndLabel(isDiagnosisRemovedOrAdded, valueType)?.color
              }
            >
              {item?.displayName}
            </Tag>
            <strong className="text-xs">
              {getTagColorAndLabel(isDiagnosisRemovedOrAdded, valueType)?.label}
            </strong>
          </li>
        );
      })}
    </ul>
  );
};

export const renderLabtest = (labtest, valueType, oldValue, newValue) => {
  return (
    <ul className="list-disc">
      {labtest?.map((item) => {
        const labtestData = item?.LabTest;
        const isLabtestRemovedOrAdded =
          valueType === "newValue"
            ? !oldValue?.TestOrderDetails?.some(
                (test) => test?.LabTest?.Id === labtestData?.Id
              )
            : !newValue?.TestOrderDetails?.some(
                (test) => test?.LabTest?.Id === labtestData?.Id
              );

        return (
          <li className="ml-7" key={labtestData?.Id}>
            <Tag
              color={
                getTagColorAndLabel(isLabtestRemovedOrAdded, valueType)?.color
              }
            >
              {labtestData?.Name}
            </Tag>
            <strong className="text-xs">
              {getTagColorAndLabel(isLabtestRemovedOrAdded, valueType)?.label}
            </strong>
          </li>
        );
      })}
    </ul>
  );
};

export const renderPatientSpecimens = (
  specimens,
  valueType,
  oldValue,
  newValue
) => {
  return (
    <ul className="list-disc">
      {specimens?.map((item) => {
        const specimenData = item?.Specimen;
        const isSpecimenRemovedOrAdded =
          valueType === "newValue"
            ? !oldValue?.PatientSpecimens?.some(
                (specimen) => specimen?.Specimen?.Id === specimenData?.Id
              )
            : !newValue?.PatientSpecimens?.some(
                (specimen) => specimen?.Specimen?.Id === specimenData?.Id
              );

        return (
          <li className="ml-7" key={specimenData?.Id}>
            <Tag
              color={
                getTagColorAndLabel(isSpecimenRemovedOrAdded, valueType)?.color
              }
            >
              {specimenData?.Name}
            </Tag>
            <strong className="text-xs">
              {getTagColorAndLabel(isSpecimenRemovedOrAdded, valueType)?.label}
            </strong>
          </li>
        );
      })}
    </ul>
  );
};

export const renderSpecialHandlings = (
  specialHandlings,
  valueType,
  oldValue,
  newValue
) => {
  return (
    <ul className="list-disc">
      {specialHandlings?.map((item) => {
        const specialHandlingData = item?.SpecialHandling;
        const isSpecialHandlingRemovedOrAdded =
          specialHandlings.length > 0 &&
          (valueType === "newValue"
            ? !oldValue?.TestOrderSpecialHandlings?.some(
                (data) => data?.SpecialHandling?.Id === specialHandlingData?.Id
              )
            : !newValue?.TestOrderSpecialHandlings?.some(
                (data) => data?.SpecialHandling?.Id === specialHandlingData?.Id
              ));
        return (
          <li className="ml-7" key={specialHandlingData?.Id}>
            <Tag
              color={
                getTagColorAndLabel(isSpecialHandlingRemovedOrAdded, valueType)
                  ?.color
              }
            >
              {specialHandlingData?.Instructions}
            </Tag>
            <strong className="text-xs">
              {
                getTagColorAndLabel(isSpecialHandlingRemovedOrAdded, valueType)
                  ?.label
              }
            </strong>
          </li>
        );
      })}
    </ul>
  );
};

export const getTagColorAndLabel = (isDataRemovedOrAdded, valueType) => {
  if (isDataRemovedOrAdded && valueType === "oldValue") {
    return { color: "red", label: "- Removed" };
  } else if (isDataRemovedOrAdded && valueType === "newValue") {
    return { color: "green", label: "- Newly Added" };
  } else {
    return { color: "", label: "" };
  }
};
