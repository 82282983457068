import { Box } from "@mui/material";
import React from "react";
import FirstSection from "./firstSection";
import SecondSection from "./secondSection";
import ThirdSection from "./thirdSection";
import { useSelector } from "react-redux";

export const SecondStep = () => {
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: {
          xs: "0.3rem",
          sm: "0.3rem",
          md: "0.9rem",
          lg: "2rem",
          xl: "2rem",
        },
      }}
    >
      <FirstSection />
      <SecondSection />
      {multiStepFormInputs.clientWillDrawSpecimen && (
        <>
          <ThirdSection />
        </>
      )}
    </Box>
  );
};
