import { Box, Chip, Divider, Tooltip } from "@mui/material";
import React from "react";
import { Badge, Button, Card, Form, Radio, Select } from "antd";
import { getWeekDay, timePeriodOptions } from "./data";
import DeleteIcon from "@mui/icons-material/Delete";

const AddSchedule = ({
  savedSchedule,
  scheduleInputs,
  handleRemoveSchedule,
  handleScheduleChange,
  handleAddSchedule,
  filteredWeekOption,
  handleTimePeriodChange,
}) => {
  const isScheduleInputsDirty = scheduleInputs.day === null;

  return (
    <Box>
      <Divider textAlign="center" sx={{ margin: "1rem 0" }}>
        <Chip label="Schedule" />
      </Divider>
      <Form.Item label="Schedules">
        <Select
          value={scheduleInputs.day}
          onChange={handleScheduleChange}
          style={{ width: "100%" }}
          placeholder="Please select schedule"
          options={filteredWeekOption}
        />
      </Form.Item>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Form.Item label="Time Period">
          <Radio.Group
            value={scheduleInputs.timePeriod}
            onChange={handleTimePeriodChange}
          >
            {timePeriodOptions.map((time) => (
              <Radio value={time.value}>{time.label}</Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Button
            disabled={isScheduleInputsDirty}
            type="primary"
            htmlType="button"
            className="submitBtn"
            onClick={handleAddSchedule}
          >
            Add Schedule
          </Button>
        </Box>
      </Box>
      {savedSchedule.length > 0 && (
        <Box
          sx={{
            display: "flex",
            padding: "0.3rem 0.4rem",
            flexWrap: "wrap",
            border: "1px solid whitesmoke",
            borderRadius: "0.3rem",
            gap: "1rem",
          }}
        >
          {savedSchedule &&
            savedSchedule.map((schedule) => (
              <Box key={schedule.day} className="group">
                <Badge.Ribbon
                  className="w-full"
                  text={
                    timePeriodOptions.find(
                      (time) => time.value === schedule.timePeriod
                    )?.label
                  }
                  color="green"
                >
                  <Card className="pt-5 bg-green-50 relative" size="small">
                    <strong>{getWeekDay(schedule.day)?.label}</strong>
                    <Box
                      onClick={() => handleRemoveSchedule(schedule.day)}
                      className="hidden group-hover:block absolute cursor-pointer bg-black/40 rounded-md h-full w-full top-0 left-0 right-0 mx-auto z-50"
                    >
                      <Tooltip title="Remove Schedule" placement="right" arrow>
                        <DeleteIcon
                          sx={{
                            color: "red",
                            fontSize: "1.2rem",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            margin: "0 auto",
                            zIndex: "100",
                            height: "100%",
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </Card>
                </Badge.Ribbon>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default AddSchedule;
