import { Tabs } from "antd";
import React, { useState } from "react";
import ClientInformationsInput from "./clientInformationsInput";
import ClientConfigurationsInput from "./clientConfigurationsInput";
import { useCurrentWidth } from "utils/getScreenSize";

const FormInputTabs = ({
  isForUpdate,
  loading,
  optionType,
  setClientStartDateValue,
  clientStartDateValue,
  optionAddressType,
  countryOptions,
  handleSelectCountry,
  selectedAddressIds,
  provinceOptions,
  handleSelectProvince,
  cityMunicipalityOptions,
  handleSelectCityMunicipality,
  barangayOptions,
  isProcessingWindowTimeEnabled,
  configurationInputsHasError,
  informationInputsHasError,
}) => {
  const isMobileView = useCurrentWidth() < 600;
  const [selectedActiveKeyTab, setSelectedActiveKeyTab] =
    useState("Informations");

  const renderTabLabel = (label) => {
    const inputLabelValidationMap = {
      Informations: (
        <p
          className={`${
            informationInputsHasError ? "text-red-500" : "text-black"
          }`}
        >
          {label}
        </p>
      ),
      Configurations: (
        <p
          className={`${
            configurationInputsHasError ? "text-red-500" : "text-black"
          }`}
        >
          {label}
        </p>
      ),
    };

    return inputLabelValidationMap[label];
  };

  const tabItems = [
    {
      key: "Informations",
      label: renderTabLabel("Informations"),
    },
    {
      key: "Configurations",
      label: renderTabLabel("Configurations"),
    },
  ];

  const handleTabChange = (key) => {
    setSelectedActiveKeyTab(key);
  };

  return (
    <>
      <Tabs
        tabBarStyle={{
          position: "fixed",
          width: isMobileView ? 500 : 537,
          backgroundColor: "white",
          zIndex: 100,
        }}
        activeKey={selectedActiveKeyTab}
        items={tabItems}
        onChange={handleTabChange}
      />
      <div
        className={`${
          selectedActiveKeyTab === "Informations" ? "block" : "hidden"
        }`}
      >
        <ClientInformationsInput
          isForUpdate={isForUpdate}
          loading={loading}
          optionType={optionType}
          setClientStartDateValue={setClientStartDateValue}
          clientStartDateValue={clientStartDateValue}
          optionAddressType={optionAddressType}
          countryOptions={countryOptions}
          handleSelectCountry={handleSelectCountry}
          selectedAddressIds={selectedAddressIds}
          provinceOptions={provinceOptions}
          handleSelectProvince={handleSelectProvince}
          cityMunicipalityOptions={cityMunicipalityOptions}
          handleSelectCityMunicipality={handleSelectCityMunicipality}
          barangayOptions={barangayOptions}
        />
      </div>
      <div
        className={`${
          selectedActiveKeyTab === "Configurations" ? "block" : "hidden"
        }`}
      >
        <ClientConfigurationsInput
          isProcessingWindowTimeEnabled={isProcessingWindowTimeEnabled}
        />
      </div>
    </>
  );
};

export default FormInputTabs;
