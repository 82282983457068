import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { notification } from "antd";
import { typeOptions } from "./data";
import useAdditionalInfoFunctions from "./useAdditionalInfoFunctions";
import getSpecimenRecursively from "components/mnl/testRequest/proxies/getSpecimenRecursively";
import getLabtestIdRecursively from "components/mnl/testRequest/proxies/getLabtestIdRecursively";
import getDuplicatedSpecimenNames from "components/mnl/testRequest/proxies/getDuplicatedSpecimenNames";
import { handleChangeSavedTestInfo } from "store/slice/savedTestInputsInfo";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import ResultComponent from "components/mnl/global/resultComponent";

const AdditionalPatientInfoModal = ({
  handleCloseAdditionalPatientInfoModal,
  fetchedInputFields,
  selectedTestDatas,
  inputValues,
  setInputValues,
  setShowSpecimenModal,
  specimenOptions,
  setTestInput,
}) => {
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const orderData = useSelector((state) => state.orderData);
  const savedTestInputsInfo = useSelector((state) => state.savedTestInputsInfo);
  const [selectedSpecimen, setSelectedSpecimen] = useState([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const dispatch = useDispatch();
  const { handleInputChange, handleInputCheckboxChange, handleSelectSpecimen } =
    useAdditionalInfoFunctions();

  const handleCloseInputModal = () => {
    //For closing specimen and additional patient information modal
    handleCloseAdditionalPatientInfoModal();
    setShowSpecimenModal(false);
  };

  const handleCloseWarningModal = () => {
    //For closing warning modal
    setShowWarningModal(false);
    setWarningMessage("");
  };

  const handleAddTestInArr = (e) => {
    //For adding additional patient data
    e.preventDefault();
    setTestInput("");
    const specimensToBeAdded = getSpecimenRecursively(selectedTestDatas); //If the selected labtest is not a type of test, get all of its child test's specimen uniquely
    const testIdsToAdd = getLabtestIdRecursively(selectedTestDatas); //If the selected labtest is not a type of test, get all of its child test's specimen and id
    const childTestIds = testIdsToAdd.map((data) => data.testId);
    const filteredSavedTest = savedLabtests?.savedLabtestsData?.filter(
      //For filtering of labtest data that will be added
      (data) => !childTestIds.includes(data.id)
    );
    const duplicatedSpecimenNames =
      getDuplicatedSpecimenNames(selectedTestDatas); //Getting duplicated specimen inside of a labtest that is not a type of test
    const testsToBeRemovedInOrder =
      multiStepFormInputs.testOrderDetails?.filter(
        //Filtered test that needs to be removed
        (data) => !childTestIds.includes(data.testId)
      );

    const filteredSpecimensToAdd = specimensToBeAdded.filter((specimen) => {
      //To avoid adding of specimen that is already on the list
      return !multiStepFormInputs.patientSpecimens.some((data) =>
        specimen.specimen.includes(data.specimen)
      );
    });

    const labTestRequirements =
      selectedTestDatas?.testDetails?.labTestRequirements; //Get the lab requirements of the labtest that is a type of test
    const labTestSpecimens = selectedTestDatas?.testDetails?.labTestSpecimens; //Get the specimen of the labtest that is a type of test

    const existingPatientSpecimenKey = multiStepFormInputs.patientSpecimens
      .filter((specimen) =>
        specimensToBeAdded.some(
          (specimenToBeAdded) =>
            specimen.specimen === specimenToBeAdded.specimen
        )
      )
      ?.map((data) => data.key);

    const defaultRelatedPatientSpecimenKey =
      selectedTestDatas?.child?.length > 0
        ? [...new Set(specimensToBeAdded.map((data) => data.specimen))]
        : specimensToBeAdded.map((data) => data.specimen);

    const relatedPatientSpecimenKeysValue =
      orderData.isOrderDataForUpdate && existingPatientSpecimenKey.length > 0
        ? existingPatientSpecimenKey
        : defaultRelatedPatientSpecimenKey;

    if (labTestRequirements?.length > 0 && labTestSpecimens?.length === 1) {
      //Check if the selected labtest has a lab requirements
      dispatch(
        handleChangeSavedTestInfo({
          savedRequirementDetails: [
            ...savedTestInputsInfo.savedRequirementDetails,
            fetchedInputFields,
          ],
          savedTestInfo: [
            ...savedTestInputsInfo.savedTestInfo,
            {
              id: selectedTestDatas.id,
              name: selectedTestDatas.name,
              type: selectedTestDatas.type,
            },
          ],
        })
      );

      dispatch(
        handleChangeLabtestData({
          savedLabtestsData: [
            ...filteredSavedTest,
            {
              type: selectedTestDatas.type,
              id: selectedTestDatas.id,
              testIds:
                selectedTestDatas?.child?.length > 0
                  ? testIdsToAdd.map((test) => test.testId)
                  : null,
              name: selectedTestDatas.name,
              code: selectedTestDatas.code,
              specimenExpirationInHours:
                selectedTestDatas?.testDetails?.maxSpecimenAgeInHours,
              specimen:
                selectedTestDatas?.child?.length > 0
                  ? specimensToBeAdded?.map((data) => data.specimen)
                  : selectedTestDatas.testDetails.labTestSpecimens.map(
                      (data) => data.name
                    ),
              isStatAllowed: selectedTestDatas?.testDetails?.isStatAllowed,
            },
          ],
          savedLabtestsIdAndSpecimen: [
            ...savedLabtests.savedLabtestsIdAndSpecimen,
            {
              testId: selectedTestDatas?.id,
              specimenExpirationInHours:
                selectedTestDatas?.testDetails?.maxSpecimenAgeInHours,
              specimen:
                selectedTestDatas?.testDetails?.labTestSpecimens[0]?.name,
            },
          ],
        })
      );

      dispatch(
        handleChangeSavedFields({
          isTestProfileEmpty: false,
        })
      );

      dispatch(
        handleChangeMultiFields({
          testOrderDetails: [
            ...testsToBeRemovedInOrder,
            {
              id: 0,
              testOrderId:
                orderData.selectedTestOrderId !== 0
                  ? orderData.selectedTestOrderId
                  : 0,
              testId: selectedTestDatas.id,
              testIds:
                selectedTestDatas?.child?.length > 0
                  ? testIdsToAdd.map((test) => test.testId)
                  : null,
              status: "Ordered",
              isStat: false,
              patientTestRequirementDatas: [
                {
                  testOrderDetailId: fetchedInputFields.testDetails.id,
                  patientRequirementDataDetails: inputValues.map(
                    ({ id, value, description }) => ({
                      patientTestRequirementDataId: id,
                      dataKey: id,
                      dataValue: value,
                      description: description,
                    })
                  ),
                },
              ],
              duplicatedSpecimenKeys:
                selectedTestDatas?.child?.length > 0
                  ? duplicatedSpecimenNames?.map((data) => data.specimenName)
                  : [
                      selectedTestDatas?.testDetails?.labTestSpecimens
                        ?.map((data) => data.name)
                        .toString(),
                    ],
              relatedPatientSpecimenKeys: relatedPatientSpecimenKeysValue,
            },
          ],
          patientSpecimens: [
            ...multiStepFormInputs.patientSpecimens,
            ...filteredSpecimensToAdd?.map((data) => ({
              unitOfMeasure: data.unitOfMeasure,
              selectedCollectionSiteOption: data.selectedCollectionSiteOption,
              requiresSampleOrigin: data.requiresSampleOrigin,
              requiresBloodDraw: data.requiresBloodDraw,
              specimen: data.specimen,
              specimenExpirationInHours: data.specimenExpirationInHours,
              key: data.key,
              specimenId: data.specimenId,
              specimenQuantity: data.specimenQuantity,
              notes: "",
              specimenVolReqData: data.specimenVolReqData,
              collectionDateTime: data.collectionDateTime,
              collectorId: data.collectorId,
              collectionSite: data.collectionSite,
              status: data.status,
            })),
          ],
        })
      );

      notification.success({
        message: "Added Labtest",
        description: `Successfully added ${selectedTestDatas.name}`,
      });
      handleCloseInputModal();
      setInputValues([]);
    } else if (
      //Check if the labtest doesn't have lab requirements but have multiple specimens
      labTestRequirements.length === 0 &&
      labTestSpecimens.length > 1
    ) {
      handleSubmitSpecimen();
      dispatch(
        handleChangeSavedFields({
          isTestProfileEmpty: false,
        })
      );
    } else if (labTestRequirements.length > 0 && labTestSpecimens.length > 1) {
      //Check if the labtest has multiple specimens and also lab requirements
      const savedSpecimenNames = multiStepFormInputs.patientSpecimens?.map(
        (data) => data.specimen
      );
      const filteredSelectedSpecimen = selectedSpecimen.filter(
        (val) => !savedSpecimenNames.includes(val.specimen)
      );

      dispatch(
        handleChangeSavedTestInfo({
          savedRequirementDetails: [
            ...savedTestInputsInfo.savedRequirementDetails,
            fetchedInputFields,
          ],
          savedTestInfo: [
            ...savedTestInputsInfo.savedTestInfo,
            {
              id: selectedTestDatas.id,
              name: selectedTestDatas.name,
              type: selectedTestDatas.type,
            },
          ],
        })
      );
      dispatch(
        handleChangeLabtestData({
          savedLabtestsData: [
            ...filteredSavedTest,
            {
              type: selectedTestDatas.type,
              id: selectedTestDatas.id,
              testIds:
                selectedTestDatas?.child?.length > 0
                  ? testIdsToAdd.map((test) => test.testId)
                  : null,
              name: selectedTestDatas.name,
              code: selectedTestDatas.code,
              specimenExpirationInHours:
                selectedTestDatas?.testDetails?.maxSpecimenAgeInHours,
              specimen: selectedSpecimen.map((data) => data.specimen),
              isStatAllowed: selectedTestDatas?.testDetails?.isStatAllowed,
            },
          ],
          savedLabtestsIdAndSpecimen: [
            ...savedLabtests?.savedLabtestsIdAndSpecimen,
            {
              testId: selectedTestDatas.id,
              specimenExpirationInHours:
                selectedTestDatas?.testDetails?.maxSpecimenAgeInHours,
              specimen: selectedSpecimen.map((data) => data.specimen),
            },
          ],
        })
      );

      dispatch(
        handleChangeSavedFields({
          isTestProfileEmpty: false,
        })
      );

      dispatch(
        handleChangeMultiFields({
          testOrderDetails: [
            ...testsToBeRemovedInOrder,
            {
              id: 0,
              testOrderId:
                orderData.selectedTestOrderId !== 0
                  ? orderData.selectedTestOrderId
                  : 0,
              testId: selectedTestDatas.id,
              status: "Ordered",
              testIds:
                selectedTestDatas?.child?.length > 0
                  ? testIdsToAdd.map((test) => test.testId)
                  : null,
              isStat: false,
              patientTestRequirementDatas: [
                {
                  testOrderDetailId: fetchedInputFields.testDetails.id,
                  patientRequirementDataDetails: inputValues.map(
                    ({ id, value, description }) => ({
                      patientTestRequirementDataId: id,
                      dataKey: id,
                      dataValue: value,
                      description: description,
                    })
                  ),
                },
              ],
              duplicatedSpecimenKeys: selectedSpecimen.map(
                (data) => data.specimen
              ),
              relatedPatientSpecimenKeys: selectedSpecimen.map(
                (data) => data.specimen
              ),
            },
          ],
          patientSpecimens: [
            ...multiStepFormInputs.patientSpecimens,
            ...filteredSelectedSpecimen.map((data) => ({
              requiresSampleOrigin: data.requiresSampleOrigin,
              requiresBloodDraw: data.requiresBloodDraw,
              selectedCollectionSiteOption: data.selectedCollectionSiteOption,
              specimen: data.specimen,
              unitOfMeasure: data.unitOfMeasure,
              specimenExpirationInHours: data.specimenExpirationInHours,
              key: data.key,
              specimenId: data.specimenId,
              specimenQuantity: data.specimenQuantity + "",
              notes: "",
              specimenVolReqData: data.specimenVolReqData,
              collectionDateTime: data.collectionDateTime,
              collectorId: data.collectorId,
              collectionSite: data.collectionSite,
              status: data.status,
            })),
          ],
        })
      );

      notification.success({
        message: "Added Labtest",
        description: `Successfully added ${selectedTestDatas.name}`,
      });
      handleCloseInputModal();
      setSelectedSpecimen([]);
      setInputValues([]);
    }
  };

  const handleSubmitSpecimen = () => {
    //Function for submitting multiple specimens
    const testIdsToAdd = getLabtestIdRecursively(selectedTestDatas);
    const savedSpecimenNames = multiStepFormInputs.patientSpecimens?.map(
      (data) => data.specimen
    );
    const filteredSelectedSpecimen = selectedSpecimen.filter(
      (val) => !savedSpecimenNames.includes(val.specimen)
    );

    const testsToBeRemovedInOrder =
      multiStepFormInputs.testOrderDetails?.filter(
        (data) => !testIdsToAdd.map((data) => data.testId).includes(data.testId)
      );

    const existingPatientSpecimenKey = [
      ...multiStepFormInputs.patientSpecimens
        .filter((specimen) =>
          selectedSpecimen.some(
            (specimenToBeAdded) =>
              specimen.specimen === specimenToBeAdded.specimen
          )
        )
        ?.map((data) => data.key),
      ...filteredSelectedSpecimen.map((item) => item.specimen),
    ];

    const defaultRelatedPatientSpecimenKey =
      selectedTestDatas?.child?.length > 0
        ? [...new Set(selectedSpecimen.map((data) => data.specimen))]
        : selectedSpecimen.map((data) => data.specimen);

    const relatedPatientSpecimenKeysValue =
      orderData.isOrderDataForUpdate && existingPatientSpecimenKey.length > 0
        ? existingPatientSpecimenKey
        : defaultRelatedPatientSpecimenKey;

    dispatch(
      handleChangeMultiFields({
        testOrderDetails: [
          ...testsToBeRemovedInOrder,
          {
            id: 0,
            testOrderId:
              orderData.selectedTestOrderId !== 0
                ? orderData.selectedTestOrderId
                : 0,
            testId: selectedTestDatas.id,
            status: "Ordered",
            testIds:
              selectedTestDatas?.child?.length > 0
                ? testIdsToAdd.map((test) => test.testId)
                : null,
            isStat: false,
            duplicatedSpecimenKeys: selectedSpecimen?.map(
              (data) => data.specimen
            ),
            relatedPatientSpecimenKeys: relatedPatientSpecimenKeysValue,
          },
        ],
        patientSpecimens: [
          ...multiStepFormInputs.patientSpecimens,
          ...filteredSelectedSpecimen.map((data) => ({
            requiresSampleOrigin: data.requiresSampleOrigin,
            requiresBloodDraw: data.requiresBloodDraw,
            selectedCollectionSiteOption:
              data.selectedCollectionSiteOption || null,
            specimen: data.specimen,
            unitOfMeasure: data.unitOfMeasure,
            specimenExpirationInHours: data.specimenExpirationInHours,
            key: data.key,
            specimenId: data.specimenId,
            specimenQuantity:
              data.specimenQuantity === undefined
                ? ""
                : data.specimenQuantity + "",
            notes: "",
            specimenVolReqData: data.specimenVolReqData,
            collectionDateTime: moment().format(),
            collectorId: data.collectorId,
            collectionSite: data.collectionSite,
            status: 0,
          })),
        ],
      })
    );

    dispatch(
      handleChangeLabtestData({
        savedLabtestsIdAndSpecimen: [
          ...savedLabtests?.savedLabtestsIdAndSpecimen,
          {
            testId: selectedTestDatas.id,
            specimenExpirationInHours:
              selectedTestDatas?.testDetails?.maxSpecimenAgeInHours,
            specimen: selectedSpecimen.map((data) => data.specimen),
          },
        ],
        savedLabtestsData: [
          ...savedLabtests.savedLabtestsData,
          {
            type: selectedTestDatas.type,
            id: selectedTestDatas.id,
            testIds:
              selectedTestDatas?.child?.length > 0
                ? testIdsToAdd.map((test) => test.testId)
                : null,
            name: selectedTestDatas.name,
            code: selectedTestDatas.code,
            specimenExpirationInHours:
              selectedTestDatas?.testDetails?.maxSpecimenAgeInHours,
            specimen: selectedSpecimen.map((data) => data.specimen),
            isStatAllowed: selectedTestDatas?.testDetails?.isStatAllowed,
          },
        ],
      })
    );
    notification.success({
      message: "Added Laboratory Test",
      description: `Successfully added ${selectedTestDatas.name}`,
    });
    setSelectedSpecimen([]);
    handleCloseInputModal();
  };

  const showTestName = (labtestInfoId, previousLabtestId) => {
    //Function for showing labtest name
    if (labtestInfoId !== previousLabtestId) {
      return true;
    }

    return false;
  };

  return (
    <Box
      sx={{
        marginBottom: "2rem",
        height:
          fetchedInputFields?.testDetails?.labTestRequirements?.length > 3 ||
          fetchedInputFields?.length > 3
            ? "30rem"
            : "20rem",
        overflowY:
          fetchedInputFields?.testDetails?.labTestRequirements?.length > 3 ||
          fetchedInputFields?.length > 3
            ? "scroll"
            : "auto",
      }}
    >
      <Typography
        sx={{
          fontSize: "1rem",
          textAlign: "center",
          paddingTop: "0.5rem",
          color: "grayishBlue.light",
          fontWeight: "bold",
          display:
            fetchedInputFields?.testDetails?.labTestRequirements?.length > 0 ||
            fetchedInputFields?.length > 0
              ? "block"
              : "none",
        }}
      >
        Additional Patient Information
      </Typography>
      <Box
        sx={{
          backgroundColor: "#E7EBF4",
          padding: "1rem 0.7rem",
          margin: "0.8rem 1rem",
          display:
            fetchedInputFields?.testDetails?.labTestRequirements?.length > 0 ||
            fetchedInputFields?.length > 0
              ? "block"
              : "none",
        }}
      >
        {fetchedInputFields?.testDetails?.labTestRequirements?.map(
          (input, index) => {
            return (
              <Box key={index}>
                {showTestName(
                  input.labTestInfoId,
                  fetchedInputFields?.testDetails?.labTestRequirements[
                    fetchedInputFields?.testDetails?.labTestRequirements?.indexOf(
                      input
                    ) - 1
                  ]?.labTestInfoId
                ) && (
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                    }}
                  >
                    For {selectedTestDatas.name}
                  </Typography>
                )}
                {input.requirementType === 3 ? (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          onChange={(e) =>
                            handleInputCheckboxChange(e, index, setInputValues)
                          }
                          checked={
                            inputValues.find(
                              (_, inputIdx) => inputIdx === index
                            )?.value === "true"
                          }
                        />
                      }
                      id={index}
                      label={
                        <Typography
                          className={input.isRequired ? "required" : ""}
                          sx={{
                            color: "grayishBlue.light",
                            fontSize: "1rem",
                          }}
                          variant="p"
                        >
                          {input.requirementDetails}
                        </Typography>
                      }
                    />
                  </FormGroup>
                ) : input.requirementType === 4 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <FormControl>
                      <Typography
                        className={input.isRequired ? "required" : ""}
                        sx={{
                          color: "grayishBlue.light",
                          fontSize: "1rem",
                        }}
                        variant="p"
                      >
                        {input.requirementDetails}
                      </Typography>
                      <RadioGroup
                        row
                        value={
                          inputValues?.find((_, inputIdx) => inputIdx === index)
                            ?.value || ""
                        }
                        onChange={(e) =>
                          handleInputChange(e, index, setInputValues)
                        }
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                ) : (
                  <Box sx={{ margin: "0.5rem 0" }}>
                    <Typography
                      className={input.isRequired ? "required" : ""}
                      sx={{
                        color: "grayishBlue.light",
                        fontSize: "1rem",
                      }}
                      variant="p"
                    >
                      {input.requirementDetails}
                    </Typography>
                    <TextField
                      id={index}
                      size="small"
                      value={
                        inputValues?.find((_, inputIdx) => inputIdx === index)
                          ?.value || ""
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            border: "1px solid",
                            borderColor: "darkBlue.secondary",
                            borderRadius: "0.7rem",
                          },
                        },
                        marginTop: "0.5rem",
                        backgroundColor: "white",
                        width: "100%",
                        borderRadius: "0.7rem",
                      }}
                      required={input.isRequired}
                      type={
                        typeOptions.find(
                          (type) => type.id === input.requirementType
                        )?.type || "text"
                      }
                      onChange={(e) =>
                        handleInputChange(e, index, setInputValues)
                      }
                    />
                  </Box>
                )}
              </Box>
            );
          }
        )}
      </Box>
      <Box sx={{ marginBottom: "2.5rem" }}>
        {selectedTestDatas.testDetails?.labTestSpecimens.length > 1 && (
          <>
            <Typography
              sx={{
                fontSize: "0.9rem",
                textAlign: "center",
                paddingTop: "0.5rem",
                color: "grayishBlue.light",
                fontWeight: "bold",
              }}
            >
              Please select specimen for{" "}
              <i>
                <strong>{`${selectedTestDatas.name}`}</strong>
              </i>
            </Typography>
            <Box
              sx={{
                backgroundColor: "#E7EBF4",
                padding: "0.3rem 0.7rem",
                margin: "0.8rem 1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {specimenOptions?.map((data) => (
                <FormControl key={data.key}>
                  <FormGroup>
                    <FormControlLabel
                      key={data.key}
                      control={
                        <Checkbox
                          checked={selectedSpecimen.some(
                            (selectedOption) => selectedOption.key === data.key
                          )}
                          onChange={(e) =>
                            handleSelectSpecimen(e, data, setSelectedSpecimen)
                          }
                        />
                      }
                      label={data.specimen}
                    />
                  </FormGroup>
                </FormControl>
              ))}
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          position: "absolute",
          bottom: 10,
          left: 54,
        }}
      >
        <button
          disabled={
            inputValues.some(
              (input) => input.value === "" && input.isRequired
            ) ||
            (selectedSpecimen.length === 0 && specimenOptions.length > 1)
          }
          onClick={handleAddTestInArr}
          className={styles["save_btn"]}
        >
          Save
        </button>
        <button
          className={styles["cancel_btn"]}
          onClick={handleCloseInputModal}
        >
          Cancel
        </button>
      </Box>
      <ResultComponent
        onCancel={handleCloseWarningModal}
        modalWidth={550}
        status="warning"
        title={"Some labtest have been removed"}
        subTitle={warningMessage}
        buttons={null}
        openModal={showWarningModal}
      />
    </Box>
  );
};

export default AdditionalPatientInfoModal;
