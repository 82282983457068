import React from "react";
import { Box, Chip, Divider } from "@mui/material";
import { Space, Collapse, Typography as AntdTypography } from "antd";

const PatientAddressesDropdown = ({ patientDetails }) => {
  const { Text } = AntdTypography;
  const { Panel } = Collapse;

  const getAddressTypeValue = (addressType) => {
    if (addressType === 0) {
      return "Company";
    } else if (addressType === 1) {
      return "Work";
    } else if (addressType === 2) {
      return "Billing";
    } else if (addressType === 3) {
      return "Home";
    } else {
      return "Others";
    }
  };
  return (
    <Box>
      <Divider>
        <Chip label="Address" />
      </Divider>
      <Collapse
        defaultActiveKey={Array.from({ length: 20 }).map((_, index) =>
          index.toString()
        )}
        style={{
          marginBottom: "0.5rem",
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        {patientDetails?.phAddresses?.map((address, idx) => (
          <Panel
            style={{ padding: "0 0.5rem" }}
            header={`Address # ${idx + 1}`}
            key={idx}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <Space wrap>
                <p>Address Type:</p>
                <Text code>{getAddressTypeValue(address.type)}</Text>
              </Space>
              <Space wrap>
                <p>Province:</p>
                <Text code>{address.province.name}</Text>
              </Space>
              <Space wrap>
                <p>City Municipality:</p>
                <Text code>
                  {address.cityMunicipality === null
                    ? "N/A"
                    : address.cityMunicipality.name}
                </Text>
              </Space>
              <Space wrap>
                <p>Barangay:</p>
                <Text code>
                  {address.barangay === null ? "N/A" : address.barangay.name}
                </Text>
              </Space>
              <Space wrap>
                <p>Street Name:</p>
                <Text code>
                  {address.streetName === null || address.streetName === ""
                    ? "N/A"
                    : address.streetName}
                </Text>
              </Space>
              <Space wrap>
                <p>Postal Code:</p>
                <Text code>
                  {address.postalCode === null || address.postalCode === ""
                    ? "N/A"
                    : address.postalCode}
                </Text>
              </Space>
              <Space wrap>
                <p>House Building Number:</p>
                <Text code>
                  {address.houseBuildingNumber === null ||
                  address.houseBuildingNumber === ""
                    ? "N/A"
                    : address.houseBuildingNumber}
                </Text>
              </Space>
            </Box>
          </Panel>
        ))}
      </Collapse>
    </Box>
  );
};

export default PatientAddressesDropdown;
