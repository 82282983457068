import { Box, Grid, Typography } from "@mui/material";
import styles from "./styles.module.css";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import useCalculateBirthdate from "hooks/useCalculateBirthdate";
import { parsedClientDetails } from "utils/parsedClientDetails";

const PatientInformation = ({ printTestOrder }) => {
  const savedInputs = useSelector((state) => state.savedInputs);
  const inputs = useSelector((state) => state.inputs);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const { getAge } = useCalculateBirthdate();
  const orderData = useSelector((state) => state.orderData);
  const patientFullName = `${inputs?.firstname} ${
    inputs?.middlename === null ? "" : inputs?.middlename
  }${
    inputs?.middlename !== null && !inputs?.middlename?.includes(".") ? "." : ""
  }
   ${inputs?.lastname}`;
  const { allowEmailSendingOfRequest } = parsedClientDetails();

  const patientMedicalRecordNo = `${
    savedInputs?.searchMedicalRecordNo === null
      ? ""
      : savedInputs?.searchMedicalRecordNo
  }`;

  const patientInfoLeft = [
    {
      id: 1,
      label: "Patient name",
      value: patientFullName,
    },
    {
      id: 2,
      label: "Medical Record no.",
      value: patientMedicalRecordNo,
    },
    {
      id: 3,
      label: "Date of Birth",
      value:
        inputs?.dateOfBirth && moment(inputs?.dateOfBirth).format("MM/DD/YYYY"),
    },
    {
      id: 4,
      label: "Patient age",
      value: `${getAge(inputs?.dateOfBirth)?.years || ""} years ${
        getAge(inputs?.dateOfBirth)?.months || ""
      } months ${getAge(inputs?.dateOfBirth)?.days || ""} days`,
    },
    {
      id: 5,
      label: "Sex code",
      value:
        inputs?.gender === "Male" ? "M" : inputs.gender === "Female" ? "F" : "",
    },
  ];

  const patientInfoRight = [
    {
      id: 6,
      label: "Date Collected",
      value: moment().format("MM/DD/YYYY"),
    },
    {
      id: 7,
      label: "Time Collected",
      value: multiStepFormInputs.clientWillDrawSpecimen
        ? moment(
            multiStepFormInputs.patientSpecimens[0]?.collectionDateTime
          ).format("LT")
        : "",
    },
    {
      id: 8,
      label: "Physician code",
      value: `${savedInputs?.physicianCode || ""}`,
    },
    {
      id: 9,
      label: "Physician name",
      value: `${savedInputs?.physicianName}`,
    },
    {
      id: 10,
      label: "Email address",
      value: inputs?.patientEmailForDisplay?.map((email) => email).join(", "),
    },
  ];

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          sx={{
            color: "darkBlue.main",
            marginBottom: "0.5rem",
            marginTop: "0.7rem",
            fontSize: "1.4rem",
            fontWeight: "semibold",
          }}
          variant="h4"
        >
          Patient Information
        </Typography>
        <p
          style={{
            marginTop: orderData.successfullyCreatedOrder ? "1rem" : "",
          }}
          className={styles.divider}
        />
      </Grid>
      <Grid container>
        <Grid
          sx={{ marginRight: "auto" }}
          xs={printTestOrder ? 5 : 12}
          sm={printTestOrder ? 5 : 12}
          md={5}
          lg={5}
          xl={5}
          item
        >
          <Box
            sx={{ marginRight: "auto" }}
            className={styles["grid-container"]}
          >
            {patientInfoLeft.map((info) => (
              <Box key={info.id} className={styles["grid-item"]}>
                <span className={styles.label}>{info.label}</span>
                <span className={styles.colon}>:</span>
                <span className={styles.value}>{info.value}</span>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid
          xs={printTestOrder ? 5 : 12}
          sm={printTestOrder ? 5 : 12}
          md={5}
          lg={5}
          xl={5}
          item
        >
          <Box className={styles["grid-container"]}>
            {patientInfoRight
              .filter((item) =>
                !allowEmailSendingOfRequest ? item.id !== 10 : item
              )
              .map((info) => (
                <Box key={info.id} className={styles["grid-item"]}>
                  <span className={styles.label}>{info.label}</span>
                  <span className={styles.colon}>:</span>
                  <span className={styles.value}>{info.value}</span>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientInformation;
