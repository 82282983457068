import React from "react";
import { Select } from "antd";

const AssortedFilter = ({ defaultValue, handleChangeSortField }) => {
  //For filtering test order by date, order status and order id. It is either by ascending or descending
  return (
    <Select
      defaultValue={defaultValue}
      onChange={handleChangeSortField}
      style={{
        width: 120,
      }}
      options={[
        {
          value: "orderDate",
          label: "Date Ordered",
        },
        {
          value: "orderStatus",
          label: "Order Status",
        },
        {
          value: "id",
          label: "Order Id",
        },
      ]}
    />
  );
};

export default AssortedFilter;
