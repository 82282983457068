import { Empty, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import AuditTrailDisplay from "./auditTrailDisplay";
import { getTestOrderAuditTrail } from "store";

const AuditTrailModal = ({ open, testOrderId, handleCloseModal }) => {
  const [auditTrailData, setAuditTrailData] = useState([]);
  const dispatch = useDispatch();
  const [auditTrailOptions, setAuditTrailOptions] = useState({
    openedAuditTrail: [],
  });
  const isAuditTrailDataEmpty = auditTrailData?.length === 0;

  const handleToggleAuditTrailDetails = (dataToBeStored) => {
    const isDetailsOpen = auditTrailOptions.openedAuditTrail.some(
      (data) => data.id === dataToBeStored.id
    );

    if (isDetailsOpen) {
      const filteredOpenedDescriptionIds =
        auditTrailOptions.openedAuditTrail.filter(
          (data) => data.id !== dataToBeStored.id
        );
      setAuditTrailOptions({
        openedAuditTrail: filteredOpenedDescriptionIds,
      });
    } else {
      setAuditTrailOptions({
        openedAuditTrail: [
          ...auditTrailOptions.openedAuditTrail,
          dataToBeStored,
        ],
      });
    }
  };

  const showAuditTrailDetails = (index) => {
    const isIndexPresent = auditTrailOptions.openedAuditTrail.some(
      (data) => data.id === index
    );
    if (isIndexPresent) {
      return true;
    }

    return false;
  };

  const fetchAuditTrail = () => {
    if (testOrderId !== null) {
      dispatch(getTestOrderAuditTrail({ id: testOrderId })).then((res) => {
        const isFetchSuccessful = res?.payload?.success;

        if (isFetchSuccessful) {
          setAuditTrailData(res?.payload?.data);
        }
      });
    }
  };

  useEffect(() => {
    fetchAuditTrail();
    // eslint-disable-next-line
  }, [dispatch, testOrderId]);

  useEffect(() => {
    if (!open) {
      setAuditTrailOptions({
        openedAuditTrail: [],
      });
    }
  }, [open]);

  return (
    <Modal
      closable={true}
      open={open}
      title={
        isAuditTrailDataEmpty
          ? ""
          : `Test Order Audit Trail - ${auditTrailData[0]?.autoGeneratedOrderNumber}`
      }
      onCancel={handleCloseModal}
      footer={null}
      width={850}
    >
      <Box
        sx={{
          maxHeight: "calc(100vh - 300px)",
          overflow: "auto",
        }}
      >
        {!isAuditTrailDataEmpty && (
          <AuditTrailDisplay
            handleToggleAuditTrailDetails={handleToggleAuditTrailDetails}
            showAuditTrailDetails={showAuditTrailDetails}
            auditTrailData={auditTrailData}
          />
        )}
        {isAuditTrailDataEmpty && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 5,
            }}
          >
            <Empty
              description={
                <span className="text-[1rem]">
                  No audit trail data to be shown
                </span>
              }
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AuditTrailModal;
