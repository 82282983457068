import { Box, Grid, Skeleton, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { orderStatusTagColor as orderStatusTextColor } from "components/mnl/admin/labtest/data";
import { useLocation } from "react-router-dom";

const HeaderContent = (props) => {
  const {
    cancelReason,
    clientDetails,
    isLoading,
    orderNumber,
    orderStatus,
    handlePrintTestOrder,
  } = props;
  const location = useLocation();
  const lowerCasedOrderStatus = orderStatus?.toLowerCase();
  const isOrderStatusCancelled = lowerCasedOrderStatus === "canceled";
  const isOrderStatusHold = lowerCasedOrderStatus === "hold";
  const isOrderStatusRejected = lowerCasedOrderStatus === "rejected";
  const inReceivingRoute = location.pathname.includes("receiving");
  const showCancellationReason =
    isOrderStatusCancelled || isOrderStatusHold || isOrderStatusRejected;
  const orderStatusTextColorValue =
    orderStatus?.toLowerCase() === "completeddelivery"
      ? "#96d952"
      : orderStatus?.toLowerCase() === "intransit"
      ? "#af6dea"
      : orderStatusTextColor(orderStatus).color;

  const renderReasonLabel = () => {
    if (isOrderStatusCancelled) {
      return "Cancellation Reason";
    } else if (isOrderStatusHold) {
      return "Held Reason";
    } else if (isOrderStatusRejected) {
      return "Rejection Reason";
    }
  };

  const printTestOrder = () => {
    if (inReceivingRoute) {
      handlePrintTestOrder();
    }
  };

  return (
    <Grid rowSpacing={2} columnSpacing={1} container>
      <Grid
        sx={{ display: "flex", flexDirection: "column" }}
        xs={6}
        sm={6}
        md={3}
        lg={3}
        xl={3}
        item
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "500", color: "darkBlue.main" }}
        >
          Client no.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            width: "max-content",
            color: "black",
            marginTop: "0.3rem",
            fontSize: "1.3rem",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rounded" width={210} height={25} />
          ) : (
            clientDetails?.clientCode
          )}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: {
            sm: "flex",
            xs: "flex",
            md: "none",
            lg: "none",
            xl: "none",
          },
          flexDirection: "column",
        }}
        xs={12}
        sm={12}
        md={2}
        lg={2}
        xl={2}
        item
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rounded" width={80} height={80} />
          ) : (
            <QRCode
              onClick={printTestOrder}
              style={{
                cursor: inReceivingRoute ? "pointer" : "auto",
                display: orderNumber === null ? "none" : "block",
                marginRight: "auto",
                marginLeft: "auto",
              }}
              size={80}
              value={orderNumber + ""}
            />
          )}
          <Box>
            <Typography
              variant="p"
              sx={{ fontWeight: "500", color: "darkBlue.main" }}
            >
              Order no.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                width: "max-content",
                color: "black",
                marginTop: "0.3rem",
                fontSize: "1.3rem",
              }}
            >
              {isLoading && (
                <Skeleton variant="rounded" width={100} height={25} />
              )}
              {!isLoading && orderNumber === null ? (
                <Typography
                  sx={{
                    fontStyle: "italic",
                    color: "softBlue.secondary",
                    fontWeight: "bold",
                  }}
                  variant="p"
                >
                  {"[pending]"}
                </Typography>
              ) : (
                orderNumber
              )}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "bold",
                backgroundColor: "#EBEFF8",
                padding: "0.1rem 0.3rem",
                color: orderStatusTextColorValue,
              }}
              variant="p"
            >
              {orderStatus?.toUpperCase()}
            </Typography>
            {showCancellationReason && (
              <Box sx={{ marginTop: "0.3rem" }}>
                <Typography sx={{ color: "darkBlue.main" }} variant="p">
                  {renderReasonLabel()}:{" "}
                  <Typography sx={{ color: "black" }} variant="p">
                    {cancelReason}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid
        sx={{ display: "flex", flexDirection: "column" }}
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        item
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "500", color: "darkBlue.main" }}
        >
          Client name
        </Typography>
        <Typography
          variant="h5"
          sx={{
            width: "auto",
            color: "black",
            marginTop: "0.3rem",
            fontSize: "1.3rem",
          }}
        >
          {isLoading ? (
            <Skeleton variant="rounded" width={210} height={25} />
          ) : (
            clientDetails?.clientName
          )}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: {
            sm: "none",
            xs: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          flexDirection: "column",
        }}
        xs={6}
        sm={6}
        md={5}
        lg={5}
        xl={5}
        item
      >
        <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          {isLoading ? (
            <Skeleton variant="rounded" width={80} height={80} />
          ) : (
            <QRCode
              onClick={printTestOrder}
              style={{
                cursor: inReceivingRoute ? "pointer" : "auto",
                display: orderNumber === null ? "none" : "block",
                marginRight: "0.7rem",
              }}
              size={80}
              value={orderNumber + ""}
            />
          )}

          <Box>
            <Typography
              variant="p"
              sx={{ fontWeight: "500", color: "darkBlue.main" }}
            >
              Order no.
            </Typography>
            <Typography
              variant="h5"
              sx={{
                width: "max-content",
                color: "black",
                marginTop: "0.3rem",
                fontSize: "1.3rem",
              }}
            >
              {isLoading && (
                <Skeleton variant="rounded" width={100} height={25} />
              )}
              {!isLoading && orderNumber === null ? (
                <Typography
                  sx={{
                    fontStyle: "italic",
                    color: "softBlue.secondary",
                    fontWeight: "bold",
                  }}
                  variant="p"
                >
                  {"[pending]"}
                </Typography>
              ) : (
                orderNumber
              )}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "bold",
                backgroundColor: "#EBEFF8",
                padding: "0.1rem 0.3rem",
                color: orderStatusTextColorValue,
              }}
              variant="p"
            >
              {orderStatus?.toUpperCase()}
            </Typography>
            {showCancellationReason && (
              <Box sx={{ marginTop: "0.3rem" }}>
                <Typography sx={{ color: "darkBlue.main" }} variant="p">
                  {renderReasonLabel()}:{" "}
                  <Typography sx={{ color: "black" }} variant="p">
                    {cancelReason}
                  </Typography>
                </Typography>
              </Box>
            )}
            {isLoading && showCancellationReason && (
              <Skeleton variant="rounded" width={140} height={20} />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeaderContent;
