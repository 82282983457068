import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/mnl/global/menuBar";
import { theme } from "components/mnl/global/theme";
import Toolbar from "@mui/material/Toolbar";
import FormContent from "components/mnl/testRequest/forms/formLayout/formContent";
import V2sessionTimeout from "components/mnl/global/v2SessionTimeout";

const TestRequestPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Test Request"} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100%",
            minHeight: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <FormContent />
        </Box>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default TestRequestPage;
