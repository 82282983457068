import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLabDeptLookUp } from "store";

const DepartmentSelectFilter = ({
  selectedLabDepartmentId,
  handleSelectLabDepartmentId,
}) => {
  const [labDepartmentsData, setLabDepartmentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const fetchLabDepartmentDatas = () => {
    if (
      getLabDeptLookUp.pending().type === "labDept/get-lab-dept-look-up/pending"
    ) {
      setIsLoading(true);
    }

    dispatch(getLabDeptLookUp()).then((res) => {
      const isFetchSuccessful = res?.payload?.data;

      if (isFetchSuccessful) {
        setLabDepartmentsData(res?.payload?.data);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchLabDepartmentDatas();
    // eslint-disable-next-line
  }, []);

  const labDepartmentOptions =
    labDepartmentsData?.length > 0 &&
    labDepartmentsData?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));

  return (
    <Select
      showSearch
      style={{ width: 170 }}
      loading={isLoading}
      options={labDepartmentOptions}
      value={selectedLabDepartmentId}
      onChange={handleSelectLabDepartmentId}
      placeholder="Select department"
      virtual={false}
      filterOption={filterOption}
      allowClear
    />
  );
};

export default DepartmentSelectFilter;
