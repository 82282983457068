import { Modal, Form, Input, Button, Popconfirm, Select } from "antd";
import { useDispatch } from "react-redux";
import { createProvince, deleteProvince, updateProvince } from "store";
import useCheckPermission from "hooks/useCheckPermission";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import { useCallback, useEffect, useState } from "react";
import notification from "components/mnl/global/openNotification";

const CreateUpdateProvinces = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
    countryData,
  } = props;

  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Administration:Province:Delete"
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const LoadUpdateData = useCallback(() => {
    form.setFieldsValue({
      name: idForUpdate.name,
      countryId: idForUpdate.countryId,
    });
  }, [form, idForUpdate]);

  useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateProvince.pending().type === "provinces/update-provinces/pending"
      ) {
        setLoading(true);
      }
      dispatch(updateProvince([idForUpdate.id, values])).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Updated Province",
            description: `Successfully updated ${values.name}`,
          });
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to update province",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
        }
      });
    } else {
      if (
        createProvince.pending().type === "provinces/create-provinces/pending"
      ) {
        setLoading(true);
      }
      dispatch(createProvince(values)).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created Province",
            description: `Successfully created ${values.name}`,
          });
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to add province",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line
    errorInfo.errorFields.map((item) => {
      notification.error({
        message: "Required Field",
        description: item?.errors[0].props.children,
      });
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (
      deleteProvince.pending().type === "provinces/delete-provinces/pending"
    ) {
      setLoading(true);
    }
    dispatch(deleteProvince(idForUpdate.id)).then((val) => {
      if (val.payload.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Province",
          description: `Successfully deleted ${idForUpdate.name}`,
        });
      }
      if (!val.payload?.success) {
        setLoading(false);
        notification.error({
          message: "Failed to delete province",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
      }
    });
  };

  const countryOptions = countryData.map((element) => {
    return { label: element.name, value: element.id };
  });

  return (
    <Modal
      open={openCreateUpdate}
      title={isForUpdate ? "Update Province" : "Create Province"}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="province"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/*  Name */}
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: <i>{"Please input Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        {/* Country */}
        <Form.Item
          label="Country"
          name="countryId"
          rules={[
            {
              required: true,
              message: <i>{"Please input Country."}</i>,
            },
          ]}
        >
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            virtual
            options={countryOptions}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            placeholder={"Country"}
          />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {accessRightsToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete the province"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateProvinces;
