import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/mnl/global/menuBar";
import { theme } from "components/mnl/global/theme";
import GetAllCityMunicipalities from "components/mnl/admin/cityMunicipalities/getAllCityMunicipalities";
import V2SessionTimeout from "components/mnl/global/v2SessionTimeout";
import TableLayout from "views/mnl/layout/tableLayout";

const CityMunicipalityPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"City Municipalities"} />
        <TableLayout>
          <GetAllCityMunicipalities />
        </TableLayout>
      </Box>
      <V2SessionTimeout />
    </ThemeProvider>
  );
};
export default CityMunicipalityPage;
