import React from "react";
import { Select } from "antd";
const DirectionFilter = ({ defaultValue, handleChangeSortDirection }) => {
  return (
    <Select
      defaultValue={defaultValue}
      onChange={handleChangeSortDirection}
      style={{
        width: 120,
        marginLeft: 2,
      }}
      options={[
        {
          value: 0,
          label: "Ascending",
        },
        {
          value: 1,
          label: "Descending ",
        },
      ]}
    />
  );
};

export default DirectionFilter;
