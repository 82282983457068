import { Modal, Tag } from "antd";
import { renderSupplyRequestStatusTag } from "./renderSupplyRequestStatusTag";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

const SupplyRequestDetailsModal = ({
  openModal,
  onCloseModal,
  supplyRequestDetails,
}) => {
  const modalTitle = (
    <div className="flex items-center gap-2">
      <p>{supplyRequestDetails?.client}</p> -
      {renderSupplyRequestStatusTag(supplyRequestDetails?.supplyRequestStatus)}
    </div>
  );

  const renderLabelAndValue = (label, value) => {
    return (
      <div className="grid grid-cols-7 space-y-2">
        <p className="col-span-2 pt-2">{label}:</p>
        <Tag className="col-span-5 w-fit text-[.8rem]">{value}</Tag>
      </div>
    );
  };

  return (
    <Modal
      open={openModal}
      onCancel={onCloseModal}
      footer={null}
      width={860}
      title={modalTitle}
    >
      <div
        className="overflow-y-auto"
        style={{ maxHeight: "calc(100vh - 300px)" }}
      >
        <div className="mt-4 mb-2 flex items-start justify-between flex-wrap">
          <div className="flex items-start gap-2 flex-col">
            <p>
              Date Created:{" "}
              <Tag>
                {moment(supplyRequestDetails?.dateCreated).format("LL")}
              </Tag>
            </p>
            <p>
              Created By: <Tag>{supplyRequestDetails?.createdBy}</Tag>
            </p>
            <p>
              Total Requested Items:{" "}
              <Tag>{supplyRequestDetails?.requestedItems?.length}</Tag>
            </p>
          </div>
          <div className="flex items-start gap-2 flex-col">
            <p>
              Requested By: <Tag>{supplyRequestDetails?.requestedBy}</Tag>
            </p>
            <p>
              Request Source: <Tag>{supplyRequestDetails?.requestSource}</Tag>
            </p>
          </div>
        </div>
        {supplyRequestDetails?.requestedItems?.map((item) => (
          <Accordion key={item.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${item?.id}-content`}
              id={item?.id}
              className="font-bold flex items-center gap-10"
            >
              <span className="mr-1 font-bold">
                {item?.inventoryItem?.name}
              </span>
              <span className="mr-1">-</span>
              <Tag color="success">{item?.quantityRequested}</Tag>
              <Tag className="ml-auto">{`${
                item?.supplyOrderStatus === "Released"
                  ? `Released on ${moment(item?.scheduledDeliveryDate).format(
                      "LL"
                    )}`
                  : item?.supplyOrderStatus
              }`}</Tag>
            </AccordionSummary>
            <hr />
            <AccordionDetails className="mt-2">
              {renderLabelAndValue(
                "Quantity Requested",
                item?.quantityRequested
              )}
              {item?.quantityIssued !== 0 &&
                renderLabelAndValue("Quantity Issued", item?.quantityIssued)}
              {item?.supplyOrderStatus === "Approved" &&
                renderLabelAndValue("Approved By", item?.approvedBy)}
              {renderLabelAndValue(
                "Date Created",
                moment(item?.dateCreated).format("LL")
              )}
              {renderLabelAndValue(
                "Category",
                item?.inventoryItem?.categoryName
              )}
              {renderLabelAndValue(
                "Supply Order Status",
                item?.supplyOrderStatus
              )}
              {item?.courierFullName !== null &&
                renderLabelAndValue("Courier", item?.courierFullName)}
              {item?.expirationDate !== null &&
                renderLabelAndValue(
                  "Expiration Date",
                  moment(item?.expirationDate).format("LL")
                )}
              {item?.scheduledDeliveryDate !== null &&
                renderLabelAndValue(
                  "Scheduled Delivery Date",
                  moment(item?.scheduledDeliveryDate).format("LL")
                )}
              {item?.supplyOrderStatus === "Rejected" &&
                renderLabelAndValue("Reject Reason", item?.rejectReason)}
              {item?.purpose && renderLabelAndValue("Purpose", item?.purpose)}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </Modal>
  );
};

export default SupplyRequestDetailsModal;
