import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import * as React from "react";
import MenuBar from "components/mnl/global/menuBar";
import { theme } from "components/mnl/global/theme";
import V2sessionTimeout from "components/mnl/global/v2SessionTimeout";
import GetAllPatient from "components/mnl/patient/getAllPatient";
import TableLayout from "views/mnl/layout/tableLayout";

const PatientPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar pageName={"Patient"} />
        <TableLayout>
          <GetAllPatient />
        </TableLayout>
      </Box>
      <V2sessionTimeout />
    </ThemeProvider>
  );
};
export default PatientPage;
