import {
  Grid,
  Typography,
  Alert,
  Stack,
  Box,
  Paper,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userConfirmEmail } from "store";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import eRequestLogo from "assests/erequest.png";
import ResendConfirmEmailButton from "./resendConfirmEmailButton";
import Copyright from "components/mnl/global/copyright";
import { useEffect, useRef, useState } from "react";

const ConfirmEmail = () => {
  const { handleSubmit } = useForm({ mode: "all" });
  const dispatch = useDispatch();
  const { isLoading, error, errorMessage, successMessage } = useSelector(
    (state) => state.auth
  );
  const [showResendConfirmationBtn, setShowResendConfirmationBtn] =
    useState(false);
  const [isConfirmEmailResent, setIsConfirmEmailResent] = useState(false);
  const [_userID, setUserId] = useState("");
  const [_token, setToken] = useState("");
  const [second, setSecond] = useState();
  const isConfirmationTokenExpired = errorMessage
    ?.toString()
    .includes("Token has expired");
  const location = useLocation();
  const isPageInEmailConfirmation = location.pathname === "/confirm-account";
  let startTimerInterval = useRef();
  let redirectSecond = 3;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");

  useEffect(() => {
    if (userId && token) {
      setUserId(userId);
      setToken(token);
    } else {
      navigate("/login");
    }
  }, [navigate, token, userId]);

  const submitForm = () => {
    dispatch(userConfirmEmail({ id: _userID, token: _token })).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        startTimerInterval.current = setInterval(() => {
          const leftSecond = redirectSecond--;
          setSecond(leftSecond);
          if (leftSecond === 0) {
            navigate("/login");
          }
        }, 1000);
      }
    });
  };

  useEffect(() => {
    if (isConfirmationTokenExpired) {
      setShowResendConfirmationBtn(true);
    } else {
      setShowResendConfirmationBtn(false);
    }
  }, [isConfirmationTokenExpired]);

  const errorMessageLists = errorMessage?.map((value) => {
    return (
      <Alert variant="outlined" severity="error">
        {value}
      </Alert>
    );
  });
  const successMessageLists = successMessage?.map((value) => {
    return (
      <Alert variant="outlined" severity="success">
        {value}
      </Alert>
    );
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img alt="eRequest Client" src={eRequestLogo} />

        <Typography component="h1" variant="h4" sx={{ fontWeight: "bold" }}>
          Confirm Account
        </Typography>

        <Box
          sx={{ mt: 10, mb: 2, width: "100%", px: 3 }}
          component="form"
          onSubmit={handleSubmit(submitForm)}
        >
          {isPageInEmailConfirmation && !isConfirmEmailResent && error && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              {errorMessageLists}
            </Stack>
          )}
          {isPageInEmailConfirmation &&
            !isConfirmEmailResent &&
            successMessage && (
              <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
                {successMessageLists}
              </Stack>
            )}
          {isPageInEmailConfirmation && isConfirmEmailResent && (
            <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
              <Alert variant="outlined" severity="success">
                Successfully Resent Confirmation Email, Please check your email
                address
              </Alert>
            </Stack>
          )}
          <hr />
          {!showResendConfirmationBtn && !isConfirmEmailResent && (
            <Typography component="h6" variant="h6">
              In order to start your eRequest Account, you need to confirm your
              account.
            </Typography>
          )}
          <Grid container>
            {!showResendConfirmationBtn && !isConfirmEmailResent && (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 2, mb: 10 }}
                disabled={
                  successMessage.length === 0 || isLoading ? false : true
                }
              >
                {successMessage.length === 0
                  ? "Confirm"
                  : second
                  ? "Redirecting to Login in " + second + " seconds"
                  : "Loading..."}
              </Button>
            )}
            {showResendConfirmationBtn && (
              <ResendConfirmEmailButton
                setIsConfirmEmailResent={setIsConfirmEmailResent}
                setShowResendConfirmationBtn={setShowResendConfirmationBtn}
                userId={userId}
              />
            )}
          </Grid>
        </Box>
      </Box>
      <Copyright />
    </Grid>
  );
};
export default ConfirmEmail;
