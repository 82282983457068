import { FormControl, TextField } from "@mui/material";
import { Modal } from "antd";
import notification from "components/mnl/global/openNotification";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";

const SpecimenNoteModal = ({
  open,
  onCancel,
  selectedSpecimenData,
  setSelectedSpecimenData,
}) => {
  const selectedSpecimenName = selectedSpecimenData.specimenName;
  const selectedSpecimenId = selectedSpecimenData.specimenId;
  const dispatch = useDispatch();
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const isNoteForUpdate =
    multiStepFormInputs.patientSpecimens.find(
      (specimen) => specimen.specimenId === selectedSpecimenId
    )?.notes !== "";
  const disableSubmitBtn = selectedSpecimenData.noteValue.trim().length === 0;

  const handleNoteInputChange = (e) => {
    //For getting the note value
    setSelectedSpecimenData({
      ...selectedSpecimenData,
      noteValue: e.target.value,
    });
  };

  const onSubmit = () => {
    //Adding note to the selected specimen
    onCancel();
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: multiStepFormInputs.patientSpecimens.map((specimen) =>
          specimen.specimenId === selectedSpecimenId
            ? {
                ...specimen,
                notes: selectedSpecimenData.noteValue,
              }
            : specimen
        ),
      })
    );
    if (isNoteForUpdate) {
      notification.success({
        message: "Updated note",
        description: "Successfully updated note",
      });
    } else {
      notification.success({
        message: "Added note",
        description: `Successfully added note for ${selectedSpecimenName}`,
      });
    }
  };

  return (
    <Modal
      okButtonProps={{
        className: "submitBtn",
        disabled: disableSubmitBtn,
      }}
      onOk={onSubmit}
      okText={isNoteForUpdate ? "Save" : "Submit"}
      onCancel={onCancel}
      centered
      open={open}
      title={`${selectedSpecimenName}`}
    >
      <FormControl fullWidth variant="filled">
        <TextField
          value={selectedSpecimenData.noteValue}
          onChange={handleNoteInputChange}
          sx={{ width: "100%", marginTop: "1rem" }}
          id="specimen-note"
          label="Enter your note..."
          multiline
          rows={4}
        />
      </FormControl>
    </Modal>
  );
};

export default SpecimenNoteModal;
