import { Box, Grid, Skeleton, Typography } from "@mui/material";
import styles from "components/mnl/testRequest/forms/fourthStep/styles.module.css";
import TestProfileSectionTwo from "./testProfileSectionTwo";
import QRCode from "react-qr-code";

const TestProfileSection = (props) => {
  const {
    encryptedTestOrder,
    testOrderDetails,
    diagnosesList,
    specimens,
    isLoading,
    specialHandlings,
    courierRemarks,
    preparedBy,
  } = props;

  const testOrderMinLength = testOrderDetails?.length >= 4;
  const sampleCollectorType =
    specimens?.length > 0 && specimens !== null
      ? specimens[0]?.collector?.collectorType
      : "";
  const sampleCollectorFirstName =
    specimens?.length > 0 && specimens !== null
      ? specimens[0]?.collector?.firstName
      : "";
  const sampleCollectorLastName =
    specimens?.length > 0 && specimens !== null
      ? specimens[0]?.collector?.lastName
      : "";

  const getQrCodeSize = () => {
    if (testOrderMinLength) {
      return 450;
    }
    return 200;
  };

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          sx={{
            color: "darkBlue.main",
            marginBottom: "0.5rem",
            marginTop: "1rem",
            fontSize: "1.4rem",
            fontWeight: "semibold",
          }}
          variant="h4"
        >
          Tests / Profiles Selection
        </Typography>
        <p className={styles.divider} />
      </Grid>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          width: "100%",
          justfiyContent: testOrderMinLength ? "center" : "start",
          alignItems: testOrderMinLength ? "center" : " start",
        }}
      >
        <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "29fr 70fr 350fr auto",
              marginTop: "1rem",
            }}
          >
            <Typography
              variant="p"
              sx={{
                fontWeight: "500",
                color: isLoading ? "" : "darkBlue.main",
              }}
            >
              No.
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontWeight: "500",
                color: isLoading ? "" : "darkBlue.main",
              }}
            >
              Test code
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontWeight: "500",
                color: isLoading ? "" : "darkBlue.main",
              }}
            >
              Test name
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: isLoading ? "" : "#EBEFF8",
              paddingBottom: "1rem",
              position: "relative",
              marginTop: "0.5rem",
              display: testOrderDetails?.length === 0 ? "none" : "block",
            }}
          >
            {isLoading ? (
              <Skeleton />
            ) : (
              testOrderDetails &&
              testOrderDetails.map((data, index) => (
                <Box
                  key={data.id}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "26fr 70fr 350fr auto",
                    padding: "0.2rem 0.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {data?.labTest?.code}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {data?.labTest?.name}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
        </Grid>
        <QRCode
          style={{ marginLeft: "auto" }}
          size={getQrCodeSize()}
          value={JSON.stringify(encryptedTestOrder)}
        />
      </Box>
      <TestProfileSectionTwo
        diagnosesList={diagnosesList}
        sampleCollectorName={
          specimens?.length === 0
            ? ""
            : `${
                sampleCollectorType === undefined ||
                sampleCollectorType === null
                  ? ""
                  : sampleCollectorType + " -"
              } ${
                sampleCollectorFirstName === null ||
                sampleCollectorFirstName === undefined
                  ? ""
                  : sampleCollectorFirstName
              } ${
                sampleCollectorLastName === null ||
                sampleCollectorLastName === undefined
                  ? ""
                  : sampleCollectorLastName
              }`
        }
        preparedBy={preparedBy}
        specimens={specimens}
        isLoading={isLoading}
        testOrderDetails={testOrderDetails}
        specialHandlings={specialHandlings}
        courierRemarks={courierRemarks}
      />
    </Grid>
  );
};

export default TestProfileSection;
