import { Box, Grid, Paper, Toolbar } from "@mui/material";
import React from "react";

const drawerWidth = 240;

const TableLayout = ({ children }) => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        mr: 2,
        ml: 2,
        mt: 3,
      }}
    >
      <Toolbar />
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 1,
            }}
          >
            {children}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableLayout;
