import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styles from "components/mnl/testRequest/styles/createPatientModalStyles/styles.module.css";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Switch } from "antd";
import moment from "moment";
import {
  createSampleCollector,
  getSampleCollectorTypes,
  requestUpdateSampleCollector,
} from "store";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import useFetchSpecificSampleCollector from "components/mnl/testRequest/proxies/useFetchSpecificSampleCollector";
import useCheckProvidedDate from "hooks/useCheckProvidedDate";
import { handleChangeSampleCollectorFields } from "store/slice/sampleCollectorInputSlice";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import notification from "components/mnl/global/openNotification";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";

const CreateUpdateSampleCollectorModal = ({
  handleCloseModal,
  handleFetchSampleCollectorLookUp,
  setSearchCollector,
  selectedSampleCollectorId,
}) => {
  const dispatch = useDispatch();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sampleCollectorTypes, setSampleCollectorTypes] = useState([]);
  const savedInputs = useSelector((state) => state.savedInputs);

  const isSampleCollectorForUpdate = savedInputs.sampleCollectorId !== null; //Check if the sample collector is for update
  useFetchSpecificSampleCollector(selectedSampleCollectorId);
  const sampleCollectorInputs = useSelector(
    (state) => state.sampleCollectorInputs
  );
  const { checkIfProvidedDateInPast } = useCheckProvidedDate();
  const isProvidedLicenseExpirationDateInPast = checkIfProvidedDateInPast(
    sampleCollectorInputs.licenseExpirationDate
  );

  const handleCloseFormModal = () => {
    //For closing sample collector form modal
    handleCloseModal();
    setIsFormDirty(false);
  };

  const handleSubmit = (e) => {
    //For submitting sample collector data
    e.preventDefault();
    if (
      !sampleCollectorInputs.firstName ||
      !sampleCollectorInputs.lastName ||
      !sampleCollectorInputs.collectorType ||
      isProvidedLicenseExpirationDateInPast
    ) {
      return setIsFormDirty(true); //Check if the input fields are empty
    }
    if (isSampleCollectorForUpdate) {
      handleUpdateSampleCollector(); //If it is for update, then perform the update sample collector function
    } else {
      if (
        createSampleCollector.pending().type ===
        "sample-collectors/create-sample-collectors/pending"
      ) {
        setIsLoading(true); //Check if the function is processing
      }
      dispatch(createSampleCollector(sampleCollectorInputs)).then((res) => {
        if (res.payload.success) {
          //Check the api call if it is successful
          dispatch(
            handleChangeSampleCollectorFields({
              id: 0,
              licenseNumber: "",
              licenseExpirationDate: null,
              firstName: "",
              lastName: "",
              collectorType: "",
              phoneNumber: "",
              email: "",
              isActive: true,
            })
          );
          dispatch(
            handleChangeSavedFields({
              refetchData: !savedInputs.refetchData,
            })
          );

          notification.success({
            message: "Created Sample Collector",
            description: `Successfully added ${sampleCollectorInputs.firstName} ${sampleCollectorInputs.lastName} to the list!`,
          });

          handleFetchSampleCollectorLookUp();
          handleCloseModal();
          setIsLoading(false);
        }
        if (!res.payload.success) {
          setIsLoading(false);
          notification.error({
            message: "Failed to create Sample Collector",
            description: errorMessagesDescription(
              res?.payload?.result?.value?.errorMessages
            ),
          });
        }
      });
    }
  };

  const handleUpdateSampleCollector = () => {
    if (
      !sampleCollectorInputs.firstName ||
      !sampleCollectorInputs.lastName ||
      !sampleCollectorInputs.collectorType ||
      isProvidedLicenseExpirationDateInPast
    ) {
      return setIsFormDirty(true); //Check if the input fields are empty
    }

    //For updating sample collector
    if (
      requestUpdateSampleCollector.pending().type ===
      "sample-collectors/req-update-sample-collectors/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(
      requestUpdateSampleCollector({
        ...sampleCollectorInputs,
        licenseNumber:
          sampleCollectorInputs.licenseNumber === ""
            ? null
            : sampleCollectorInputs.licenseNumber,
        licenseExpirationDate:
          sampleCollectorInputs.licenseExpirationDate === ""
            ? null
            : sampleCollectorInputs.licenseExpirationDate,
        id: sampleCollectorInputs.id,
      })
    ).then((res) => {
      if (res.payload.success) {
        handleFetchSampleCollectorLookUp();
        dispatch(
          handleChangeSampleCollectorFields({
            id: res.payload.data?.id,
            licenseNumber: res.payload.data?.licenseNumber
              ? res.payload.data?.licenseNumber
              : "",
            licenseExpirationDate: res.payload.data?.licenseExpirationDate
              ? moment(res.payload.data?.licenseExpirationDate)
              : "",
            firstName: res.payload.data?.firstName,
            lastName: res.payload.data?.lastName,
            collectorType: res.payload.data?.collectorType,
            clientId: +res.payload.data?.clientId,
            phoneNumber: res.payload.data?.phoneNumber,
            email: res.payload.data?.email,
            isActive: res.payload.data?.isActive,
          })
        );
        setSearchCollector(
          `${res.payload.data.firstName} ${res.payload.data.lastName} ${
            res.payload.data?.collectorType && "-"
          } ${res.payload.data?.collectorType}`
        );
        handleCloseModal();
        setIsLoading(false);
        notification.success({
          message: "Updated Sample Collector",
          description: `Successfully updated ${sampleCollectorInputs.firstName} ${sampleCollectorInputs.lastName}`,
        });
        dispatch(
          handleChangeSavedFields({
            sampleCollectorId: res?.payload?.data?.id,
            refetchData: !savedInputs.refetchData,
          })
        );
      }
      if (!res.payload.success) {
        setIsLoading(false);
        notification.error({
          message: "Failed to update Sample Collector",
          description: res?.payload?.result?.value?.errorMessages,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getSampleCollectorTypes()).then((res) => {
      setSampleCollectorTypes(res?.payload);
    });
  }, [dispatch]);

  return (
    <Box
      component="form"
      sx={{
        flexGrow: 1,
        padding: 3,
        width: { xs: "100%", sm: "100%", md: "100%", lg: "auto", xl: "auto" },
        height: { xs: "100%", sm: "100%", md: "100%", lg: "auto", xl: "auto" },
      }}
    >
      <Grid sx={{ marginTop: "0.5rem" }} spacing={2}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider sx={{ marginBottom: "1rem" }} textAlign="center">
            <Typography
              variant="h6"
              display="block"
              sx={{ fontWeight: "500", color: "darkBlue.main" }}
            >
              {savedInputs.sampleCollectorId !== null
                ? "Edit Sample Collector"
                : "Add Sample Collector"}
            </Typography>
          </Divider>
        </Grid>
        {/* Status */}
        <Box>
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            className="required"
          >
            Status
          </Typography>
          <Box sx={{ width: "4rem" }}>
            <Switch
              checkedChildren="Active"
              checked={sampleCollectorInputs.isActive === true}
              unCheckedChildren="Inactive"
              defaultChecked
              onChange={(checked) =>
                dispatch(
                  handleChangeSampleCollectorFields({
                    isActive: checked,
                  })
                )
              }
            />
          </Box>
        </Box>
        {/* First name and last name */}
        <Grid columnSpacing={2} sx={{ marginTop: "0.7rem" }} container>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              className="required"
            >
              First Name
            </Typography>
            <TextField
              error={!sampleCollectorInputs.firstName && isFormDirty}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.main",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={sampleCollectorInputs.firstName}
              onChange={(e) =>
                dispatch(
                  handleChangeSampleCollectorFields({
                    firstName: e.target.value,
                  })
                )
              }
              name="firstName"
              autoComplete="false"
              placeholder="Enter your first name..."
              size="small"
              variant="outlined"
            />
            <Typography
              sx={{
                fontSize: "0.7rem",
                color: "red",
                opacity:
                  !sampleCollectorInputs.firstName && isFormDirty ? 1 : 0,
              }}
            >
              First name is required
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
              className="required"
            >
              Last Name
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.main",
                  },
                },
              }}
              error={!sampleCollectorInputs.lastName && isFormDirty}
              className={styles["form-input"]}
              autoSave={false}
              value={sampleCollectorInputs.lastName}
              onChange={(e) =>
                dispatch(
                  handleChangeSampleCollectorFields({
                    lastName: e.target.value,
                  })
                )
              }
              name="lastName"
              autoComplete="false"
              placeholder="Enter your last name..."
              size="small"
              variant="outlined"
            />
            <Typography
              sx={{
                fontSize: "0.7rem",
                color: "red",
                opacity: !sampleCollectorInputs.lastName && isFormDirty ? 1 : 0,
              }}
            >
              Last name is required
            </Typography>
          </Grid>
        </Grid>
        {/* Employee license number and its expiration date */}
        <Grid columnSpacing={2} container>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              License Number
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.main",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={sampleCollectorInputs.licenseNumber}
              onChange={(e) =>
                dispatch(
                  handleChangeSampleCollectorFields({
                    licenseNumber: e.target.value,
                  })
                )
              }
              name="licenseNumber"
              autoComplete="false"
              placeholder="Enter your license number..."
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              License Expiration Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                name="licenseExpirationDate"
                slotProps={{
                  textField: {
                    size: "small",
                    error: isProvidedLicenseExpirationDateInPast,
                  },
                }}
                value={sampleCollectorInputs.licenseExpirationDate || null}
                inputFormat="MM/DD/YYYY"
                onChange={(value) =>
                  dispatch(
                    handleChangeSampleCollectorFields({
                      licenseExpirationDate: value,
                    })
                  )
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "2px solid",
                      borderColor: "darkBlue.main",
                    },
                  },
                  width: "100%",
                }}
                components={{
                  OpenPickerIcon: CalendarMonthIcon,
                }}
              />
            </LocalizationProvider>
            {isProvidedLicenseExpirationDateInPast && (
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  color: "red",
                }}
              >
                License Expiration Date cannot be set in past
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* Phone number, collector type and email*/}
        <Grid columnSpacing={2} sx={{ marginTop: "0.7rem" }} container>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              Phone Number
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.main",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={sampleCollectorInputs.phoneNumber}
              onChange={(e) =>
                dispatch(
                  handleChangeSampleCollectorFields({
                    phoneNumber: e.target.value,
                  })
                )
              }
              name="phoneNumber"
              autoComplete="false"
              placeholder="Enter your phoneNumber..."
              size="small"
              variant="outlined"
            />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} item>
            <Typography
              className="required"
              variant="p"
              sx={{
                fontWeight: "bold",
                color: "darkBlue.main",
              }}
            >
              Collector Type
            </Typography>
            <FormControl
              size="small"
              error={isFormDirty && !sampleCollectorInputs.collectorType}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.main",
                  },
                },
              }}
              fullWidth
            >
              <Select
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "darkBlue.secondary",
                    fontSize: "1.5rem",
                    transform: "rotate(90deg)",
                    marginRight: "0.3rem",
                  },
                }}
                IconComponent={ArrowForwardIosRoundedIcon}
                value={sampleCollectorInputs.collectorType}
                onChange={(e) =>
                  dispatch(
                    handleChangeSampleCollectorFields({
                      collectorType: e.target.value,
                    })
                  )
                }
              >
                {sampleCollectorTypes.map((type) => (
                  <MenuItem
                    selected={sampleCollectorInputs.collectorType}
                    value={type}
                  >
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              sx={{
                fontSize: "0.7rem",
                color: "red",
                opacity:
                  !sampleCollectorInputs.collectorType && isFormDirty ? 1 : 0,
              }}
            >
              Collector type is required
            </Typography>
          </Grid>
        </Grid>
        <Grid columnSpacing={2} container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", color: "darkBlue.main" }}
            >
              Email Address
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.main",
                  },
                },
              }}
              className={styles["form-input"]}
              autoSave={false}
              value={sampleCollectorInputs.email}
              onChange={(e) =>
                dispatch(
                  handleChangeSampleCollectorFields({
                    email: e.target.value,
                  })
                )
              }
              name="email"
              autoComplete="false"
              placeholder="Enter your email address..."
              size="small"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Footer buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          gap: "2rem",
          marginTop: "3rem",
        }}
      >
        <LoadingButton
          size="medium"
          disabled={isLoading}
          loading={isLoading}
          type="button"
          variant="contained"
          endIcon={
            savedInputs.sampleCollectorId !== null ? <SaveIcon /> : <SendIcon />
          }
          onClick={handleSubmit}
        >
          {savedInputs.sampleCollectorId !== null ? "Save Changes" : "Submit"}
        </LoadingButton>
        <Button
          size="medium"
          onClick={handleCloseFormModal}
          variant="dark"
          endIcon={<CloseIcon />}
          type="button"
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default CreateUpdateSampleCollectorModal;
