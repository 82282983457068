import { Modal, Form, Input, Button, Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { createICD10Codes, deleteICD10Codes, updateICD10Codes } from "store";
import useCheckPermission from "hooks/useCheckPermission";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import notification from "components/mnl/global/openNotification";
import { useCallback, useEffect, useState } from "react";

const CreateUpdateICD10Codes = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
  } = props;
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Laboratory:ICD10Code:Delete"
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const LoadUpdateData = useCallback(() => {
    form.setFieldsValue({
      icD10_Code: idForUpdate.icD10_Code,
      description: idForUpdate.description,
    });
  }, [form, idForUpdate]);

  useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateICD10Codes.pending().type ===
        "icd10codes/update-icd10codes/pending"
      ) {
        setLoading(true);
      }
      dispatch(updateICD10Codes([values, idForUpdate.id])).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Updated ICD10 Code",
            description: `Successfully updated ${values.icD10_Code}`,
          });
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to Update ICD10 Code",
            description: errorMessagesDescription(
              val.payload?.response?.data.errorMessages
            ),
          });
        }
      });
    } else {
      if (
        createICD10Codes.pending().type ===
        "icd10codes/create-icd10codes/pending"
      ) {
        setLoading(true);
      }
      dispatch(createICD10Codes(values)).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created ICD10 Code",
            description: `Successfully created ${values.icD10_Code}`,
          });
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to Create ICD10 Code",
            description: errorMessagesDescription(
              val.payload?.response?.data.errorMessages
            ),
          });
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line
    errorInfo.errorFields.map((item) => {
      notification.error({
        message: "Required Field",
        description: item?.errors[0].props.children,
      });
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (
      deleteICD10Codes.pending().type === "icd10codes/delete-icd10codes/pending"
    ) {
      setLoading(true);
    }
    dispatch(deleteICD10Codes(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted ICD10 Code",
          description: `Successfully deleted ${idForUpdate.icD10_Code}`,
        });
      }
      if (!val.payload?.success) {
        notification.error({
          message: "Failed to Delete ICD10 Code",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      open={openCreateUpdate}
      title={isForUpdate ? "Update ICD10 Code" : "Create ICD10 Code"}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="healthcareProvider"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/* ICD10 Code */}
        <Form.Item
          label="ICD10 Code"
          name="icD10_Code"
          rules={[
            {
              required: true,
              message: <i>{"Please input ICD10 Code."}</i>,
            },
          ]}
        >
          <Input placeholder="ICD10 Code" />
        </Form.Item>

        {/* Description */}
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: <i>{"Please input Description."}</i>,
            },
          ]}
        >
          <Input placeholder="Description" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {accessRightsToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete the ICD10 code"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateICD10Codes;
