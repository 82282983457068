import moment from "moment";
import { renderSupplyRequestAuditTrailAction } from "utils/renderSupplyRequestAuditTrailAction";
import { renderSupplyRequestAuditTrailIcon } from "utils/renderSupplyRequestAuditTrailIcon";

const Timeline = ({
  dateCreated,
  action,
  actionBy,
  supplyRequestStatus,
  description,
  showDescription,
}) => {
  const splittedAuditTrailDescription = description
    ?.split("\n")
    .filter((text) => text !== "");

  return (
    <div className="grid grid-cols-3 gap-10 mb-4 pb-3">
      <div className="bg-[#C1CFE0] place-self-center w-full h-[45px] flex items-center rounded-lg col-span-1 relative">
        <span className="text-[.95rem] font-semibold ml-4 text-[#324661]">
          {moment(dateCreated).format("lll")}
        </span>
        {renderSupplyRequestAuditTrailIcon(supplyRequestStatus)}
      </div>
      <div className="bg-[#C1CFE0] col-span-2 rounded-xl p-2 flex flex-col relative z-20">
        <span className="text-[.95rem] font-semibold text-[#324661]">{`${actionBy} - ${renderSupplyRequestAuditTrailAction(
          action
        )}`}</span>
        <span className="text-[.99rem] text-[#6382A8]">
          {supplyRequestStatus}
        </span>
        {showDescription && (
          <div className="bg-[#DFE5ED] mt-3 mx-1 mb-1 p-4 rounded-lg">
            <ul className="list-disc ml-4">
              {splittedAuditTrailDescription?.map((text, index) => (
                <li key={index} className="font-medium">
                  {text}
                </li>
              ))}
            </ul>
          </div>
        )}
        <span className="absolute -left-2 top-0 bottom-0 my-auto bg-[#C1CFE0] h-5 w-5 -z-10 rotate-45" />
      </div>
    </div>
  );
};

export default Timeline;
