import {
  Grid,
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
  createFilterOptions,
  Box,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getFrequentlyOrderedTest,
  getFrequentlyOrderedTestForUpdate,
  getTestsLookUp,
  getTestsLookUpForUpdate,
} from "store";
import decodedUserDetails from "utils/decodedUserDetails";
import truncateString from "components/mnl/global/truncateString";
import { sortDataAlphabetically } from "utils/sortDataAlphabetically";

const LabtestSearchInputs = ({ handleAddLabtest, testInput, setTestInput }) => {
  const [openTestOptions, setOpenTestOptions] = useState(false);
  const [freqOrderedTest, setFreqOrderedTest] = useState([]);
  const [freqTestInput, setFreqTestInput] = useState("");
  const [testProfileOptions, setTestProfileOptions] = useState([]);
  const inputs = useSelector((state) => state.inputs);
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const dispatch = useDispatch();
  const loading = openTestOptions && testProfileOptions?.length === 0;
  const isGenderEmpty = inputs.gender === ""; //Check if there is no gender selected
  const clientId = decodedUserDetails()?.clientId;
  const orderData = useSelector((state) => state.orderData);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedSpecimenIds = multiStepFormInputs.patientSpecimens.map(
    (specimen) => specimen.specimenId
  );
  const isStatusPickedUp = orderData.orderStatus.toLowerCase() === "pickedup";
  const testProfleFilterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) =>
      option.code +
      option.displayName +
      option.name +
      option.specimen +
      option.type +
      option.abbreviation,
  }); //Filter options for test profiles, to make all of the property used for searching

  const handleOpenTestOptions = () => {
    //Function for opening labtest dropdown input field option
    setOpenTestOptions(true);
  };
  const handleCloseTestOptions = () => {
    //Function for closing labtest dropdown input field option
    setOpenTestOptions(false);
  };

  const handleTestChange = (e) => {
    //Getting the value of labtest dropdown search input field
    setTestInput(e.target.value);
  };
  const handleFreqTestChange = (e) => {
    //Getting the value of top 10 labtest dropdown search input field
    setFreqTestInput(e.target.value);
  };

  useEffect(() => {
    if (isStatusPickedUp) {
      dispatch(
        getFrequentlyOrderedTestForUpdate({
          specimenIds: savedSpecimenIds,
        })
      ).then((res) => {
        setFreqOrderedTest(sortDataAlphabetically(res.payload?.data, "name"));
      });
    } else {
      dispatch(getFrequentlyOrderedTest()).then((res) => {
        //Fetching of top 10 labtest, or frequenlty ordered labtest
        if (res.type === "labtests/get-top-10-labtest/fulfilled") {
          setFreqOrderedTest(sortDataAlphabetically(res.payload?.data, "name"));
        }
      });
    }
    // eslint-disable-next-line
  }, [isStatusPickedUp]);

  useEffect(() => {
    //For displaying loading spinner when labtest dropdown input field is focused
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (active) {
      if (isStatusPickedUp) {
        dispatch(
          getTestsLookUpForUpdate({
            clientId: clientId,
            gender: inputs.gender.toLowerCase(),
            specimenIds: savedSpecimenIds,
          }) //Dispatching of get labtest lookup
        ).then((res) => {
          setTestProfileOptions(
            sortDataAlphabetically(res.payload?.data, "name")
          );
        });
      } else {
        dispatch(
          getTestsLookUp({
            clientId: clientId,
            gender: inputs.gender.toLowerCase(),
          }) //Dispatching of get labtest lookup
        ).then((res) => {
          setTestProfileOptions(
            sortDataAlphabetically(res.payload?.data, "name")
          );
        });
      }
    }

    return () => {
      active = false; //When it unmounts, sets the active to false to unshow loading spinner
    };
    // eslint-disable-next-line
  }, [inputs.gender, clientId, dispatch, loading, isStatusPickedUp]);

  useEffect(() => {
    //Check if the labtest data for lookup is fetched, then the dropdown search input field will be opened
    if (!openTestOptions) {
      setTestProfileOptions([]);
    }
  }, [openTestOptions]);

  const checkIfLabtestIsAdded = (labtestId) => {
    const isLabtestAlreadyAdded = savedLabtests?.savedLabtestsData?.some(
      (data) => data.id === labtestId
    );
    const isLabtestChildOfOtherLabtest = savedLabtests.savedLabtestsData?.some(
      (data) => data?.testIds?.includes(labtestId)
    );

    if (isLabtestAlreadyAdded || isLabtestChildOfOtherLabtest) {
      return true;
    }
    return false;
  };

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={8} xl={8} item>
        <Autocomplete
          disabled={isGenderEmpty}
          loading={loading}
          open={openTestOptions}
          onOpen={handleOpenTestOptions}
          onClose={handleCloseTestOptions}
          noOptionsText={`No labtest were found for your search: ${testInput} `}
          inputValue={testInput !== "" ? testInput : ""}
          value={testInput}
          groupBy={(option) => option.type}
          filterOptions={testProfleFilterOptions}
          disableClearable
          clearOnBlur
          size="small"
          options={testProfileOptions?.sort((a, b) =>
            b?.type?.localeCompare(a?.type)
          )}
          getOptionLabel={(test) => (test.displayName ? test.displayName : "")}
          renderOption={(props, test) => (
            <>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <Popover
                  placement="rightTop"
                  autoAdjustOverflow
                  zIndex={99999}
                  content={
                    <Box>
                      <h4>{test.displayName}</h4>
                    </Box>
                  }
                  title={"Labtest Details"}
                >
                  <li
                    style={{
                      margin: "0.2rem",
                      color: checkIfLabtestIsAdded(test?.id)
                        ? "green"
                        : "black",
                      fontWeight: checkIfLabtestIsAdded(test?.id)
                        ? "bold"
                        : "normal",
                    }}
                    {...props}
                  >
                    {test.displayName?.length > 35
                      ? test?.displayName?.substring(0, 38) + "..."
                      : test?.displayName}
                  </li>
                </Popover>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <li
                  style={{
                    margin: "0.2rem",
                    color: checkIfLabtestIsAdded(test?.id) ? "green" : "black",
                    fontWeight: checkIfLabtestIsAdded(test?.id)
                      ? "bold"
                      : "normal",
                  }}
                  {...props}
                >
                  {test.displayName?.length > 35
                    ? test?.displayName.substring(0, 38) + "..."
                    : test?.displayName}
                </li>
              </Box>
            </>
          )}
          onChange={(event, newInputValue) => {
            handleAddLabtest(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              value={testInput}
              onChange={handleTestChange}
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "30rem",
                  xl: "30rem",
                },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
                borderRadius: "5px",
                outline: "none",
                marginTop: "0.5rem",
                backgroundColor: "white",
              }}
              {...params}
              placeholder="Search tests / profiles..."
              size="small"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <Box sx={{ overflow: "hidden", opacity: 0 }}>
                      {params.InputProps.endAdornment}
                    </Box>
                    <InputAdornment
                      sx={{ transform: "translateX(30px)" }}
                      position="end"
                    >
                      {loading ? (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          sx={{ pointerEvents: "none" }}
                        />
                      ) : (
                        <SearchOutlinedIcon
                          sx={{
                            color: "lightBlue.main",
                            pointerEvents: "none",
                          }}
                        />
                      )}
                    </InputAdornment>
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
        <Autocomplete
          disabled={isGenderEmpty}
          inputValue={freqTestInput !== "" ? freqTestInput : ""}
          value={freqTestInput}
          filterOptions={testProfleFilterOptions}
          disableClearable
          clearOnBlur
          size="small"
          noOptionsText="Empty labtest data"
          options={freqOrderedTest}
          getOptionLabel={(test) => (test.displayName ? test.displayName : "")}
          onChange={(event, newInputValue) => {
            handleAddLabtest(newInputValue);
          }}
          renderOption={(props, test) => (
            <>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <Popover
                  placement="rightTop"
                  autoAdjustOverflow
                  zIndex={99999}
                  content={`${test.displayName}`}
                  title={"Test Order Details"}
                >
                  <li
                    style={{
                      margin: "0.2rem",
                      color: checkIfLabtestIsAdded(test?.id)
                        ? "green"
                        : "black",
                      fontWeight: checkIfLabtestIsAdded(test?.id)
                        ? "bold"
                        : "normal",
                    }}
                    {...props}
                  >
                    {truncateString(test?.displayName)}
                  </li>
                </Popover>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <li
                  style={{
                    margin: "0.2rem",
                    color: checkIfLabtestIsAdded(test?.id) ? "green" : "black",
                    fontWeight: checkIfLabtestIsAdded(test?.id)
                      ? "bold"
                      : "normal",
                  }}
                  {...props}
                >
                  {test.displayName?.length > 27
                    ? test?.displayName.substring(0, 23) + "..."
                    : test?.displayName}
                </li>
              </Box>
            </>
          )}
          renderInput={(params) => (
            <TextField
              onChange={handleFreqTestChange}
              sx={{
                width: { xs: "100%", sm: "100%" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "2px solid",
                    borderColor: "darkBlue.secondary",
                  },
                },
                borderRadius: "5px",
                outline: "none",
                marginTop: "0.5rem",
                backgroundColor: "white",
              }}
              {...params}
              placeholder="Previous order"
              size="small"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <Box sx={{ overflow: "hidden", opacity: 0 }}>
                      {params.InputProps.endAdornment}
                    </Box>
                    <InputAdornment
                      sx={{ transform: "translateX(25px)" }}
                      position="end"
                    >
                      <ArrowBackIosNewRoundedIcon
                        sx={{
                          transform: "rotate(-90deg)",
                          color: "lightBlue.main",
                          pointerEvents: "none",
                        }}
                      />
                    </InputAdornment>
                  </>
                ),
              }}
            />
          )}
        />
        <Typography
          sx={{ fontSize: "0.75rem", fontStyle: "italic" }}
          variant="p"
        >
          Showing 10 most frequently used
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LabtestSearchInputs;
