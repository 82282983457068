import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authReducer } from "store/slice/authSlice";
import { userReducer } from "store/slice/userSlice";
import { clientInfoReducer } from "store/slice/clientInfoSlice";
import { inputReducer } from "store/slice/inputSlice";
import { searchPatientReducer } from "store/slice/searchPatientSlice";
import { patientInfoReducer } from "store/slice/patientInfoSlice";
import { multiStepFormReducer } from "store/slice/multiStepFormInputSlice";
import { healthcareProviderReducer } from "store/slice/healthcareProviderSlice";
import { specialHandlingReducer } from "store/slice/specialHandlingSlice";
import { savedLabtestReducer } from "store/slice/savedLabtestSlice";
import { specimenInputReducer } from "store/slice/specimenInputSlice";
import { savedInputReducer } from "store/slice/savedInputSlice";
import { savedTestInputsInfoReducer } from "store/slice/savedTestInputsInfo";
import { physicianInputReducer } from "store/slice/physicianInputSlice";
import { sampleCollectorInputReducer } from "store/slice/sampleCollectorInputSlice";
import { orderDataReducer } from "store/slice/orderSlice";
import { appBarReducer } from "store/slice/appBarSlice";
import { settingsReducer } from "store/slice/settingsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    searchPatient: searchPatientReducer,
    clientInfo: clientInfoReducer,
    inputs: inputReducer,
    savedInputs: savedInputReducer,
    patientInfo: patientInfoReducer,
    multiStepFormInputs: multiStepFormReducer,
    healthCareProvider: healthcareProviderReducer,
    appBarSettings: appBarReducer,
    settings: settingsReducer,
    specialHandling: specialHandlingReducer,
    specimenInputs: specimenInputReducer,
    savedLabtests: savedLabtestReducer,
    orderData: orderDataReducer,
    savedTestInputsInfo: savedTestInputsInfoReducer,
    physicianInputs: physicianInputReducer,
    sampleCollectorInputs: sampleCollectorInputReducer,
  },
});

setupListeners(store.dispatch);

//SLICE
export { logout, clearMessage } from "store/slice/authSlice";
export { clearMessageUser, refreshUser } from "store/slice/userSlice";
export { setOpen } from "store/slice/settingsSlice";

// AUTH
export { userLogin } from "api/auth/userLogin";
export { userRegister } from "api/auth/userRegister";
export { userForgotPassword } from "api/auth/userForgotPassword";
export { userResetPassword } from "api/auth/userResetPassword";
export { userChangePassword } from "api/auth/userChangePassword";
export { userConfirmEmail } from "api/auth/userConfirmEmail";
export { userResendConfirmEmail } from "api/auth/userResendConfirmEmail";

// USER
export { createUser } from "api/user/createUser";
export { getAllUsers } from "api/user/getAllUser";
export { addUserByRole } from "api/user/addUserByRole";
export { addUserClaims } from "api/user/addUserClaims";
export { deleteUserByRole } from "api/user/deleteUserByRole";
export { updateUser } from "api/user/updateUser";
export { deleteUser } from "api/user/deleteUser";
export { getUserById } from "api/user/getUserById";
export { getUserInfoById } from "api/user/getUserInfoById";
export { getCouriersLookup } from "api/user/getCouriersLookup";

// ROLES
export { getRoles } from "api/rolesClaims/getRoles";
export { createRoles } from "api/rolesClaims/createRoles.js";
export { updateRoles } from "api/rolesClaims/updateRoles.js";
export { deleteRoles } from "api/rolesClaims/deleteRoles.js";

//CLAIMS
export { getAllPermissionsKeys } from "api/rolesClaims/getAllPermissionsKeys.js";
export { getUserPermissions } from "api/rolesClaims/getUserPermissions";
export { getFlattenUserPermissions } from "api/rolesClaims/getFlattenUserPermissions";
export { createClaims } from "api/rolesClaims/createClaims";
export { getClaims } from "api/rolesClaims/getClaims";

//CLIENT
export { clientLookUp } from "api/client/clientLookUp";
export { getAllClient } from "api/client/getAllClient";
export { getClientInfo } from "api/client/getClientInfo";
export { createClient } from "api/client/createClient";
export { updateClient } from "api/client/updateClient";
export { deleteClient } from "api/client/deleteClient";
export { getClientInfoById } from "api/client/getClientInfoById";

//PATIENTS
export { getAllPatient } from "api/patient/getAllPatient";
export { createPatient } from "api/patient/createPatient";
export { updatePatient } from "api/patient/updatePatient";
export { getPatientInfo } from "api/patient/getPatientInfo";
export { getPatientLookup } from "api/patient/getPatientLookup";
export { checkPatientExistingOrder } from "api/patient/checkPatientExistingOrder";

//PROVINCES
export { getAllProvinces } from "api/provinces/getAllProvinces";
export { getProvinceLookUp } from "api/provinces/getProvinceLookUp";
export { createProvince } from "api/provinces/createProvince";
export { updateProvince } from "api/provinces/updateProvince";
export { deleteProvince } from "api/provinces/deleteProvince";

//CITY-MUNICIPALITIES
export { getAllCityMunicipalities } from "api/cityMunicipalities/getAllCityMunicipalities";
export { getCityMunicipalitiesLookUp } from "api/cityMunicipalities/getCityMunicipalitiesLookUp";
export { getCityMunicipalities } from "api/cityMunicipalities/getCityMunicipalities";
export { createCityMunicipalities } from "api/cityMunicipalities/createCityMunicipalities";
export { updateCityMunicipalities } from "api/cityMunicipalities/updateCityMunicipalities";
export { deleteCityMunicipalities } from "api/cityMunicipalities/deleteCityMunicipalities";

//BARANGAYS
export { getBarangayLookUp } from "api/barangay/getBarangayLookUp";
export { getAllBarangay } from "api/barangay/getAllBarangay";
export { createBarangay } from "api/barangay/createBarangay";
export { updateBarangay } from "api/barangay/updateBarangay";
export { deleteBarangay } from "api/barangay/deleteBarangay";

// LAB TESTS
export { getAllLabTest } from "api/labtest/getAllLabTest";
export { getLaboratoryTestType } from "api/labtest/getLaboratoryTestType";
export {
  getTestsLookUp,
  getTestsLookUpForUpdate,
} from "api/labtest/getTestsLookUp";
export { getLabTestRequirements } from "api/labtest/getLabTestRequirements";
export {
  getFrequentlyOrderedTest,
  getFrequentlyOrderedTestForUpdate,
} from "api/labtest/getFrequentlyOrderedTest";
export { getLabTestSpecimenTypes } from "api/labtest/getLabTestSpecimenTypes";
export { createLabTest } from "api/labtest/createLabTest";
export { getLaboratoryTest } from "api/labtest/getLaboratoryTest";
export { updateLabTest } from "api/labtest/updateLabTest";
export {
  hasSecondAuthorityDeleteLabtest,
  withoutSecondAuthorityDeleteLabtest,
} from "api/labtest/deleteLabTest";
export { getLaboratoryTestMeasurementType } from "api/labtest/getLaboratoryTestMeasurementType";
export { getLaboratoryTestSpecimenSize } from "api/labtest/getLaboratoryTestSpecimenSize";

// LAB DEPT
export { getLabDeptLookUp } from "api/labDepartments/getLabDeptLookUp";
export { getAllLabDept } from "api/labDepartments/getAllLabDept";
export { createLabDepartment } from "api/labDepartments/createLabDepartment";
export { updateLabDepartment } from "api/labDepartments/updateLabDepartment";
export { deleteLabDepartment } from "api/labDepartments/deleteLabDepartment";

// CLIENT-TYPES
export { getAllClientType } from "api/clientType/getAllClientType";
export { createClientType } from "api/clientType/createClientType";
export { updateClientType } from "api/clientType/updateClientType";
export { deleteClientType } from "api/clientType/deleteClientType";

// HEALTHCARE-PROVIDER
export { getAllHealthcareProvider } from "api/healthCareProvider/getAllHealthcareProvider";
export { getHealthcareProvidersLookUp } from "api/healthCareProvider/getHealthcareProvidersLookUp";
export { createHealthcareProvider } from "api/healthCareProvider/createHealthcareProvider";
export { getAllPhysicians } from "api/healthCareProvider/getAllPhysicians";
export { updateHealthcareProvider } from "api/healthCareProvider/updateHealthcareProvider";
export { getSpecificPhysician } from "api/healthCareProvider/getSpecificPhysician";
export { deleteHealthcareProvider } from "api/healthCareProvider/deleteHealthcareProvider";

// COUNTRIES
export { getAllCountries } from "api/countries/getAllCountries";
export { getCountryLookUp } from "api/countries/getCountryLookUp";

// ICD10-CODE
export { getIcd10CodesLookUp } from "api/icd10Codes/getIcd10CodesLookUp";
export { getAllICD10Codes } from "api/icd10Codes/getAllICD10Codes";
export { createICD10Codes } from "api/icd10Codes/createICD10Codes";
export { updateICD10Codes } from "api/icd10Codes/updateICD10Codes";
export { deleteICD10Codes } from "api/icd10Codes/deleteICD10Codes";

// SPECIAL HANDLING
export { getSpecialHandlingLookUp } from "api/specialInstruction/getSpecialHandlingLookUp";
export { createSpecialHandling } from "api/specialInstruction/createSpecialHandling";
export { createCountry } from "api/countries/createCountry";
export { updateCountry } from "api/countries/updateCountry";
export { deleteCountry } from "api/countries/deleteCountry";

// LOGS
export { getAllLogs } from "api/logs/getAllLogs";
export { getLogs } from "api/logs/getLogs";

// SAMPLE COLLECTORS
export { getSampleCollectorLookUp } from "api/sampleCollectors/getSampleCollectorLookUp";
export { getAllSampleCollector } from "api/sampleCollectors/getAllSampleCollector";
export { createSampleCollector } from "api/sampleCollectors/createSampleCollector";
export { updateSampleCollector } from "api/sampleCollectors/updateSampleCollector";
export { requestUpdateSampleCollector } from "api/sampleCollectors/updateSampleCollector";
export { deleteSampleCollector } from "api/sampleCollectors/deleteSampleCollector";
export { getSpecificSampleCollector } from "api/sampleCollectors/getSpecificSampleCollector";
export { getSampleCollectorTypes } from "api/sampleCollectors/getSampleCollectorTypes";

// TEST ORDERS
export { createTestOrder } from "api/testOrder/createTestOrder";
export { createLrfPickupRequest } from "api/testOrder/createLrfPickupRequest";
export { updateTestOrder } from "api/testOrder/updateTestOrder";
export { cancelTestOrder } from "api/testOrder/cancelTestOrder";
export { findDuplicateTestOrder } from "api/testOrder/findDuplicateTestOrder";
export { getAllTestOrder } from "api/testOrder/getAllTestOrder";
export { getTestOrderByClientId } from "api/testOrder/getTestOrderByClientId";
export { getTestOrderByPatientId } from "api/testOrder/getTestOrderByPatientId";
export { getPatientTestOrderById } from "api/testOrder/getPatientTestOrderById";
export { getAllTestOrderTransportStatus } from "api/testOrder/getAllTestOrderTransportStatus";
export { getOrderSessionSummary } from "api/testOrder/getOrderSessionSummary";
export { getTestOrderById } from "api/testOrder/getTestOrderById";
export { getTestOrderAuditTrail } from "api/testOrder/getTestOrderAuditTrail";
export { getTestOrderSummary } from "api/testOrder/getTestOrderSummary";
export { getTestOrderByOrderNo } from "api/testOrder/getTestOrderByOrderNo";
export { getLabWorkloads } from "api/testOrder/getLabWorkloads";
export { updateOrderPickup } from "api/testOrder/updateOrderPickup";
export { updateOrderLabStatus } from "api/testOrder/updateOrderLabStatus";
export { emailPatientTestOrder } from "api/testOrder/emailPatientTestOrder";
export { updateTestOrderNotes } from "api/testOrder/updateTestOrderNotes";

// PH ADDRESS
export { getPhilippineAddressTypes } from "api/philippineAddresses/getPhilippineAddressTypes";

// SPECIMEN
export { getSpecimen } from "api/specimen/getSpecimen";
export { getAllSpecimen } from "api/specimen/getAllSpecimen";
export { createSpecimen } from "api/specimen/createSpecimen";
export { deleteSpecimen } from "api/specimen/deleteSpecimen";
export { updateSpecimen } from "api/specimen/updateSpecimen";

//QR CODE REGISTRATION
export { createQrCode } from "api/qrRegistration/createQrCode";
export { getQrCodeById } from "api/qrRegistration/getQrCodeById";

//ATTACHMENTS
export { uploadFile } from "api/attachments/uploadFile";
export { downloadFile } from "api/attachments/downloadFile";

//SUPPLY ORDER
export { getInventoryItemsLookup } from "api/supplyOrders/getInventoryItemsLookup";
export { createSupplyOrder } from "api/supplyOrders/createSupplyOrder";
export { cancelSupplyRequest } from "api/supplyOrders/cancelSupplyRequest";
export { getAllSupplyRequest } from "api/supplyOrders/getAllSupplyRequest";
export { getSupplyRequestById } from "api/supplyOrders/getSupplyRequestById";
export { getSupplyRequestAuditTrail } from "api/supplyOrders/getSupplyRequestAuditTrail";

//TEST ORDER ENCRYPTION
export { encryptTestOrder } from "api/encryption/encryptTestOrder";
//TEST ORDER DECRYPTION
export { decryptTestOrder } from "api/decryption/decryptTestOrder";

// EXTERNAL SYSTEM CREDENTIALS
export { authenticateExternalSystemCredential } from "api/externalSystemCredentials/externalSystemCredentials";
