import { Box, Grid, Typography } from "@mui/material";
import styles from "./styles.module.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TestProfileSectionTwo from "./testProfileSectionTwo";
import { findDuplicateTestOrder } from "store";
import { handleChangeOrderData } from "store/slice/orderSlice";
import notification from "components/mnl/global/openNotification";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import { handleChangeSavedTestInfo } from "store/slice/savedTestInputsInfo";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import QRCode from "react-qr-code";
import PopConfirm from "components/mnl/global/popConfirm";

const TestProfileSection = ({ printTestOrder }) => {
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const savedTestInputsInfo = useSelector((state) => state.savedTestInputsInfo);
  const orderData = useSelector((state) => state.orderData);
  const testOrderMinLength = multiStepFormInputs?.testOrderDetails?.length >= 4;
  const dispatch = useDispatch();

  const description = (
    <div>
      Canceling will remove the test profile from the list. <br />
      Are you sure?
    </div>
  );

  const handleFindDuplicateTestOrder = () => {
    const testOrderIds = multiStepFormInputs.testOrderDetails.map(
      (test) => test.testId
    );
    dispatch(
      findDuplicateTestOrder({
        patientId: multiStepFormInputs.patientId,
        testIds: testOrderIds,
        testOrderId: orderData.selectedTestOrderId,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        dispatch(
          handleChangeOrderData({
            duplicatedTestOrderData: res?.payload?.data,
          })
        );
      }
    });
  };

  const handleRemoveLabtest = (selectedData) => {
    const isSingleLabtestWillBeRemoved =
      multiStepFormInputs.testOrderDetails.length === 1;

    if (isSingleLabtestWillBeRemoved) {
      dispatch(
        handleChangeOrderData({
          orderAgain: true,
        })
      );
      notification.warning({
        message: "Empty Laboratory Test",
        description:
          "Please select laboratory test before submitting test order",
      });
    }

    const filteredTestArr = savedLabtests.savedLabtestsData.filter(
      (profile) => profile.id !== selectedData.id
    );
    const filteredSavedTestDatas =
      savedLabtests.savedLabtestsIdAndSpecimen.filter(
        (data) => data.testId !== selectedData.id
      );
    dispatch(
      handleChangeLabtestData({
        savedLabtestsIdAndSpecimen: filteredSavedTestDatas,
        savedLabtestsData: filteredTestArr,
      })
    );
    const filteredTestOrderDetails =
      multiStepFormInputs.testOrderDetails.filter(
        (order) => order.testId !== selectedData.id
      );
    const filteredRequirementDetails =
      savedTestInputsInfo.savedRequirementDetails.filter(
        (info) => info.id !== selectedData.id
      );
    const filteredSavedTestInfo = savedTestInputsInfo.savedTestInfo.filter(
      (test) => test.id !== selectedData.id
    );

    dispatch(
      handleChangeSavedTestInfo({
        savedTestInfo: filteredSavedTestInfo,
        savedRequirementDetails: filteredRequirementDetails,
      })
    );

    const specimensToRemove = multiStepFormInputs.patientSpecimens.filter(
      (data) => {
        const isSpecimenPresentInOtherTest =
          savedLabtests.savedLabtestsData.some(
            (test) =>
              test !== selectedData && test.specimen.includes(data.specimen)
          );
        return !isSpecimenPresentInOtherTest;
      }
    );
    const filteredSpecimens = multiStepFormInputs.patientSpecimens.filter(
      (data) =>
        !specimensToRemove.some(
          (specimen) => specimen.specimen === data.specimen
        )
    );
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: filteredSpecimens,
        testOrderDetails: filteredTestOrderDetails,
      })
    );
  };

  const getQrCodeSize = () => {
    if (testOrderMinLength) {
      return 450;
    }
    return 150;
  };

  useEffect(() => {
    handleFindDuplicateTestOrder();
    // eslint-disable-next-line
  }, [multiStepFormInputs.testOrderDetails, savedLabtests.savedLabtestsData]);

  return (
    <Grid container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          sx={{
            color: "darkBlue.main",
            marginBottom: "0.5rem",
            marginTop: "1rem",
            fontSize: "1.4rem",
            fontWeight: "semibold",
          }}
          variant="h4"
        >
          Tests / Profiles Selection
        </Typography>
        <p className={styles.divider} />
      </Grid>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          width: "100%",
          justfiyContent: testOrderMinLength ? "center" : "start",
          alignItems: testOrderMinLength ? "center" : " start",
        }}
      >
        <Grid xs={11} sm={11} md={11} lg={11} xl={11} item>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: printTestOrder
                ? "29fr 70fr 350fr auto"
                : {
                    xs: "69fr 150fr 400fr auto",
                    sm: "69fr 150fr 400fr auto",
                    md: "33fr 70fr 400fr auto",
                    lg: "33fr 70fr 400fr auto",
                    xl: "33fr 70fr 400fr auto",
                  },
              marginTop: "1rem",
            }}
          >
            <Typography
              variant="p"
              sx={{
                fontWeight: "500",
                color: "darkBlue.main",
              }}
            >
              No.
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontWeight: "500",
                color: "darkBlue.main",
              }}
            >
              Test code
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontWeight: "500",
                color: "darkBlue.main",
              }}
            >
              Test name
            </Typography>
            <button
              style={{
                display: !orderData.successfullyCreatedOrder ? "block" : "none",
              }}
              onClick={() =>
                dispatch(
                  handleChangeOrderData({
                    orderAgain: true,
                  })
                )
              }
              type="button"
              className={styles["addtest-btn"]}
            >
              Add test
            </button>
          </Box>
          <Box
            sx={{
              backgroundColor: "grayishBlue.main",
              paddingBottom: "1rem",
              position: "relative",
              marginTop: "0.5rem",
              display:
                savedLabtests?.savedLabtestsData.length === 0
                  ? "none"
                  : "block",
            }}
          >
            {savedLabtests?.savedLabtestsData &&
              savedLabtests?.savedLabtestsData.map((data, index) => (
                <Box
                  key={data.id}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: printTestOrder
                      ? "26fr 70fr 350fr auto"
                      : {
                          xs: "45fr 120fr 400fr auto",
                          sm: "45fr 120fr 400fr auto",
                          md: "26fr 65fr 400fr auto",
                          lg: "26fr 65fr 400fr auto",
                          xl: "26fr 65fr 400fr auto",
                        },
                    padding: "0.2rem 0.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {data.code}
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                    variant="p"
                  >
                    {data.name}
                  </Typography>
                  <PopConfirm
                    title="Remove the test profile"
                    description={description}
                    onConfirm={() => handleRemoveLabtest(data)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <CloseRoundedIcon
                      sx={{
                        color: "red",
                        fontSize: "1.5rem",
                        position: "absolute",
                        right: 0,
                        cursor: "pointer",
                        transform: "translateX(22px)",
                        display:
                          orderData.successfullyCreatedOrder ||
                          (data.forUpdate &&
                            orderData.orderStatus.toLowerCase() === "pickedup")
                            ? "none"
                            : "block",
                      }}
                    />
                  </PopConfirm>
                </Box>
              ))}
          </Box>
        </Grid>
        {orderData.successfullyCreatedOrder && (
          <QRCode
            style={{ marginLeft: "auto" }}
            size={getQrCodeSize()}
            value={JSON.stringify(orderData.encryptedTestOrderData)}
          />
        )}
      </Box>

      <TestProfileSectionTwo printTestOrder={printTestOrder} />
    </Grid>
  );
};

export default TestProfileSection;
