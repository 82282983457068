import { Box } from "@mui/material";
import React from "react";
import FirstSection from "./firstSection";

export const ThirdStep = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: {
          xs: "0.3rem",
          sm: "0.3rem",
          md: "0.9rem",
          lg: "2rem",
          xl: "2rem",
        },
        height: {
          xs: "100%",
          sm: "100%",
          md: "100%",
          lg: "100%",
          xl: "100%",
        },
      }}
    >
      <FirstSection />
    </Box>
  );
};
