import { Box, Grid, Skeleton, Typography } from "@mui/material";
import styles from "components/mnl/testRequest/forms/fourthStep/styles.module.css";

const BillingInformation = (props) => {
  const { clientDetails, isLoading } = props;
  const currentYear = new Date(Date.now()).getFullYear();

  const billingInfo = [
    {
      id: 1,
      label: "Payor",
      value: `${clientDetails?.clientName || "RS Example Name"}`,
    },
    {
      id: 2,
      label: "Invoice period",
      value: `Month ${currentYear}`,
    },
  ];

  return (
    <Grid container>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
          <Typography
            sx={{
              color: "darkBlue.main",
              marginBottom: "0.5rem",
              marginTop: "1.5rem",
              fontSize: "1.4rem",
              fontWeight: "semibold",
            }}
            variant="h4"
          >
            Billing Information
          </Typography>
          <p className={styles.divider} />
        </Grid>
      </Grid>
      <Grid
        sx={{ marginTop: "1.5rem" }}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid xs={12} sm={12} md={5} lg={5} xl={5}>
          <Typography
            variant="p"
            sx={{ fontWeight: "500", color: "darkBlue.main" }}
          >
            Bill to client
          </Typography>
          <Box className={styles["grid-container"]}>
            {billingInfo.map((info) => (
              <Box key={info.id} className={styles["grid-item"]}>
                <span className={styles.label}>{info.label}</span>
                <span className={styles.colon}>:</span>
                <span className={styles.value}>
                  {isLoading ? <Skeleton /> : info.value}
                </span>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillingInformation;
