import { Modal, Result } from "antd";
import React from "react";

const ResultComponent = ({
  status,
  title,
  subTitle,
  buttons,
  openModal,
  modalWidth,
  onCancel,
}) => {
  return (
    <Modal
      width={modalWidth}
      open={openModal}
      centered
      onCancel={onCancel}
      footer={false}
      closable
    >
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={buttons}
      />
    </Modal>
  );
};

export default ResultComponent;
