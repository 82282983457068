import * as React from "react";
import { Modal, Form, Button, Popconfirm } from "antd";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import "./createUpdateClient.css";
import dayjs from "dayjs";
import useCheckPermission from "hooks/useCheckPermission";
import {
  updateClient,
  createClient,
  deleteClient,
  getClientInfoById,
} from "store";
import notification from "components/mnl/global/openNotification";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import decodedUserDetails from "utils/decodedUserDetails";
import FormInputTabs from "./formInputTabs";

const CreateUpdateClient = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
    clientTypeData,
    addressType,
    barangay,
    cityMunicipality,
    province,
    country,
  } = props;
  const { handleVerifyPermission } = useCheckPermission();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isProcessingWindowTimeEnabled = Form.useWatch(
    "enableProcessingWindowTime",
    form
  );
  const [loading, setLoading] = React.useState(false);
  const [filteredProvince, setFilteredProvince] = React.useState([]);
  const [filteredBarangay, setFilteredBarangay] = React.useState([]);
  const [filteredCityMunicipality, setFilteredCityMunicipality] =
    React.useState([]);
  const [selectedAddressIds, setSelectedAddressIds] = React.useState({
    country: null,
    provinceId: null,
    barangayId: null,
    cityMunicipalityId: null,
  });
  const isAuthorizedToDelete = handleVerifyPermission(
    "Administration:Client:Delete"
  );
  const [clientStartDateValue, setClientStartDateValue] = React.useState(
    dayjs()
  );
  const [invalidTabInputs, setInvalidTabInputs] = React.useState({
    configurationInputsHasError: false,
    informationInputsHasError: false,
  });

  const LoadUpdateData = React.useCallback(() => {
    form.setFieldsValue({
      code: idForUpdate.clientCode,
      name: idForUpdate.clientName,
      type: idForUpdate.clientType_ID,
      contactName: idForUpdate.contactName,
      contactNumber: idForUpdate.contactNumber,
      inClientProcessingWindowTime: idForUpdate.inClientProcessingWindowTime,
      clientStartDate: dayjs(idForUpdate.clientStartDate),
      clientEndDate:
        idForUpdate.clientEndDate === null
          ? ""
          : dayjs(idForUpdate.clientEndDate),
      registeredUserCap: idForUpdate.registeredUserCap,
      enableCollectionSite: idForUpdate.enableCollectionSite,
      enableVolumeCheck: idForUpdate.enableVolumeCheck,
      allowEmailSendingOfRequest: idForUpdate.allowEmailSendingOfRequest,
      enableProcessingWindowTime: idForUpdate.enableProcessingWindowTime,
      isActive: Boolean(idForUpdate.isActive),
      phAddresses: idForUpdate.phAddresses,
    });
    setClientStartDateValue(idForUpdate.clientStartDate);
  }, [form, idForUpdate]);

  React.useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const saveClientInfoInLocalStorage = () => {
    dispatch(getClientInfoById({ id: decodedUserDetails()?.clientId })).then(
      (val) => {
        const isFetchSuccessful = val?.payload?.success;
        if (isFetchSuccessful) {
          localStorage.setItem("clInf", JSON.stringify(val.payload.data));
        }
      }
    );
  };

  const isInputEmpty = (inputValue) => {
    if (inputValue === "" || inputValue === undefined || inputValue === null) {
      return true;
    }
    return false;
  };

  const onFinish = (values) => {
    const filteredPhAddresses = values?.phAddresses?.map((address) => ({
      ...address,
      type: values.addressType === "" ? null : values.addressType,
      houseBuildingNumber:
        address.houseBuildingNumber === "" ? null : address.houseBuildingNumber,
      postalCode: address.postalCode === "" ? null : address.postalCode,
      streetName: address.streetName === "" ? null : address.streetName,
      cityMunicipalityId:
        address.cityMunicipalityId === undefined
          ? null
          : address.cityMunicipalityId,
      barangayId: address.barangayId === undefined ? null : address.barangayId,
    }));

    const isPhAddressesEmpty =
      values?.phAddresses === undefined && values?.phAddresses?.length === 0;

    if (isForUpdate) {
      if (updateClient.pending().type === "clients/update-client/pending") {
        setLoading(true);
      }
      dispatch(
        updateClient([
          values,
          {
            clientStartDate: dayjs(values.clientStartDate.$d).format(),
            clientEndDate:
              values.clientEndDate === "" || values.clientEndDate === undefined
                ? null
                : dayjs(values.clientEndDate.$d).format(),
            phAddresses: isPhAddressesEmpty ? null : filteredPhAddresses,
          },
          idForUpdate.id,
        ])
      ).then((val) => {
        if (val.payload.success) {
          if (idForUpdate.id === decodedUserDetails()?.clientId) {
            saveClientInfoInLocalStorage();
          }
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Updated Client",
            description: `Successfully updated ${values.name}`,
          });
          handleCloseModal();
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to Update Client",
            description: errorMessagesDescription(
              val?.payload?.response?.data?.errorMessages
            ),
          });
        }
      });
    } else {
      if (createClient.pending().type === "clients/create-client/pending") {
        setLoading(true);
      }
      dispatch(
        createClient([
          values,
          {
            clientStartDate: dayjs(values.clientStartDate.$d).format(),
            clientEndDate:
              values.clientEndDate === "" || values.clientEndDate === undefined
                ? null
                : dayjs(values.clientEndDate.$d).format(),
            phAddresses: isPhAddressesEmpty ? null : filteredPhAddresses,
          },
        ])
      ).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created Client",
            description: `Successfully created ${values.name}`,
          });
          handleCloseModal();
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to Create Client",
            description: errorMessagesDescription(
              val?.payload?.response?.data?.errorMessages
            ),
          });
        }
      });
    }
  };

  const onFinishFailed = (values) => {
    const invalidInformationInputValues =
      isInputEmpty(values.code) ||
      isInputEmpty(values.name) ||
      isInputEmpty(values.type) ||
      isInputEmpty(values.contactName) ||
      isInputEmpty(values.contactNumber) ||
      isInputEmpty(values.clientStartDate);
    const invalidConfigurationInputValues =
      isInputEmpty(values.registeredUserCap) ||
      (values.enableProcessingWindowTime &&
        isInputEmpty(values.inClientProcessingWindowTime));
    const bothHasInvalidInputValues =
      invalidInformationInputValues && invalidConfigurationInputValues;

    if (invalidInformationInputValues) {
      setInvalidTabInputs({
        ...invalidTabInputs,
        informationInputsHasError: true,
      });
    }
    if (invalidConfigurationInputValues) {
      setInvalidTabInputs({
        ...invalidTabInputs,
        configurationInputsHasError: true,
      });
    }
    if (bothHasInvalidInputValues) {
      setInvalidTabInputs({
        configurationInputsHasError: true,
        informationInputsHasError: true,
      });
    }
  };

  const onValuesChange = (values) => {
    const filledInformationInputs =
      !isInputEmpty(values.code) &&
      !isInputEmpty(values.name) &&
      !isInputEmpty(values.type) &&
      !isInputEmpty(values.contactName) &&
      !isInputEmpty(values.contactNumber) &&
      !isInputEmpty(values.clientStartDate);
    const filledConfigurationInputs =
      !isInputEmpty(values.registeredUserCap) ||
      (values.enableProcessingWindowTime &&
        !isInputEmpty(values.inClientProcessingWindowTime));
    const bothInputsHasFilled =
      filledInformationInputs && filledConfigurationInputs;
    if (filledInformationInputs) {
      setInvalidTabInputs({
        ...invalidTabInputs,
        informationInputsHasError: false,
      });
    }
    if (filledConfigurationInputs) {
      setInvalidTabInputs({
        ...invalidTabInputs,
        configurationInputsHasError: false,
      });
    }
    if (bothInputsHasFilled) {
      setInvalidTabInputs({
        informationInputsHasError: false,
        configurationInputsHasError: false,
      });
    }
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (deleteClient.pending().type === "clients/delete-client/pending") {
      setLoading(true);
    }
    dispatch(deleteClient(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Client",
          description: `Successfully deleted ${idForUpdate.name}`,
        });
      }
      if (!val.payload?.success) {
        setLoading(false);
        notification.error({
          message: "Failed to Delete Client",
          description: val?.payload?.errorMessages[0],
        });
      }
    });
  };

  const optionType = clientTypeData.map((element) => {
    return { value: element.id, label: element?.typeName };
  });

  const optionAddressType = addressType.map((element) => {
    return { value: element?.key, label: element?.value };
  });

  const handleSelectCountry = (event) => {
    setSelectedAddressIds({
      ...selectedAddressIds,
      country: event,
    });

    setFilteredProvince(
      province.filter((element) => element.countryId === event)
    );
  };

  const handleSelectProvince = (event) => {
    setSelectedAddressIds({
      ...selectedAddressIds,
      provinceId: event,
    });

    setFilteredCityMunicipality(
      cityMunicipality.filter((element) => element.provinceId === event)
    );
  };

  const handleSelectCityMunicipality = (event) => {
    setSelectedAddressIds({
      ...selectedAddressIds,
      cityMunicipalityId: event,
    });

    setFilteredBarangay(
      barangay.filter((element) => element.cityMunicipalityId === event)
    );
  };

  const countryOptions = country.map((element) => {
    return { value: element?.id, label: element?.name };
  });

  const provinceOptions = filteredProvince.map((element) => {
    return { value: element?.id, label: element?.name };
  });

  const cityMunicipalityOptions = filteredCityMunicipality.map((element) => {
    return { value: element?.id, label: element?.name };
  });

  const barangayOptions = filteredBarangay.map((element) => {
    return { value: element?.id, label: element?.name };
  });

  const handleCloseModal = () => {
    handleCancel();
    clearFormInputs();
  };

  const clearFormInputs = () => {
    form.setFieldsValue({
      code: "",
      name: "",
      type: "",
      contactName: "",
      contactNumber: "",
      registeredUserCap: "",
      inClientProcessingWindowTime: "",
      clientStartDate: dayjs(),
      clientEndDate: "",
      allowEmailSendingOfRequest: false,
      enableCollectionSite: false,
      enableVolumeCheck: false,
      enableProcessingWindowTime: false,
      phAddresses: [],
      isActive: true,
    });
    setClientStartDateValue("");
  };

  return (
    <Modal
      open={openCreateUpdate}
      title={isForUpdate ? "Update Client" : "Create Client"}
      onCancel={handleCloseModal}
      footer={[
        <Button
          form="client"
          loading={loading}
          type="primary"
          htmlType="submit"
          style={{ margin: 2 }}
          className="submitBtn"
        >
          Submit
        </Button>,
        isAuthorizedToDelete && isForUpdate ? (
          <Popconfirm
            title="Delete the client"
            description="Are you sure to delete?"
            onConfirm={handleClickDelete}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ loading: loading, className: "submitBtn" }}
          >
            <Button loading={loading} type="primary" danger>
              Delete
            </Button>
          </Popconfirm>
        ) : null,
      ]}
      width={600}
      style={{ position: "relative" }}
    >
      <Box sx={{ height: "27rem", overflowY: "scroll" }}>
        <Form
          name="client"
          initialValues={{
            clientStartDate: dayjs(),
            registeredUserCap: 10,
          }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          onValuesChange={(_, allValues) => onValuesChange(allValues)}
          onFinishFailed={(data) => onFinishFailed(data.values)}
        >
          <FormInputTabs
            isForUpdate={isForUpdate}
            loading={loading}
            optionType={optionType}
            setClientStartDateValue={setClientStartDateValue}
            clientStartDateValue={clientStartDateValue}
            optionAddressType={optionAddressType}
            countryOptions={countryOptions}
            handleSelectCountry={handleSelectCountry}
            selectedAddressIds={selectedAddressIds}
            provinceOptions={provinceOptions}
            handleSelectProvince={handleSelectProvince}
            cityMunicipalityOptions={cityMunicipalityOptions}
            handleSelectCityMunicipality={handleSelectCityMunicipality}
            barangayOptions={barangayOptions}
            isProcessingWindowTimeEnabled={isProcessingWindowTimeEnabled}
            configurationInputsHasError={
              invalidTabInputs.configurationInputsHasError
            }
            informationInputsHasError={
              invalidTabInputs.informationInputsHasError
            }
          />
        </Form>
      </Box>
    </Modal>
  );
};

export default CreateUpdateClient;
