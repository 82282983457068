import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getClientInfo } from "store";
import decodedUserDetails from "utils/decodedUserDetails";

const FirstSection = () => {
  const clientId = decodedUserDetails()?.clientId;
  const currentYear = new Date(Date.now()).getFullYear();
  const [clientDetails, setClientDetails] = useState({});
  const dispatch = useDispatch();

  //Fetching of client details
  useEffect(() => {
    dispatch(getClientInfo()).then((res) => {
      if (res.type === "client/get-client-info/fulfilled") {
        setClientDetails(res.payload.data);
      }
    });
  }, [clientId, dispatch]);

  return (
    <Grid
      sx={{
        paddingBottom: "1rem",
        paddingX: { xs: "0.5rem", sm: "0.5rem", md: "0", lg: "0", xl: "0" },
      }}
      container
    >
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          variant="p"
          sx={{ fontWeight: "bold", color: "darkBlue.main" }}
        >
          Bill to client
        </Typography>
      </Grid>
      <Grid
        sx={{ marginTop: "1.5rem" }}
        rowSpacing={2}
        columnSpacing={5}
        container
      >
        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
          xs={12}
          sm={12}
          md={7}
          lg={5}
          xl={5}
          item
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          >
            Client no.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              backgroundColor: "grayishBlue.secondary",
              padding: "0.3rem",
              width: "max-content",
              color: "black",
              marginTop: "0.3rem",
              fontSize: {
                xs: "1.3rem",
                sm: "1.3rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
            }}
          >
            {clientDetails?.clientCode}
          </Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
          xs={12}
          sm={12}
          md={5}
          lg={4}
          xl={4}
          item
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          >
            Payor
          </Typography>
          <Typography
            variant="h5"
            sx={{
              backgroundColor: "grayishBlue.secondary",
              padding: "0.3rem",
              color: "black",
              width: "max-content",
              marginTop: "0.3rem",
              fontSize: {
                xs: "1.3rem",
                sm: "1.3rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
              maxWidth: "100%",
            }}
          >
            {clientDetails?.clientName || "RS Example Name"}
          </Typography>
        </Grid>
        <Grid
          sx={{ display: "flex", flexDirection: "column" }}
          xs={12}
          sm={12}
          md={12}
          lg={3}
          xl={3}
          item
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", color: "darkBlue.main" }}
          >
            Invoice Period
          </Typography>
          <Typography
            variant="h5"
            sx={{
              backgroundColor: "grayishBlue.secondary",
              padding: "0.3rem",
              width: "max-content",
              color: "black",
              marginTop: "0.3rem",
              fontSize: {
                xs: "1.3rem",
                sm: "1.3rem",
                md: "1.5rem",
                lg: "1.5rem",
                xl: "1.5rem",
              },
            }}
          >
            Month {currentYear}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FirstSection;
