import { Box } from "@mui/material";
import { Modal, Tag } from "antd";
import React from "react";

const LabWorkloadDetailsModal = ({ orderedByClientsData, open, onCancel }) => {
  const orderByClientsDataLength = orderedByClientsData?.length;
  const enableModalScrolling = orderByClientsDataLength >= 10;
  const modalTitle = `Ordered by Clients (${orderByClientsDataLength})`;

  const renderClientName = (text) => {
    const splittedClientName = text.split("-");
    // eslint-disable-next-line
    const clientCode = splittedClientName[0].replace(/[\[\]]/g, "");
    const clientName = splittedClientName.slice(1).toString().replace(",", "-");

    return (
      <div className="grid grid-cols-[0.24fr,1fr] grid-flow-col mb-1">
        <Tag className="text-center font-bold" color="success">
          {clientCode}
        </Tag>{" "}
        <p className="font-bold">{clientName}</p>
      </div>
    );
  };

  return (
    <Modal title={modalTitle} open={open} onCancel={onCancel} footer={null}>
      <Box
        sx={{
          my: 2,
          height: enableModalScrolling ? "26rem" : "auto",
          overflowY: enableModalScrolling ? "scroll" : "auto",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {orderedByClientsData.map((text) => (
            <div>{renderClientName(text)}</div>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default LabWorkloadDetailsModal;
