import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTestsLookUp } from "store";
import styles from "./styles.module.css";
import moment from "moment";
import ProfilePanelPackageLabReq from "./profilePanelPackageLabReq";
import useAdditionalInfoFunctions from "./useAdditionalInfoFunctions";
import decodedUserDetails from "utils/decodedUserDetails";
import { handleChangeSavedTestInfo } from "store/slice/savedTestInputsInfo";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import notification from "components/mnl/global/openNotification";
import getSpecimenRecursively from "components/mnl/testRequest/proxies/getSpecimenRecursively";
import getLabtestIdRecursively from "components/mnl/testRequest/proxies/getLabtestIdRecursively";
import getDuplicatedSpecimenNames from "components/mnl/testRequest/proxies/getDuplicatedSpecimenNames";
import { handleChangeLabtestData } from "store/slice/savedLabtestSlice";
import ResultComponent from "components/mnl/global/resultComponent";

const ProfilePanelPackageSpecimenSelectionModal = ({
  specimenOptions,
  selectedTestDatas,
  inputValues,
  setInputValues,
  fetchedInputFields,
  handleCloseMultiLabRequirementsModal,
  handleCloseMultiSpecimenSelectorModal,
  handleCloseAdditionalPatientInfoModal,
}) => {
  const [selectedSpecimens, setSelectedSpecimens] = useState([]);
  const [labTestNames, setLabTestNames] = useState([]);
  const inputs = useSelector((state) => state.inputs);
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const savedTestInputsInfo = useSelector((state) => state.savedTestInputsInfo);
  const orderData = useSelector((state) => state.orderData);
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const dispatch = useDispatch();
  const specimenOptionsCount = specimenOptions?.length;
  const showLabReqInputFields = fetchedInputFields?.length > 0;
  const showSpecimenCheckbox = specimenOptionsCount > 1;
  const {
    handleSelectSpecimen,
    getNestedLabtestIdAndSpecimen,
    getNestedPatientSpecimensData,
    getNestedTestOrderDetails,
    getLabtestData,
  } = useAdditionalInfoFunctions();

  useEffect(() => {
    dispatch(
      getTestsLookUp({
        clientId: decodedUserDetails()?.clientId,
        gender: inputs.gender,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        //Fetching of labtest lookup
        setLabTestNames(res.payload.data);
      }
    });
  }, [dispatch, inputs.gender]);

  const closeModal = () => {
    handleCloseMultiLabRequirementsModal();
    handleCloseMultiSpecimenSelectorModal();
    handleCloseAdditionalPatientInfoModal();
    setSelectedSpecimens([]);
  };

  const filteredSelectedDataBySpecimenLength = (data) => {
    let extractedTestData = [];
    // eslint-disable-next-line
    data?.child?.map((item) => {
      if (item?.testDetails?.labTestSpecimens?.length === 1) {
        extractedTestData.push(item);
      }

      if (item?.child && item?.child?.length > 0) {
        const childData = filteredSelectedDataBySpecimenLength(item);
        extractedTestData?.push(...childData);
      }
    });

    return extractedTestData;
  };

  const selectedTestDataItems = (data) => {
    let extractedTestData = [];
    // eslint-disable-next-line
    data?.child?.map((item) => {
      extractedTestData.push(item);

      if (item?.child && item?.child?.length > 0) {
        const childData = selectedTestDataItems(item);
        extractedTestData?.push(...childData);
      }
    });

    return extractedTestData;
  };

  const labtestHasMultipleSpecimenCount = selectedTestDataItems(
    selectedTestDatas
  )?.filter((data) => data?.testDetails?.labTestSpecimens?.length > 1)?.length;

  const disableSubmitBtn =
    inputValues.some((input) => input.value === "" && input.isRequired) ||
    selectedSpecimens.length < labtestHasMultipleSpecimenCount;

  const showTestName = (labtestInfoId, previousLabtestId) => {
    //Function for showing labtest name
    if (labtestInfoId !== previousLabtestId) {
      return true;
    }

    return false;
  };

  const submitData = () => {
    if (showLabReqInputFields && !showSpecimenCheckbox) {
      handleSubmitRecursiveFields();
    }
    if (showSpecimenCheckbox && !showLabReqInputFields) {
      handleSubmitRecursiveSpecimens();
    }
    if (showLabReqInputFields && showLabReqInputFields) {
      handleSubmitSpecimenAndLabRequirements();
    }
  };

  const formatDataToBeAdded = (data) => {
    return data?.map((item) => ({
      testId: item?.id || item?.labTestInfoId,
      requiresBloodDraw:
        item?.testDetails?.labTestSpecimens[0]?.requiresBloodDraw ||
        item?.requiresBloodDraw,
      requiresSampleOrigin:
        item?.testDetails?.labTestSpecimens[0]?.requiresSampleOrigin ||
        item?.requiresSampleOrigin,
      specimenExpirationInHours:
        item?.testDetails?.maxSpecimenAgeInHours ||
        item?.specimenExpirationInHours,
      specimenId:
        item?.testDetails?.labTestSpecimens[0]?.id || item?.specimenId,
      unitOfMeasure:
        item?.testDetails?.labTestSpecimens[0]?.unitOfMeasure ||
        item?.unitOfMeasure,
      specimenQuantity:
        (item?.testDetails?.labTestSpecimens[0]?.testVolumeOrSizeRequirements
          .length === 0
          ? "N/A"
          : item?.testDetails?.labTestSpecimens[0]?.testVolumeOrSizeRequirements.find(
              (req) => Math.min(req.minTest)
            )?.minVolume) ||
        (item?.specimenVolReqData.length === 0
          ? "N/A"
          : item?.specimenVolReqData.find((req) => Math.min(req.minTest))
              ?.minVolume),
      specimenVolReqData:
        item?.testDetails?.labTestSpecimens[0]?.testVolumeOrSizeRequirements ||
        item?.specimenVolReqData,
      specimen: item?.testDetails?.labTestSpecimens[0]?.name || item?.specimen,
      selectedCollectionSiteOption: null,
      collectionDateTime: moment().format(),
      collectorId:
        (item?.testDetails?.labTestSpecimens[0]?.requiresBloodDraw
          ? ""
          : null) || (item?.requiresBloodDraw ? "" : null),
      collectionSite:
        (item?.testDetails?.labTestSpecimens[0]?.requiresSampleOrigin
          ? ""
          : null) || (item?.requiresSampleOrigin ? "" : null),
      status: 0,
      key: item?.testDetails?.labTestSpecimens[0]?.name || item?.specimen,
    }));
  };

  const handleSubmitSpecimenAndLabRequirements = () => {
    const extractedTestData =
      filteredSelectedDataBySpecimenLength(selectedTestDatas);
    const selectedDatasChildItems = selectedTestDataItems(selectedTestDatas);
    const testProfilePanelPackageIds = selectedDatasChildItems
      ?.filter((data) => data.type !== "Test")
      ?.map((item) => item.id);
    const formattedSelectedSpecimenData =
      formatDataToBeAdded(selectedSpecimens);
    const specimensToBeAdded = formatDataToBeAdded(extractedTestData);

    const combinedPatientSpecimensData = combineArray(
      specimensToBeAdded,
      formattedSelectedSpecimenData
    );

    const listsOfLabtestThatWillBeRemoved =
      savedLabtests?.savedLabtestsData?.filter((data) =>
        combinedPatientSpecimensData
          .map((data) => data.testId)
          ?.includes(data.id)
      ); //Get all of the labtest that needs to be removed, before we add panel/profile/package

    const combinedTestIds = [
      ...combinedPatientSpecimensData?.map((data) => data?.testId),
      ...testProfilePanelPackageIds,
    ];

    const mappedAndCombinedSpecimenNames = combinedPatientSpecimensData?.map(
      (data) => data?.specimen
    );

    const testsToBeRemovedInOrder =
      multiStepFormInputs.testOrderDetails?.filter(
        (data) =>
          !combinedPatientSpecimensData
            .map((data) => data.testId)
            .includes(data.testId)
      );

    const specimensOfLabtestThatwillBeRemoved =
      multiStepFormInputs?.patientSpecimens
        ?.filter((data) =>
          listsOfLabtestThatWillBeRemoved?.some((item) =>
            item.specimen.includes(data.specimen)
          )
        )
        ?.map((specimen) => specimen.specimen);

    const filteredSpecimensOfLabtestThatWillBeRemoved =
      specimensOfLabtestThatwillBeRemoved
        .filter(
          (specimenName) =>
            !selectedSpecimens
              .map((item) => item.specimen)
              .includes(specimenName)
        )
        .toString();

    const filteredPatientSpecimens =
      multiStepFormInputs?.patientSpecimens?.filter(
        (data) => data.specimen !== filteredSpecimensOfLabtestThatWillBeRemoved
      );

    const testToBeAdded = {
      id: selectedTestDatas?.id,
      type: selectedTestDatas?.type,
      testIds: combinedTestIds,
      name: selectedTestDatas.name,
      code: selectedTestDatas.code,
      specimenExpirationInHours: null,
      specimen: removeDuplicatesInArray(mappedAndCombinedSpecimenNames),
      isStatAllowed: false,
    };

    const filteredSavedLabtestsData = savedLabtests?.savedLabtestsData?.filter(
      (data) =>
        !combinedPatientSpecimensData
          ?.map((specimenData) => specimenData?.testId)
          .includes(data.id)
    );
    const filteredSavedSelectedIds =
      savedLabtests?.savedLabtestsIdAndSpecimen?.filter(
        (data) =>
          !combinedPatientSpecimensData
            .map((data) => data.testId)
            .includes(data.testId)
      ); //Filter recursively fetched labtest id, check if one of the labtest is already added

    const filteredSpecimensToAdd = combinedPatientSpecimensData?.filter(
      (specimen) => {
        return !multiStepFormInputs?.patientSpecimens?.some(
          (data) => specimen.specimenId === data.specimenId
        );
      }
    );

    const uniqueFilteredSpecimensToAdd = filteredSpecimensToAdd?.filter(
      (item, index) => {
        return (
          index ===
          filteredSpecimensToAdd?.findIndex((obj) => {
            return item.specimenId === obj.specimenId;
          })
        );
      }
    );
    const filteredSavedTestInfo = savedTestInputsInfo.savedTestInfo.filter(
      (test) =>
        !combinedPatientSpecimensData
          .map((data) => data.testId)
          .includes(test.id)
    );

    const filteredCombinedSpecimensData = [
      ...new Set(combinedPatientSpecimensData.map((data) => data.specimen)),
    ];

    const existingRelatedPatientSpecimenKeys =
      multiStepFormInputs.patientSpecimens
        .filter((item) =>
          filteredCombinedSpecimensData.some(
            (specimen) => item.specimen === specimen
          )
        )
        .map((data) => data.key);
    const relatedPatientSpecimenKeysValue =
      orderData.isOrderDataForUpdate &&
      existingRelatedPatientSpecimenKeys.length > 0
        ? existingRelatedPatientSpecimenKeys
        : filteredCombinedSpecimensData;

    dispatch(
      handleChangeSavedTestInfo({
        savedRequirementDetails: [
          ...savedTestInputsInfo.savedRequirementDetails,
          fetchedInputFields,
        ],
        savedTestInfo: [
          ...filteredSavedTestInfo,
          {
            id: selectedTestDatas.id,
            name: selectedTestDatas.name,
            type: selectedTestDatas.type,
          },
        ],
      })
    );
    getLabtestData(filteredSavedLabtestsData, testToBeAdded);
    getNestedLabtestIdAndSpecimen(
      filteredSavedSelectedIds,
      removeDuplicatesInArray(combinedPatientSpecimensData)
    );

    dispatch(
      handleChangeMultiFields({
        testOrderDetails: [
          ...testsToBeRemovedInOrder,
          {
            id: 0,
            testOrderId:
              orderData.selectedTestOrderId !== 0
                ? orderData.selectedTestOrderId
                : 0,
            testId: selectedTestDatas.id,
            status: "Ordered",
            isStat: false,
            testIds: selectedDatasChildItems?.map((data) => data.id),
            patientTestRequirementDatas: [
              {
                testOrderDetailId: selectedTestDatas.id,
                patientRequirementDataDetails: inputValues.map(
                  ({ id, value, description }) => ({
                    patientTestRequirementDataId: id,
                    dataKey: id,
                    dataValue: value,
                    description: description,
                  })
                ),
              },
            ],
            duplicatedSpecimenKeys: combinedPatientSpecimensData?.map(
              (data) => data.specimen
            ),
            relatedPatientSpecimenKeys: relatedPatientSpecimenKeysValue,
          },
        ],
        patientSpecimens: [
          ...filteredPatientSpecimens,
          ...uniqueFilteredSpecimensToAdd?.map((data) => {
            return {
              requiresSampleOrigin: data.requiresSampleOrigin,
              requiresBloodDraw: data.requiresBloodDraw,
              unitOfMeasure: data.unitOfMeasure,
              specimen: data.specimen,
              key: data.key,
              selectedCollectionSiteOption:
                data.selectedCollectionSiteOption || null,
              specimenExpirationInHours: data.specimenExpirationInHours,
              specimenId: data.specimenId,
              specimenQuantity: data.specimenQuantity,
              notes: "",
              specimenVolReqData: data.specimenVolReqData,
              collectionDateTime: data.collectionDateTime,
              collectorId: data.requiresBloodDraw ? "" : null,
              collectionSite: data.requiresSampleOrigin ? "" : null,
              status: data.status,
            };
          }),
        ],
      })
    );

    notification.success({
      message: "Added Laboratory Test",
      description: `Successfully added ${selectedTestDatas.name}`,
    });

    if (listsOfLabtestThatWillBeRemoved.length > 0) {
      const notificationToastTimeout = setTimeout(() => {
        handleShowWarningModal(
          <p style={{ fontSize: "1rem", color: "black" }}>
            <strong style={{ color: "red" }}>
              {" "}
              {listsOfLabtestThatWillBeRemoved
                ?.map((test) => test.name)
                .join(", ")}
            </strong>{" "}
            {listsOfLabtestThatWillBeRemoved?.length > 1 ? "are" : "is"} removed
            because it is inside of{" "}
            <strong style={{ color: "green" }}>{selectedTestDatas.name}</strong>
          </p>
        );
      }, 1000);

      return () => clearTimeout(notificationToastTimeout);
    }
    closeModal();
  };

  const handleSubmitRecursiveFields = () => {
    //Function for submitting labtest data recursively if the type of it is not a test
    const specimensToBeAdded = getSpecimenRecursively(selectedTestDatas);
    const testIdsToAdd = getLabtestIdRecursively(selectedTestDatas);
    const duplicatedSpecimenNames =
      getDuplicatedSpecimenNames(selectedTestDatas);

    const filteredSpecimensToAdd = specimensToBeAdded.filter((specimen) => {
      return !multiStepFormInputs.patientSpecimens.some((data) =>
        specimen.specimen.includes(data.specimen)
      );
    });
    const filteredTestIdsToAdd = testIdsToAdd.filter(
      (data) =>
        !savedLabtests.savedLabtestsIdAndSpecimen
          .map((test) => test.id)
          .includes(data.testId)
    );
    const filteredSavedTest = savedLabtests?.savedLabtestsData?.filter(
      (data) => !testIdsToAdd.map((data) => data.testId).includes(data.id)
    );
    const filteredSavedTestInfo = savedTestInputsInfo.savedTestInfo.filter(
      (test) => !testIdsToAdd.map((data) => data.testId).includes(test.id)
    );
    const testsToBeRemoved = savedLabtests?.savedLabtestsData?.filter((data) =>
      testIdsToAdd.map((data) => data.testId)?.includes(data.id)
    );
    const testsToBeRemovedInOrder =
      multiStepFormInputs.testOrderDetails?.filter(
        (data) => !testIdsToAdd.map((data) => data.testId).includes(data.testId)
      );

    const existingPatientSpecimenKey = multiStepFormInputs.patientSpecimens
      .filter((specimen) =>
        specimensToBeAdded.some(
          (specimenToBeAdded) =>
            specimen.specimen === specimenToBeAdded.specimen
        )
      )
      .map((data) => data.key);

    const defaultRelatedPatientSpecimenKey =
      selectedTestDatas?.child?.length > 0
        ? [...new Set(specimensToBeAdded.map((data) => data.specimen))]
        : specimensToBeAdded.map((data) => data.specimen);

    const relatedPatientSpecimenKeysValue =
      orderData.isOrderDataForUpdate && existingPatientSpecimenKey.length > 0
        ? existingPatientSpecimenKey
        : defaultRelatedPatientSpecimenKey;
    dispatch(
      handleChangeSavedTestInfo({
        savedRequirementDetails: [
          ...savedTestInputsInfo.savedRequirementDetails,
          fetchedInputFields,
        ],
        savedTestInfo: [
          ...filteredSavedTestInfo,
          {
            id: selectedTestDatas.id,
            name: selectedTestDatas.name,
            type: selectedTestDatas.type,
          },
        ],
      })
    );

    dispatch(
      handleChangeLabtestData({
        savedLabtestsData: [
          ...filteredSavedTest,
          {
            type: selectedTestDatas.type,
            id: selectedTestDatas.id,
            testIds: testIdsToAdd.map((test) => test.testId),
            name: selectedTestDatas.name,
            code: selectedTestDatas.code,
            specimenExpirationInHours:
              selectedTestDatas?.testDetails?.maxSpecimenAgeInHours,
            specimen:
              selectedTestDatas?.child?.length > 0
                ? specimensToBeAdded?.map((data) => data.specimen)
                : selectedTestDatas.testDetails.labTestSpecimens.map(
                    (data) => data.name
                  ),
            isStatAllowed: selectedTestDatas?.testDetails?.isStatAllowed,
          },
        ],
        savedLabtestsIdAndSpecimen: [
          ...filteredSavedTest,
          ...filteredTestIdsToAdd.map((data) => ({
            testId: data.testId,
            specimenExpirationInHours: data.specimenExpirationInHours,
            specimen: data.specimen,
          })),
        ],
      })
    );

    dispatch(
      handleChangeMultiFields({
        testOrderDetails: [
          ...testsToBeRemovedInOrder,
          {
            id: 0,
            testOrderId:
              orderData.selectedTestOrderId !== 0
                ? orderData.selectedTestOrderId
                : 0,
            testId: selectedTestDatas.id,
            status: "Ordered",
            isStat: false,
            testIds:
              selectedTestDatas?.child?.length > 0
                ? testIdsToAdd.map((test) => test.testId)
                : null,
            patientTestRequirementDatas: [
              {
                testOrderDetailId: selectedTestDatas.id,
                patientRequirementDataDetails: inputValues.map(
                  ({ id, value, description }) => ({
                    patientTestRequirementDataId: id,
                    dataKey: id,
                    dataValue: value,
                    description: description,
                  })
                ),
              },
            ],
            duplicatedSpecimenKeys:
              selectedTestDatas?.child?.length > 0
                ? duplicatedSpecimenNames?.map((data) => data.specimenName)
                : [
                    selectedTestDatas?.testDetails?.labTestSpecimens
                      ?.map((data) => data.name)
                      .toString(),
                  ],
            relatedPatientSpecimenKeys: relatedPatientSpecimenKeysValue,
          },
        ],
        patientSpecimens: [
          ...multiStepFormInputs.patientSpecimens,
          ...filteredSpecimensToAdd?.map((data) => ({
            requiresSampleOrigin: data.requiresSampleOrigin,
            selectedCollectionSiteOption: data.selectedCollectionSiteOption,
            requiresBloodDraw: data.requiresBloodDraw,
            unitOfMeasure: data.unitOfMeasure,
            specimen: data.specimen,
            specimenExpirationInHours: data.specimenExpirationInHours,
            key: data.key,
            specimenId: data.specimenId,
            specimenQuantity: data.specimenQuantity,
            notes: "",
            specimenVolReqData: data.specimenVolReqData,
            collectionDateTime: data.collectionDateTime,
            collectorId: data.collectorId,
            collectionSite: data.collectionSite,
            status: data.status,
          })),
        ],
      })
    );

    notification.success({
      message: "Added Laboratory Test",
      description: `Successfully added ${selectedTestDatas.name}`,
    });

    if (testsToBeRemoved.length > 0) {
      const notificationToastTimeout = setTimeout(() => {
        handleShowWarningModal(
          <p style={{ fontSize: "1rem", color: "black" }}>
            <strong style={{ color: "red" }}>
              {" "}
              {testsToBeRemoved?.map((test) => test.name).join(", ")}
            </strong>{" "}
            {testsToBeRemoved?.length > 1 ? "are" : "is"} removed because it is
            inside of{" "}
            <strong style={{ color: "green" }}>{selectedTestDatas.name}</strong>
          </p>
        );
      }, 1000);
      return () => clearTimeout(notificationToastTimeout);
    }
    closeModal();
    setInputValues([]);
  };

  const handleSubmitRecursiveSpecimens = () => {
    const extractedTestData =
      filteredSelectedDataBySpecimenLength(selectedTestDatas);

    const formattedSelectedSpecimenData =
      formatDataToBeAdded(selectedSpecimens);
    const specimensToBeAdded = formatDataToBeAdded(extractedTestData);

    const combinedPatientSpecimensData = combineArray(
      specimensToBeAdded,
      formattedSelectedSpecimenData
    );

    const listsOfLabtestThatWillBeRemoved =
      savedLabtests?.savedLabtestsData?.filter((data) =>
        combinedPatientSpecimensData
          .map((data) => data.testId)
          ?.includes(data.id)
      ); //Get all of the labtest that needs to be removed, before we add panel/profile/package
    const combinedTestIds = combinedPatientSpecimensData?.map(
      (data) => data?.testId
    );

    const mappedAndCombinedSpecimenNames = combinedPatientSpecimensData?.map(
      (data) => data?.specimen
    );

    const labtestsToBeRemovedInTestOrder =
      multiStepFormInputs.testOrderDetails?.filter(
        (data) =>
          !combinedPatientSpecimensData
            .map((data) => data.testId)
            .includes(data.testId)
      ); //Labtests that will be removed in test order details

    const testToBeAdded = {
      id: selectedTestDatas?.id,
      type: selectedTestDatas?.type,
      testIds: combinedTestIds,
      name: selectedTestDatas.name,
      code: selectedTestDatas.code,
      specimenExpirationInHours: null,
      specimen: removeDuplicatesInArray(mappedAndCombinedSpecimenNames),
      isStatAllowed: false,
    };

    const filteredSavedLabtestsData = savedLabtests?.savedLabtestsData?.filter(
      (data) =>
        !combinedPatientSpecimensData
          ?.map((specimenData) => specimenData?.testId)
          .includes(data.id)
    );
    const filteredSavedSelectedIds =
      savedLabtests?.savedLabtestsIdAndSpecimen?.filter(
        (data) =>
          !combinedPatientSpecimensData
            .map((data) => data.testId)
            .includes(data.testId)
      ); //Filter recursively fetched labtest id, check if one of the labtest is already added
    const filteredSpecimensToAdd = combinedPatientSpecimensData?.filter(
      (specimen) => {
        return !multiStepFormInputs?.patientSpecimens?.some(
          (data) => specimen.specimenId === data.specimenId
        );
      }
    );
    const uniqueFilteredSpecimensToAdd = filteredSpecimensToAdd?.filter(
      (item, index) => {
        return (
          index ===
          filteredSpecimensToAdd?.findIndex((obj) => {
            return item.specimenId === obj.specimenId;
          })
        );
      }
    );

    getLabtestData(filteredSavedLabtestsData, testToBeAdded);
    getNestedLabtestIdAndSpecimen(
      filteredSavedSelectedIds,
      removeDuplicatesInArray(combinedPatientSpecimensData)
    );
    getNestedTestOrderDetails(
      labtestsToBeRemovedInTestOrder,
      selectedTestDatas,
      combinedTestIds?.map((id) => ({ testId: id })),
      combinedPatientSpecimensData?.map((data) => ({
        specimenName: data?.specimen,
      })),
      combinedPatientSpecimensData
    );
    getNestedPatientSpecimensData(
      uniqueFilteredSpecimensToAdd,
      combinedPatientSpecimensData
    );
    notification.success({
      message: "Added Laboratory Test",
      description: `Successfully added ${selectedTestDatas.name}`,
    });

    if (listsOfLabtestThatWillBeRemoved.length > 0) {
      const notificationToastTimeout = setTimeout(() => {
        handleShowWarningModal(
          <p style={{ fontSize: "1rem", color: "black" }}>
            <strong style={{ color: "red" }}>
              {" "}
              {listsOfLabtestThatWillBeRemoved
                ?.map((test) => test.name)
                .join(", ")}
            </strong>{" "}
            {listsOfLabtestThatWillBeRemoved?.length > 1 ? "are" : "is"} removed
            because it is inside of{" "}
            <strong style={{ color: "green" }}>{selectedTestDatas.name}</strong>
          </p>
        );
      }, 1000);
      return () => clearTimeout(notificationToastTimeout);
    }
    handleCloseMultiSpecimenSelectorModal();
    setSelectedSpecimens([]);
  };

  const combineArray = (arrayOne, arrayTwo) => {
    return [...arrayOne, ...arrayTwo];
  };
  const removeDuplicatesInArray = (data) => {
    return [...new Set(data)];
  };

  const handleShowWarningModal = (message) => {
    //For showing warning message modal
    setShowWarningModal(true);
    setWarningMessage(message);
    setTimeout(() => {
      setShowWarningModal(false);
      setWarningMessage("");
    }, 3000);
  };

  const handleCloseWarningModal = () => {
    //For closing warning modal
    setShowWarningModal(false);
    setWarningMessage("");
  };

  return (
    <Box
      sx={{
        marginBottom: "2.5rem",
        height: specimenOptionsCount > 4 ? "30rem" : "20rem",
        overflowY: specimenOptionsCount > 4 ? "scroll" : "auto",
      }}
    >
      {showLabReqInputFields && (
        <ProfilePanelPackageLabReq
          inputValues={inputValues}
          setInputValues={setInputValues}
          labTestNames={labTestNames}
          fetchedInputFields={fetchedInputFields}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {showSpecimenCheckbox &&
          specimenOptions?.map((data) => {
            return (
              <>
                {showTestName(
                  data.labTestInfoId,
                  specimenOptions[specimenOptions.indexOf(data) - 1]
                    ?.labTestInfoId
                ) && (
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      textAlign: "center",
                      paddingTop: "0.5rem",
                      color: "grayishBlue.light",
                      fontWeight: "bold",
                      pb: 2,
                    }}
                  >
                    Please select specimen for{" "}
                    <i>
                      <strong>
                        {" "}
                        {
                          labTestNames.find(
                            (test) => test.id === data.labTestInfoId
                          )?.name
                        }
                      </strong>
                    </i>
                  </Typography>
                )}
                <Box
                  sx={{
                    backgroundColor: "#E7EBF4",
                    padding: "0.3rem 0.7rem",
                    margin: "0 1rem",
                  }}
                >
                  <FormControl key={data.key}>
                    <FormGroup>
                      <FormControlLabel
                        key={data.key}
                        control={
                          <Checkbox
                            checked={selectedSpecimens.some(
                              (selectedOption) =>
                                selectedOption.labTestInfoId ===
                                  data.labTestInfoId &&
                                selectedOption.key === data.key
                            )}
                            onChange={(e) =>
                              handleSelectSpecimen(
                                e,
                                data,
                                setSelectedSpecimens
                              )
                            }
                          />
                        }
                        label={data.specimen}
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </>
            );
          })}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          position: "absolute",
          bottom: 10,
          left: 54,
        }}
      >
        <button
          disabled={disableSubmitBtn}
          onClick={submitData}
          className={styles["save_btn"]}
        >
          Save
        </button>
        <button className={styles["cancel_btn"]} onClick={closeModal}>
          Cancel
        </button>
      </Box>
      <ResultComponent
        onCancel={handleCloseWarningModal}
        modalWidth={550}
        status="warning"
        title={"Some labtest have been removed"}
        subTitle={warningMessage}
        buttons={null}
        openModal={showWarningModal}
      />
    </Box>
  );
};

export default ProfilePanelPackageSpecimenSelectionModal;
