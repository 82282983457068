import { Button, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getInventoryItemsLookup } from "store";

const CreatedSupplyRequestItem = ({
  supplyRequestFormInputs,
  setRequestedItemInputs,
  setSupplyRequestFormInputs,
  setIsDataForUpdate,
}) => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const dispatch = useDispatch();

  const label = (text) => {
    return <span className="text-[#324661] font-[500] mt-[2px]">{text}:</span>;
  };

  const valueWrapper = (text) => {
    return (
      <p className="bg-[#DFE5ED] w-fit text-black px-2 py-[2px] rounded-[4px]">
        {text}
      </p>
    );
  };

  const handleFetchInventoryItemsLookup = () => {
    dispatch(getInventoryItemsLookup()).then((res) => {
      const isFetchSuccessful = res?.payload?.success;

      if (isFetchSuccessful) {
        setInventoryItems(res?.payload?.data);
      }
    });
  };

  useEffect(() => {
    handleFetchInventoryItemsLookup();
    // eslint-disable-next-line
  }, []);

  const getInventoryItemName = (inventoryItemId) => {
    const foundName = inventoryItems.find(
      (item) => item.id === inventoryItemId
    )?.name;

    return foundName;
  };

  const onRemoveCreatedSupplyRequestItem = (itemId) => {
    const filteredSupplyRequestItemInputs =
      supplyRequestFormInputs?.requestedItems?.filter(
        (data) => itemId !== data.inventoryItemId
      );
    setSupplyRequestFormInputs({
      ...supplyRequestFormInputs,
      requestedItems: filteredSupplyRequestItemInputs,
    });
  };

  const onEditCreatedSupplyRequestItem = (itemData) => {
    setRequestedItemInputs(itemData);
    setIsDataForUpdate(true);
  };

  return (
    <div className="bg-[#DFE5ED] p-4 mt-5 rounded-lg">
      <div className="bg-[#C1CFE0] rounded-lg p-3 space-y-2">
        {supplyRequestFormInputs?.requestedItems?.map((item, index) => (
          <div
            className="bg-white w-full h-full py-3 px-4 border border-[#1976D2] rounded-[4px]"
            key={index}
          >
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                {/* Item Name */}
                <div className="flex gap-1">
                  {label("Item")}
                  {valueWrapper(getInventoryItemName(item?.inventoryItemId))}
                </div>
                {/* Quantity */}
                <div className="flex gap-1">
                  {label("Quantity")}
                  {valueWrapper(item?.quantityRequested)}
                </div>
              </div>
              {/* Purpose */}
              {item.purpose && (
                <div className="flex gap-1">
                  {label("Purpose")}
                  {valueWrapper(item?.purpose)}
                </div>
              )}
            </div>
            <div className="flex items-center justify-end gap-3">
              <Popconfirm
                title="Are you sure?"
                onConfirm={() =>
                  onRemoveCreatedSupplyRequestItem(item.inventoryItemId)
                }
              >
                <Button
                  size="small"
                  className="bg-[#FF5050]  text-white rounded-lg"
                >
                  Delete
                </Button>
              </Popconfirm>
              <Button
                size="small"
                className="flex items-center gap-1 bg-[#1976D2] px-3 rounded-lg"
                type="primary"
                onClick={() => onEditCreatedSupplyRequestItem(item)}
              >
                Edit
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreatedSupplyRequestItem;
