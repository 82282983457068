/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useCallback } from "react";
import { Box, Grid, Typography } from "@mui/material";
import SessionSummarySidePanel from "./sessionSummarySidePanel";
import PaginationButtons from "./paginationButtons";
import { useDispatch } from "react-redux";
import { getOrderSessionSummary } from "store";
import usePagination from "hooks/usePagination";
import SessionSummaryContent from "./sessionSummaryContent";
import { getClientInfo, getAllTestOrder } from "store";
import decodedUserDetails from "utils/decodedUserDetails";
import { isDataEmpty } from "utils/isDataEmpty";
import moment from "moment";
import DateFilter from "../testOrder/filterOptions/dateFilter";
import OrderStatusFilter from "../testOrder/filterOptions/orderStatusFilter";
import SessionSummaryTab from "./sessionSummaryTab";
import PreviousOrdersContent from "./previousOrdersContent";
import { parsedClientDetails } from "utils/parsedClientDetails";
import ClientFilter from "../testOrder/filterOptions/clientFilter";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const GetAllSessionSummary = () => {
  const sessionSummaryRef = useRef(null);
  const previousTestOrderRef = useRef(null);
  const promiseResolveRef = useRef(null);
  const [orderData, setOrderData] = useState([]);
  const [previousOrderData, setPreviousOrderData] = useState([]);
  const [previousOrderDataToBePrinted, setPreviousOrderDataToBePrinted] =
    useState(false);
  const [isSessionSummaryDataFetching, setIsSessionSummaryDataFetching] =
    useState(false);
  const [isPreviousTestOrderFetching, setIsPreviousTestOrderFetching] =
    useState(false);
  const [selectedSessionSummaryTab, setSelectedSessionSummaryTab] = useState(0);
  const [printTestOrderSummary, setPrintTestOrderSummary] = useState(false);
  const [printPreviousTestOrders, setPrintPreviousTestOrders] = useState(false);
  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState([]);
  const [selectedOrderDate, setSelectedOrderDate] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const isPreviousTestOrderTabSelected = selectedSessionSummaryTab === 1;
  const [clientDetails, setClientDetails] = useState(null);
  const { isClientAbcLab } = parsedClientDetails();
  const [previousTestOrderPageNumber, setPreviousTestOrderPageNumber] =
    useState(1);
  const previousTestOrderPagesize = 10;
  const [previousTestOrderTotal, setPreviousTestOrderTotal] = useState(0);
  const [totalPreviousTestOrderPages, setTotalPreviousTestOrderPages] =
    useState(0);
  const disablePreviousTestOrderPrevButton =
    previousTestOrderPageNumber <= 1 || isPreviousTestOrderFetching;
  const disablePreviousTestOrderNextButton =
    previousTestOrderPageNumber === totalPreviousTestOrderPages ||
    isPreviousTestOrderFetching;
  const isPreviousTestOrderDataEmpty =
    isPreviousTestOrderTabSelected &&
    isPreviousTestOrderFetching &&
    previousOrderData?.length === 0;
  const isSessionSummaryDataEmpty =
    !isPreviousTestOrderTabSelected &&
    isSessionSummaryDataFetching &&
    orderData?.length === 0;
  const dispatch = useDispatch();

  useEffect(() => {
    //Fetching of test order summary data
    if (
      getOrderSessionSummary.pending().type ===
      "test-orders/get-test-order-session-summary/pending"
    ) {
      setIsSessionSummaryDataFetching(true);
    }
    dispatch(getOrderSessionSummary()).then((res) => {
      if (res?.payload?.success) {
        setIsSessionSummaryDataFetching(false);
        setOrderData(res?.payload?.data.reverse());
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (printPreviousTestOrders && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [printPreviousTestOrders]);

  useEffect(() => {
    if (printTestOrderSummary && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [printTestOrderSummary]);

  // Previous test order data for printing
  useEffect(() => {
    const clientIdValue = isDataEmpty(selectedClientId)
      ? null
      : selectedClientId;

    dispatch(
      getAllTestOrder({
        clientId: isClientAbcLab
          ? clientIdValue
          : decodedUserDetails()?.clientId,
        statuses: isDataEmpty(selectedOrderStatuses)
          ? null
          : selectedOrderStatuses,
        startOrderDate: isDataEmpty(selectedOrderDate)
          ? null
          : moment(selectedOrderDate[0]).format(),
        endOrderDate: isDataEmpty(selectedOrderDate)
          ? null
          : moment(selectedOrderDate[1]).format(),
        search: "",
        sortDirection: 1,
        pageSize: 20,
        pageNumber: 1,
        loadCompleteData: true,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      const items = res?.payload?.data?.items;
      if (isFetchSuccessful) {
        setPreviousOrderDataToBePrinted(items);
      }
    });
  }, [
    dispatch,
    selectedOrderStatuses,
    selectedOrderDate,
    selectedClientId,
    isClientAbcLab,
  ]);

  // Previous test order data for display
  useEffect(() => {
    if (
      getAllTestOrder.pending().type ===
      "test-orders/get-all-test-order/pending"
    ) {
      setIsPreviousTestOrderFetching(true);
    }

    const clientIdValue = isDataEmpty(selectedClientId)
      ? null
      : selectedClientId;

    dispatch(
      getAllTestOrder({
        clientId: isClientAbcLab
          ? clientIdValue
          : decodedUserDetails()?.clientId,
        statuses: isDataEmpty(selectedOrderStatuses)
          ? null
          : selectedOrderStatuses,
        startOrderDate: isDataEmpty(selectedOrderDate)
          ? null
          : moment(selectedOrderDate[0]).format(),
        endOrderDate: isDataEmpty(selectedOrderDate)
          ? null
          : moment(selectedOrderDate[1]).format(),
        search: "",
        sortDirection: 1,
        pageSize: previousTestOrderPagesize,
        pageNumber: previousTestOrderPageNumber,
        loadCompleteData: true,
      })
    ).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      const items = res?.payload?.data?.items;
      if (isFetchSuccessful) {
        setPreviousTestOrderTotal(
          res.payload.data.totalPages * previousTestOrderPagesize
        );
        setTotalPreviousTestOrderPages(res.payload.data.totalPages);
        setPreviousOrderData(items);
        setIsPreviousTestOrderFetching(false);
      }
    });
  }, [
    dispatch,
    selectedOrderStatuses,
    selectedOrderDate,
    selectedClientId,
    isClientAbcLab,
    previousTestOrderPageNumber,
    previousTestOrderPagesize,
    printPreviousTestOrders,
  ]);

  //Fetching of client details
  useEffect(() => {
    dispatch(getClientInfo()).then((res) => {
      const isFetchSuccessful = res?.payload?.success;
      if (isFetchSuccessful) {
        setClientDetails(res.payload.data);
      }
    });
  }, [dispatch]);

  const {
    setPageNumber,
    paginatedData,
    pageNumber,
    totalPages,
    disablePrevButton,
    disableNextButton,
  } = usePagination(orderData, 3); //Destructuring the values inside of usePagination hook, that will be use to paginate the test order summary data

  const handleClickNextPage = useCallback(() => {
    //Function to navigate user to the next page

    if (isPreviousTestOrderTabSelected) {
      setPreviousTestOrderPageNumber((prevPageNumber) => {
        if (prevPageNumber < totalPreviousTestOrderPages) {
          return prevPageNumber + 1;
        }

        return prevPageNumber;
      });
    } else {
      setPageNumber((prevPageNumber) => {
        if (prevPageNumber < totalPages) {
          return prevPageNumber + 1;
        }

        return prevPageNumber;
      });
    }
    // eslint-disable-next-line
  }, [isPreviousTestOrderTabSelected ? previousTestOrderTotal : totalPages]);

  const handleClickPrevPage = useCallback(() => {
    //Function to navigate user to the next page

    if (isPreviousTestOrderTabSelected) {
      setPreviousTestOrderPageNumber((prevPageNumber) => {
        if (prevPageNumber > 0) {
          return prevPageNumber - 1;
        }

        return prevPageNumber;
      });
    } else {
      setPageNumber((prevPageNumber) => {
        if (prevPageNumber > 0) {
          return prevPageNumber - 1;
        }

        return prevPageNumber;
      });
    }
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isPreviousTestOrderTabSelected ? previousTestOrderPageNumber : pageNumber,
  ]);

  const onBeforePrintSessionSummaryContent = () => {
    //Before printing the test order summary, it should display all of the data not the paginated one using a state
    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintTestOrderSummary(true);
    });
  };

  const onAfterPrintSessionSummaryContent = () => {
    //After printing set the state to false, to re display the paginated data
    promiseResolveRef.current = null;
    setPrintTestOrderSummary(false);
  };

  const onBeforePrintPreviousTestOrderContent = () => {
    //Before printing previous test orders, it should display all of the data not the paginated one using a state

    return new Promise((resolve) => {
      promiseResolveRef.current = resolve;
      setPrintPreviousTestOrders(true);
    });
  };

  const onAfterPrintPreviousTestOrderContent = () => {
    //After printing set the state to false, to re display the paginated data
    promiseResolveRef.current = null;
    setPrintPreviousTestOrders(false);
  };

  const handleChangeDate = (dates, dateStrings) => {
    //Handler to change date when filtering test order
    if (dates === null) {
      setSelectedOrderDate(null);
    } else {
      setSelectedOrderDate(dateStrings);
    }
  };

  const handleSelectStatus = (value) => {
    setSelectedOrderStatuses(value);
  };

  const onTabChange = (newValue) => {
    setSelectedSessionSummaryTab(newValue);
  };

  const handleSelectClientId = (value) => {
    setSelectedClientId(value);
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexWrap: "wrap-reverse",
        maxHeight: "100%",
        minHeight: "100vh",
        height: "100%",
        marginBottom: "3rem",
      }}
      container
    >
      <Grid xs={12} sm={12} md={12} lg={12} xl={9} item>
        <Typography
          sx={{
            color: "darkBlue.main",
            margin: "1.6rem auto",
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "60rem",
            },
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "block",
            },
            fontSize: "2rem",
            fontWeight: "500",
          }}
          variant="h4"
        >
          Session Summary
        </Typography>
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "60rem",
            },
            padding: {
              xs: "0 0.5rem",
              sm: "0 0.5rem",
              md: 0,
              lg: 0,
              xl: 0,
            },
            margin: "1.2rem auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: { xs: 3, sm: 2, md: 2, lg: 0, xl: 0 },
            }}
          >
            <SessionSummaryTab
              selectedTab={selectedSessionSummaryTab}
              onTabChange={onTabChange}
            />
            <div className="flex items-center gap-10">
              {(!isPreviousTestOrderDataEmpty ||
                !isSessionSummaryDataEmpty) && (
                <Typography variant="p">
                  Page{" "}
                  <span className="font-bold">
                    {isPreviousTestOrderTabSelected
                      ? previousTestOrderPageNumber
                      : pageNumber + 1}
                  </span>{" "}
                  of{" "}
                  <span className="font-bold">
                    {isPreviousTestOrderTabSelected
                      ? totalPreviousTestOrderPages
                      : totalPages}
                  </span>
                </Typography>
              )}

              <PaginationButtons
                handleClickNextPage={handleClickNextPage}
                handleClickPrevPage={handleClickPrevPage}
                disablePrevButton={
                  isPreviousTestOrderTabSelected
                    ? disablePreviousTestOrderPrevButton
                    : disablePrevButton
                }
                disableNextButton={
                  isPreviousTestOrderTabSelected
                    ? disablePreviousTestOrderNextButton
                    : disableNextButton
                }
              />
            </div>
          </Box>
          {isPreviousTestOrderTabSelected && (
            <div className="flex items-center gap-2 mt-5">
              {isClientAbcLab && (
                <ClientFilter handleSelectClientId={handleSelectClientId} />
              )}
              <DateFilter handleChangeDate={handleChangeDate} />
              <OrderStatusFilter
                selectedStatuses={selectedOrderStatuses}
                handleSelectStatus={handleSelectStatus}
              />
            </div>
          )}
        </Box>
        <Box
          sx={{
            background: "white",
            margin: {
              xs: "0 auto",
              sm: "0 auto",
              md: "0 auto",
              lg: "0 auto",
              xl: "1rem auto",
            },
            borderRadius: { xs: 0, sm: 0, md: 0, lg: 0, xl: "1rem" },
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "60rem",
            },
          }}
        >
          <Typography
            sx={{
              color: "darkBlue.main",
              padding: "2rem",
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "60rem",
              },
              display: {
                xs: "block",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "none",
              },
              textAlign: "center",
            }}
            variant="h5"
          >
            Session Summary
          </Typography>
          <CustomTabPanel value={selectedSessionSummaryTab} index={0}>
            <SessionSummaryContent
              clientDetails={clientDetails}
              isSessionSummaryDataFetching={isSessionSummaryDataFetching}
              paginatedOrderData={
                printTestOrderSummary ? orderData : paginatedData
              }
              printTestOrderSummary={printTestOrderSummary}
              ref={sessionSummaryRef}
            />
          </CustomTabPanel>
          <CustomTabPanel value={selectedSessionSummaryTab} index={1}>
            <PreviousOrdersContent
              clientDetails={clientDetails}
              isPreviousTestOrderFetching={isPreviousTestOrderFetching}
              paginatedOrderData={
                printPreviousTestOrders
                  ? previousOrderDataToBePrinted
                  : previousOrderData
              }
              printPreviousTestOrders={printPreviousTestOrders}
              ref={previousTestOrderRef}
            />
          </CustomTabPanel>
        </Box>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={3} item>
        <SessionSummarySidePanel
          clientDetails={clientDetails}
          onBeforePrintContent={
            isPreviousTestOrderTabSelected
              ? onBeforePrintPreviousTestOrderContent
              : onBeforePrintSessionSummaryContent
          }
          onAfterPrintContent={
            isPreviousTestOrderTabSelected
              ? onAfterPrintPreviousTestOrderContent
              : onAfterPrintSessionSummaryContent
          }
          sessionSummaryRef={
            isPreviousTestOrderTabSelected
              ? previousTestOrderRef
              : sessionSummaryRef
          }
        />
      </Grid>
    </Grid>
  );
};

export default GetAllSessionSummary;
