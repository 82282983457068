import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientInfo } from "store";
import QRCode from "react-qr-code";
import { orderStatusTagColor } from "components/mnl/admin/labtest/data";

const HeaderContent = ({ printTestOrder }) => {
  const [clientDetails, setClientDetails] = useState({});
  const orderData = useSelector((state) => state.orderData);
  const dispatch = useDispatch();
  const isOrderNumberGenerated = orderData.autoGeneratedOrderNumber !== null;

  //Fetching of client details
  useEffect(() => {
    dispatch(getClientInfo()).then((res) => {
      if (res.payload.success) {
        setClientDetails(res.payload.data);
      }
    });
  }, [dispatch]);

  return (
    <Grid rowSpacing={2} columnSpacing={1} container>
      <Grid
        sx={{ display: "flex", flexDirection: "column" }}
        xs={printTestOrder ? 3 : 6}
        sm={printTestOrder ? 3 : 6}
        md={3}
        lg={3}
        xl={3}
        item
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "500", color: "darkBlue.main" }}
        >
          Client no.
        </Typography>
        <Typography
          variant="h5"
          sx={{
            width: "max-content",
            color: "black",
            marginTop: "0.3rem",
            fontSize: "1.3rem",
          }}
        >
          {clientDetails?.clientCode}
        </Typography>
      </Grid>

      {!printTestOrder && (
        <Grid
          sx={{
            display: {
              sm: "flex",
              xs: "flex",
              md: "none",
              lg: "none",
              xl: "none",
            },
            flexDirection: "column",
          }}
          xs={6}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          item
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            {isOrderNumberGenerated && (
              <QRCode
                size={80}
                value={orderData?.autoGeneratedOrderNumber + ""}
              />
            )}
            <Box>
              <Typography
                variant="p"
                sx={{ fontWeight: "500", color: "darkBlue.main" }}
              >
                Order no.
              </Typography>
              {isOrderNumberGenerated && (
                <Typography
                  variant="h5"
                  sx={{
                    width: "max-content",
                    color: "black",
                    marginTop: "0.3rem",
                    fontSize: "1.3rem",
                  }}
                >
                  {orderData?.autoGeneratedOrderNumber}
                </Typography>
              )}
              {!isOrderNumberGenerated && (
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontStyle: "italic",
                    color: "softBlue.secondary",
                    padding: "0.3rem",
                    width: "max-content",

                    textAlign: "end",
                    fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem" },
                  }}
                >
                  {"[pending]"}
                </Typography>
              )}
              {isOrderNumberGenerated && (
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    backgroundColor: "#EBEFF8",
                    padding: "0.1rem 0.3rem",
                    color: orderStatusTagColor(orderData?.orderStatus).color,
                  }}
                  variant="p"
                >
                  {orderData?.orderStatus?.toUpperCase()}
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
      )}
      <Grid
        sx={{ display: "flex", flexDirection: "column" }}
        xs={printTestOrder ? 4 : 12}
        sm={printTestOrder ? 4 : 12}
        md={4}
        lg={4}
        xl={4}
        item
      >
        <Typography
          variant="p"
          sx={{ fontWeight: "500", color: "darkBlue.main" }}
        >
          Client name
        </Typography>
        <Typography
          variant="h5"
          sx={{
            width: "max-content",
            maxWidth: "100%",
            color: "black",
            marginTop: "0.3rem",
            fontSize: "1.3rem",
          }}
        >
          {clientDetails?.clientName}
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: printTestOrder
            ? "flex"
            : {
                sm: "none",
                xs: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
          flexDirection: "column",
        }}
        xs={printTestOrder ? 5 : 6}
        sm={printTestOrder ? 5 : 6}
        md={5}
        lg={5}
        xl={5}
        item
      >
        {isOrderNumberGenerated && (
          <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
            <QRCode
              size={80}
              value={orderData?.autoGeneratedOrderNumber + ""}
            />
            <Box>
              <Typography
                variant="p"
                sx={{ fontWeight: "500", color: "darkBlue.main" }}
              >
                Order no.
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  width: "max-content",
                  color: "black",
                  marginTop: "0.3rem",
                  fontSize: "1.3rem",
                }}
              >
                {orderData?.autoGeneratedOrderNumber}
              </Typography>
              {isOrderNumberGenerated && (
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    backgroundColor: "#EBEFF8",
                    padding: "0.1rem 0.3rem",
                    color: orderStatusTagColor(orderData?.orderStatus).color,
                  }}
                  variant="p"
                >
                  {orderData?.orderStatus?.toUpperCase()}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default HeaderContent;
